import React, {Fragment, useState} from 'react';
import {FormControl, Button, Typography} from '@mui/material';
import {createUseStyles, useTheme} from 'react-jss';
import {osColour, border1, contentPadding, InputLabel, linkButton, DropDownMenu} from 'omse-components';
import BackLink from '../../../../components/BackLink';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import StepList from '../../../../components/stepList/StepList';
import routes, {getLocation} from "../../../../util/routes";
import classNames from 'classnames';
import OpenDataDesc from './OpenDataDesc';
import OpenDataSideInfo from './OpenDataSideInfo';
import OpenDataLinks from './OpenDataLinks';
import OpenDataHeading from './OpenDataHeading';
import StepListItem from '../../../../components/stepList/StepListItem';
import CustomAreaDialog from '../../CustomAreaDialog';
import {formatBytes} from '../../../../modules/downloads';
import ContentHeader from '../../../../components/ContentHeader';
import useOpenDataItem from "../../../../hooks/useOpenDataItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import OpenDataTagAndProvider from "./OpenDataTagAndProvider";
import {useHistory, useLocation} from "react-router-dom";

const messages = defineMessages({
    openDataDownloads: {
        id: 'OpenDataItem.actions',
        defaultMessage: 'OpenData downloads',
        description: 'Label for back link'
    },
    specifyAnArea: {
        id: 'OpenDataItem.specifyAnArea',
        defaultMessage: 'Specify an area',
        description: 'Label for Specify an area'
    },
    dataFormat: {
        id: 'OpenDataItem.dataFormat',
        defaultMessage: 'Data format',
        description: 'Label for Data format'
    },
    download: {
        id: 'OpenDataItem.download',
        defaultMessage: 'Download',
        description: 'Label for Download'
    },
    allOfGb: {
        id: 'OpenDataItem.allOfGb',
        defaultMessage: 'All of Great Britain',
        description: 'Label for all of GB'
    },
    allOfUk: {
        id: 'OpenDataItem.allOfUk',
        defaultMessage: 'All of the United Kingdom',
        description: 'Label for all of UK'
    },
    allOfGbAndNi: {
        id: 'OpenDataItem.allOfGbAndNi',
        defaultMessage: 'All of Great Britain and Northern Ireland',
        description: 'Label for all of GB and NI'
    },
    formatLabel: {
        id: 'OpenDataItem.formatLabel',
        defaultMessage: 'Select format',
        description: 'Label Select format control'
    },
    loading: {
        id: 'OpenDataItem.loading',
        defaultMessage: 'Loading...',
        description: 'Label for the download page while it is loading the details of the download'
    },
    downloadData: {
        id: 'OpenDataItem.downloadData',
        defaultMessage: 'Download {data, plural, =0{} one {} other {all}}',
        description: 'Label for download data button'
    },
    size: {
        id: 'OpenDataItem.size',
        defaultMessage: 'Size',
        description: 'Size label'
    },
    selectFormat: {
        id: 'OpenDataItem.selectFormat',
        defaultMessage: 'Select format',
        description: 'Select format option label'
    },
    setCustomArea: {
        id: 'OpenDataItem.setCustomArea',
        defaultMessage: 'Set a custom area',
        description: 'Custom area label'
    },
    or: {
        id: 'OpenDataItem.or',
        defaultMessage: 'or ',
        description: 'Text for or label'
    },
    please: {
        id: 'OpenDataItem.please',
        defaultMessage: 'Please ',
        description: 'Text for Please label'
    },
    setYourCustomArea: {
        id: 'OpenDataItem.setYourCustomArea',
        defaultMessage: 'set your custom area',
        description: 'Set custom area text'
    },
    selectedTiles: {
        id: 'OpenDataItem.selectedTiles',
        defaultMessage: 'Selected tiles:',
        description: 'Selected tiles label'
    },
    changeCustomArea: {
        id: 'OpenDataItem.changeCustomArea',
        defaultMessage: 'Change custom area',
        description: 'Change custom area label'
    },
    sizeRange: {
        id: 'OpenDataItem.sizeRange',
        defaultMessage: 'Each download ranges from {min} to {max}',
        description: 'Size label for custom area'
    },
    singleSize: {
        id: 'OpenDataItem.singleSize',
        defaultMessage: '{size}',
        description: 'Size label for custom area for one item'
    },
    loadingFormats: {
        id: 'OpenDataItem.loadingFormats',
        defaultMessage: 'Loading formats...',
        description: 'Label for the Loading formats... message'
    },
    back: {
        id: 'OpenDataItem.back',
        defaultMessage: 'Back to {name}',
        description: 'Label back link'
    }
});

// Used to keep the controls consistent
const controlWidth = 258;
const mobileControlWidth = 244;

const myStyles = createUseStyles(theme =>  ({
        root: {
            paddingTop: contentPadding.top - contentPadding.backLink - 10,
            paddingRight: contentPadding.right,
            paddingBottom: contentPadding.bottom,
            paddingLeft: contentPadding.left,
            borderBottom: border1,
            [theme.breakpoints.down('sm')]: {
                padding: contentPadding.mobile,
                paddingTop: theme.spacing(3)
            }
        },
        container: {
            display: 'flex',
            marginTop: theme.spacing(2),
            [theme.breakpoints.down('lg')]: {
                flexDirection: 'column'
            }
        },
        content: {
            paddingTop: theme.spacing(0.5),
            paddingLeft: contentPadding.left,
            maxWidth: contentPadding.maxWidth,
            [theme.breakpoints.down('sm')]: {
                padding: 0,
                marginTop: 0
            }
        },
        downloadDetailContent: {
            flex: '1 1 fit-content',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2)
            }
        },
        downloadStage: {
            '&:not(:last-child)': {
                minHeight: 108,
            },
            '&:last-of-type $stageContent': {
                paddingBottom: 0,
                marginBottom: theme.spacing(3)
            }
        },
        contentLabel: {
            color: theme.palette.text.primary,
            marginTop: 4,
            whiteSpace: 'normal'
        },
        stageLabels: {
            color: theme.palette.text.primary,
            marginTop: 4,
            whiteSpace: 'nowrap',
            [theme.breakpoints.down('sm')]: {
                width: 130,
                marginBottom: 15,
                flex: '1 0 auto'
            },
            '&.disabled p span,&.disabled': {
                color: osColour.neutral.stone
            }
        },
        stageHeading: {
            marginBottom: theme.spacing(1),
            [theme.breakpoints.down('sm')]: {
                marginTop: 5,
                '& span': {
                    fontSize: '1.125rem'
                }
            }
        },
        formatSelectButton: {
            '&.MuiButton-outlined': {
                fontWeight: 600,
            },
            minWidth: controlWidth,
            [theme.breakpoints.down('sm')]:{
                minWidth: mobileControlWidth
            }
        },
        downloadMenu: {
            padding: '8px 51px 7px 19px',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(2.5),
            }
        },
        downloadMenuIcon: {
            color: osColour.primary.berry,
            transform: 'scale(1.25)'
        },
        expandIcon: {
            position: 'absolute',
            top: 8,
            right: 11,
            zIndex: -1,
            '&.formatOpen': {
                transform: 'rotate(180deg)'
            }
        },
        label: {
            color: osColour.neutral.stone,
            marginRight: 5
        },
        controlInfo: {
            marginTop: theme.spacing(1),
            whiteSpace: 'normal',
            '&.disabled': {
                color: osColour.neutral.stone,
                '& span': {
                    color: osColour.neutral.stone,
                }
            },
            display: 'block',
            width: controlWidth,
            [theme.breakpoints.down('sm')]:{
                width: mobileControlWidth
            }
        },
        allAreaButton: {
            borderRadius: '3px 0 0 3px',
            '&.selectedAll': {
                background: osColour.primary.berry,
                color: osColour.neutral.white
            },
            borderRight: 'none',
            '&:hover':{
                borderRight: 'none'
            }
        },
        customAreaButton: {
            borderRadius: '0 3px 3px 0',
            '&.hasCustomArea': {
                background: osColour.primary.berry,
                color: osColour.neutral.white
            },
            borderLeft: 'none',
            '&:hover':{
                borderLeft: 'none'
            }
        },
        singleActionButton: {
            background: osColour.primary.berry,
            color: osColour.neutral.white,
            '&:hover': {
                background: osColour.primary.berry
            }
        },
        mobileCompatable: {
            [theme.breakpoints.down('sm')]: {
                padding: '7px 15px 7px !important'
            }
        },
        controls: {
            whiteSpace: 'normal',
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        },
        downloadForm: {
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(2)
            }
        },
        changeCustomArea: {
            ...linkButton,
            display: 'block',
            marginBottom: theme.spacing(4.5),
            [theme.breakpoints.down('sm')]: {
                marginBottom: 0
            }
        },
        downloadMetadata: {
            marginLeft: 5,
        },
        metaLabel: {
            marginLeft: 5
        },
        linkIcon: {
            width: '16px',
            height: '16px',
            paddingTop: '2px',
            paddingRight: theme.spacing(1)
        },
        sizeInfo: {
            marginBottom: theme.spacing(4),
            color: theme.palette.text.primary,
            whiteSpace: 'break-spaces',
            [theme.breakpoints.down('sm')]: {
                marginBottom: 0
            }
        },
        titleContainer: {
            display: 'flex',
            flexWrap: 'wrap-reverse',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            marginTop: theme.spacing(3),
            marginRight: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(2),
            }
        },
        productTitle: {
            marginRight: theme.spacing(3),
            marginBottom: theme.spacing(3)
        },
        stageContent: {
            marginLeft: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 auto',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                left: -13,
                position: 'relative',
                alignItems: 'start',
                paddingBottom: theme.spacing(4)
            }
        }
    }));

const gridRefs = [
    '','','','','HP','','',
    '','','','HT','HU','','',
    '','HW','HX','HY','HZ','','',
    'NA','NB','NC','ND','','','',
    'NF','NG','NH','NJ','NK','','',
    'NL','NM','NN','NO','','','',
    '','NR','NS','NT','NU','','',
    '','NW','NX','NY','NZ','OV','',
    '','','','SD','SE','TA','',
    '','','SH','SJ','SK','TF','TG',
    '','SM','SN','SO','SP','TL','TM',
    '','SR','SS','ST','SU','TQ','TR',
    'SV','SW','SX','SY','SZ','TV',''
];

export default function OpenDataItem() {
    const history = useHistory();
    const location = useLocation();
    const classes = myStyles();
    const intl = useIntl();
    const theme = useTheme();
    const isXlScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const [customAreaOpen, setCustomAreaOpen] = useState(false);
    const [customArea, setCustomArea] = useState([]);
    const [selectedFormatName, setSelectedFormatName] = useState(null);
    const { product, productLoading, downloads, downloadsLoading, error } = useOpenDataItem();

    function initGrid() {
        return gridRefs.map(g => {
            let active = false;
            if (product?.areas && product.areas.indexOf(g) !== -1) {
                active = true;
            }
            return {ref: g, active};
        });
    }

    const loadingJsx = <>
        <ContentHeader
            backPath={routes.openDataDownloads}
            backLabel={messages.openDataDownloads}
            title={messages.loading}/>
    </>;

    if (error) {
        history.push(getLocation(routes.error404Downloads, location));
        return loadingJsx;
    }

    if (productLoading || !product) {
        return loadingJsx;
    }

    const grid = initGrid();
    const gbEnabled = downloads.some(d => d.area === 'GB');
    const niEnabled = downloads.some(d => d.area === 'NI');
    const ukEnabled = downloads.some(d => d.area === 'UK');
    const hasCustomArea = customArea.length > 0;
    const selectedAll = customArea.length === 0;

    let filteredDownloads = [];
    if(hasCustomArea) {
        filteredDownloads = downloads.filter(d => customArea.includes(d.area));
    } else if(gbEnabled && niEnabled) {
        filteredDownloads = downloads.filter(d => d.area === 'GB' || d.area === 'NI');
    } else if(gbEnabled) {
        filteredDownloads = downloads.filter(d => d.area === 'GB');
    } else if(ukEnabled) {
        filteredDownloads = downloads.filter(d => d.area === 'UK');
    }

    const formatLabels = getFormatLabels(filteredDownloads);
    if(selectedFormatName) {
        filteredDownloads = filteredDownloads.filter(d => selectedFormatName === getFormatLabel(d))
    }

    function getFormatLabel(download) {
        let label = download.format;
        if(download.subformat) {
            label += ' ' + download.subformat;
        }
        return label;
    }

    function getFormatLabels(downloads) {
        const uniqueFormats = [];
        downloads.forEach(d => {
            const label = getFormatLabel(d);
            if (uniqueFormats.indexOf(label) === -1) {
                uniqueFormats.push(label);
            }
        });
        return uniqueFormats;
    }

    const handleCustomAreaSave = selectedSquares => {
        if (selectedSquares.length === 0) {
            setSelectedFormatName('');
        }
        if (formatLabels.length === 1) {
            setSelectedFormatName(formatLabels[0]);
        }
        setCustomArea(selectedSquares);
        setCustomAreaOpen(false);
        setSelectedFormatName(null);
    }

    const handleSelectGbArea = () => {
        setCustomArea([]);
        setSelectedFormatName(null);
    }

    const formatDisabled = filteredDownloads.length === 0;
    const downloadDisabled = !selectedFormatName && formatLabels.length !== 1 && filteredDownloads.length !== 1;
    const minSize = formatBytes(Math.min(...filteredDownloads.map(d => d.size)));
    const maxSize = formatBytes(Math.max(...filteredDownloads.map(d => d.size)));

    const logoJsx = <OpenDataTagAndProvider product={product} />;
    const sideInfoJsx = <OpenDataSideInfo item={product} />

    return <>
        <BackLink path={routes.openDataDownloads} label={messages.openDataDownloads}/>
        <div className={classes.content}>
            <div className={classes.container}>
                <div className={classes.downloadDetailContent} data-testid="downloadDetail">
                    {!isXlScreen && logoJsx}
                    <Typography variant='h1' color='primary' className={classes.productTitle}>
                        {product.name}
                    </Typography>
                    <OpenDataDesc item={product} summary={false}/>
                    <OpenDataHeading title={product.name}/>

                    <StepList className={classes.downloadForm}>
                        <StepListItem disabled={false} lineDisabled={formatDisabled} className={classes.downloadStage}>
                            <div className={classes.stageContent}>
                                <div className={classNames({
                                    [classes.stageHeading]: true,
                                    [classes.stageLabels]: true,
                                    disabled: false
                                })}>
                                    <Typography variant='body1'>
                                        <FormattedMessage {...messages.specifyAnArea} />
                                    </Typography>
                                </div>


                                <div className={classes.controls}>
                                    {(!gbEnabled && product.customAreaEnabled) &&
                                        <Button onClick={() => setCustomAreaOpen(true)}
                                                color='primary'
                                                variant='outlined'
                                                aria-controls='format'
                                                className={classNames(classes.singleActionButton, classes.mobileCompatable)}>
                                            <FormattedMessage {...messages.setCustomArea} />
                                        </Button>
                                    }
                                    {(gbEnabled && product.customAreaEnabled) &&
                                        <Fragment>
                                            <Button onClick={handleSelectGbArea}
                                                    color='primary'
                                                    variant='outlined'
                                                    aria-controls='format'
                                                    className={classNames({
                                                        [classes.allAreaButton]: true,
                                                        [classes.mobileCompatable]: true,
                                                        selectedAll
                                                    })}>
                                                <FormattedMessage {...messages.allOfGb} />
                                            </Button>
                                            <Button onClick={() => setCustomAreaOpen(true)}
                                                    color='primary'
                                                    variant='outlined'
                                                    aria-controls='format'
                                                    className={classNames({
                                                        [classes.customAreaButton]: true,
                                                        [classes.mobileCompatable]: true,
                                                        hasCustomArea
                                                    })}>
                                                <FormattedMessage {...messages.setCustomArea} />
                                            </Button>
                                        </Fragment>
                                    }
                                    {customArea.length > 0 &&
                                        <Typography variant='body1' className={classes.controlInfo}>
                                            <Fragment>
                                                        <span
                                                            className={classes.label}><FormattedMessage {...messages.selectedTiles} /></span>
                                                {customArea.join(', ')}
                                            </Fragment>
                                            <Button className={classes.changeCustomArea}
                                                    onClick={() => setCustomAreaOpen(true)} disableRipple>
                                                <FormattedMessage {...messages.changeCustomArea} />
                                            </Button>
                                        </Typography>
                                    }
                                    {(gbEnabled && !niEnabled && !product.customAreaEnabled) &&
                                        <Typography variant='body1' className={classes.contentLabel}>
                                            <FormattedMessage {...messages.allOfGb} />
                                        </Typography>
                                    }
                                    {(gbEnabled && niEnabled && !product.customAreaEnabled) &&
                                        <Typography variant='body1' className={classes.contentLabel}>
                                            <FormattedMessage {...messages.allOfGbAndNi} />
                                        </Typography>
                                    }
                                    {(ukEnabled && !product.customAreaEnabled) &&
                                        <Typography variant='body1' className={classes.contentLabel}>
                                            <FormattedMessage {...messages.allOfUk} />
                                        </Typography>
                                    }
                                </div>

                            </div>
                        </StepListItem>

                        <StepListItem disabled={formatDisabled} lineDisabled={downloadDisabled}
                                      className={classes.downloadStage}>
                            <div className={classes.stageContent}>
                                <div className={classNames({
                                    [classes.stageHeading]: true,
                                    [classes.stageLabels]: true,
                                    disabled: formatDisabled
                                })}>
                                    <Typography variant='body1'>
                                        <FormattedMessage {...messages.dataFormat} />
                                    </Typography>
                                </div>

                                <div className={classes.controls}>
                                    <FormControl className={classes.formControl}>
                                        {downloadsLoading &&
                                            <Typography variant='body1'
                                                        className={classNames({
                                                            [classes.contentLabel]: true,
                                                            disabled: formatDisabled
                                                        })}>
                                                <FormattedMessage {...messages.loadingFormats} />
                                            </Typography>
                                        }
                                        {formatLabels.length === 1 &&
                                            <Typography variant='body1'
                                                        className={classNames({
                                                            [classes.contentLabel]: true,
                                                            disabled: formatDisabled
                                                        })}>
                                                {formatLabels[0]}
                                            </Typography>
                                        }
                                        {!downloadsLoading && formatLabels.length !== 1 &&
                                            <Fragment>
                                                <InputLabel id='formatLabel' hidden={true}>
                                                    {intl.formatMessage(messages.formatLabel)}
                                                </InputLabel>
                                                <DropDownMenu
                                                    id='format'
                                                    buttonClasses={{outlined: classes.formatSelectButton}}
                                                    buttonLabel={formatLabels.find(label => label === selectedFormatName) || intl.formatMessage(messages.selectFormat)}
                                                    buttonVariant='outlined'
                                                    buttonProps={{
                                                        'aria-controls': 'links size',
                                                        'aria-labelledby': 'formatLabel'
                                                    }}
                                                    disabled={formatDisabled}
                                                    onChange={item => setSelectedFormatName(item.value)}
                                                    items={formatLabels.map(label => ({label, value: label}))}
                                                />
                                            </Fragment>
                                        }
                                        {filteredDownloads.length === 1 &&
                                            <Typography id='size' type='region' variant='body1'
                                                        className={classNames(classes.sizeInfo, {
                                                            [classes.controlInfo]: true,
                                                            disabled: formatDisabled
                                                        })}>
                                                <span
                                                    className={classes.label}>{intl.formatMessage(messages.size)}:</span>{filteredDownloads[0].readableSize}
                                            </Typography>
                                        }

                                        {!downloadDisabled && filteredDownloads.length > 1 && minSize && maxSize &&
                                            <Typography id='size' type='region' variant='body1'
                                                        className={classNames(classes.sizeInfo, {
                                                            [classes.controlInfo]: true,
                                                            disabled: formatDisabled
                                                        })}>
                                                <span
                                                    className={classes.label}>{intl.formatMessage(messages.size)}:</span>
                                                {minSize === maxSize ?
                                                    <FormattedMessage {...messages.singleSize}
                                                                      values={{size: minSize}}/>
                                                    :
                                                    <FormattedMessage {...messages.sizeRange}
                                                                      values={{min: minSize, max: maxSize}}/>
                                                }
                                            </Typography>
                                        }
                                    </FormControl>
                                </div>
                            </div>
                        </StepListItem>

                        <StepListItem disabled={downloadDisabled} className={classes.downloadStage}>
                            <div className={classes.stageContent}>
                                <div className={classNames({
                                    [classes.stageHeading]: true,
                                    [classes.stageLabels]: true,
                                    disabled: downloadDisabled
                                })}>
                                    <Typography variant='body1'>
                                        <FormattedMessage {...messages.download} />
                                    </Typography>
                                </div>

                                <div className={classes.controls} id='links' type='region' aria-live='polite'>
                                    {!downloadDisabled &&
                                        <OpenDataLinks downloads={filteredDownloads} name={product.name}
                                                       format={selectedFormatName}/>
                                    }
                                </div>
                            </div>
                        </StepListItem>
                    </StepList>
                </div>
                <div>
                    {isXlScreen && logoJsx}
                    {sideInfoJsx}
                </div>
            </div>
        </div>

        {customAreaOpen &&
            <CustomAreaDialog
                handleClose={() => setCustomAreaOpen(false)}
                handleSave={handleCustomAreaSave}
                selectedArea={customArea}
                grid={grid}
            />
        }
    </>;
}
