import React, { Fragment, useEffect } from 'react';
import Header from '../../documentation/Header';
import Content from "../../documentation/Content";
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import { createUseStyles } from 'react-jss';
import getBaseStyles from './styles/support';
import Faq from './components/Faq';
import { LinkToAnchor } from '../../../components/LinkToAnchor';
import downloadFaqs from './data/download';
import { scrollHashIntoView } from './util/scroll';
import Document from '../../documentation/Document';
import { withWidth } from 'omse-components';
import { useImageLoadCallback } from './util/useImageLoadCallback';
import { useFaqs } from './util/useFaqs';

const useStylesBase = createUseStyles(theme => getBaseStyles(theme));

export function Download(props) {
    const classesBase = useStylesBase(props);
    const { getImg } = useImageLoadCallback(scrollHashIntoView);
    const downloads = useFaqs(downloadFaqs, getImg);

    return (
        <Document>
            <Header>
                <span>Support</span>
            </Header>
            <Content classes={{ content: classesBase.support }}>

                <Typography variant='h2'>FAQs: Download</Typography>
                <Typography variant='body1'>
                    The following FAQs relate to ordering of premium data via the OS Data Hub:
                </Typography>

                <nav className={classesBase.faqs}>
                    {downloads.map((group) => (
                        <Fragment key={group.heading}>
                            {group.heading &&
                                <Typography variant='h3'>{group.heading}</Typography>
                            }
                            <List>
                                {group.faqs.map((item) => (
                                    <LinkToAnchor key={item.id} item={item} />
                                ))}
                            </List>
                        </Fragment>
                    ))}
                </nav>

                <div className={classesBase.divider} />

                {downloads.map((group) => (
                    <Fragment key={group.heading}>
                        {group.heading &&
                            <Typography variant='h2' id={group.id}>
                                {group.heading}
                            </Typography>
                        }
                        {group.faqs.map((item) => (
                            <Faq key={item.title} linkId={item.id} title={item.title} premium={item.premium}>{item.body}</Faq>
                        ))}
                        <List>
                            <LinkToAnchor />
                        </List>
                    </Fragment>
                ))}

            </Content>
        </Document>
    );
}

export default withWidth()(Download);