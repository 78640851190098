import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage} from "react-intl";
import {createUseStyles} from 'react-jss';
import {useSelector} from "react-redux";
import {fromISOString} from "../../../util/dateUtils";
import {InputLabel} from "@mui/material";
import {DatePicker} from '@mui/x-date-pickers';
import useLocationState from "./useLocationState";
import LayoutWithKeyboardView from '../../../components/datePicker/LayoutWithKeyboardView';
import ToolbarWithKeyboardViewSwitch from '../../../components/datePicker/ToolbarWithKeyboardViewSwitch';

export const FROM_DATE = 'fromDate';
export const TO_DATE = 'toDate';

// A constant is used instead of inline props due to:
// https://github.com/mui/mui-x/issues/8432
const popperProps = {
    modifiers: [
        {
            name: 'flip',
            enabled: false
        }
    ]
};

const styles = createUseStyles(theme => ({
    label: {
        marginBottom: theme.spacing(1)
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0 auto',
        '& .MuiInput-root': {
            // Tweak the styling of the date picker that MaterialUI draws.
            height: '40px',
            borderBottom: 'none'
        },
        "& input": {
            padding: '10px 0 10px 15px !important'
        },
        "& button": {
            marginRight: "2px"
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }
}));

const messages = defineMessages({
    [FROM_DATE]: {
        id: 'RecipeLibraryDatePicker.fromDate',
        defaultMessage: 'From creation date (dd/mm/yyyy)',
        description: 'Label for the "from" date picker.'
    },
    [TO_DATE]: {
        id: 'RecipeLibraryDatePicker.toDate',
        defaultMessage: 'To creation date (dd/mm/yyyy)',
        description: 'Label for the "to" date picker'
    }
});

export default function RecipeLibraryDatePicker({variant}) {
    const [fromValue, setFromValue] = useLocationState(FROM_DATE);
    const [toValue, setToValue] = useLocationState(TO_DATE);
    const allRecipes = useSelector(state => state.recipes.library.result);
    const classes = styles();

    let minDate, maxDate, value;

    function setValue(newDate) {
        let adjustedDate = new Date(newDate);
        if(!newDate || isNaN(newDate)) {
            adjustedDate = 'Invalid Date';
        } else {
            // Adjust the date so that it points to the first instant of the day
            adjustedDate.setUTCHours(0);
            adjustedDate.setUTCMinutes(0);
            adjustedDate.setUTCSeconds(0);
            adjustedDate.setUTCMilliseconds(0);
        }

        if(variant === FROM_DATE) {
            setFromValue(adjustedDate);
        } else {
            setToValue(adjustedDate);
        }
    }

    minDate = fromISOString(allRecipes[allRecipes.length-1].created);
    maxDate = fromISOString(allRecipes[0].created);
    if(variant === FROM_DATE) {
        value = fromValue || minDate;
        if(toValue) {
            maxDate = toValue;
        }
    } else {
        value = toValue || maxDate;
        if(fromValue) {
            minDate = fromValue;
        }
    }

    return <div className={classes.root}>
        <InputLabel htmlFor={variant} className={classes.label}>
            <FormattedMessage {...messages[variant]}/>
        </InputLabel>
        <DatePicker InputProps={{id: variant}}
                    inputFormat={'dd/MM/yyyy'}
                    format={'dd/MM/yyyy'}
                    views={['year', 'month', 'day']}
                    value={value}
                    onChange={setValue}
                    slots={{
                        layout: LayoutWithKeyboardView,
                        toolbar: ToolbarWithKeyboardViewSwitch
                    }}
                    slotProps={{
                        textField: {
                            variant: 'standard', 
                            helperText: null
                        },
                        popper: popperProps
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
        />
    </div>
}

RecipeLibraryDatePicker.propTypes = {
    variant: PropTypes.oneOf([FROM_DATE, TO_DATE]).isRequired
};
