import { useState, useEffect, useId } from "react";
import styled from "@emotion/styled";
import { contentPadding, DropDownMenu, ExternalLink, Notification, theme } from "omse-components";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, CircularProgress, Typography } from "@mui/material";
import SearchBox from "../components/SearchBox";
import Header from "./teamSpaces/Header";
import EmptyState from "./teamSpaces/EmptyState";
import TeamSpacesList from "./teamSpaces/TeamSpacesList";
import FeatureCheck from "../components/FeatureCheck";
import { TEAM_SPACES } from "../../shared/features";
import { useDispatch, useSelector } from "react-redux";
import { getOrgUsers, getTeamSpaces } from "../modules/teamSpaces/actions";
import teamSpacesMessages from "./TeamSpaces.msg";
import { ContentContainer } from "./teamSpaces/ContentContainer";
import NewTeamSpace from "./teamSpaces/NewTeamSpace";

const SelectionControlsContainer = styled(Box)`
    & > div:first-of-type {
        max-width: 16em;
        ${theme.breakpoints.down("md")} {
            min-width: 100%;
        }
    }
    & > div:last-of-type {
        display: flex;
        gap: 1em;
        flex-wrap: wrap;
        align-items: end;
        ${theme.breakpoints.down("md")} {
            flex-direction: column-reverse;
            align-items: stretch;
        }
        justify-content: space-between;
        max-width: ${contentPadding.maxWidth}px;
        padding-top: 18px;
    }
    & > div:last-of-type > div:first-of-type {
        flex-grow: 1;
    }
    & > div:last-of-type > div:last-of-type {
        min-width: 12em;
    }

    & .dropdownBox > button {
        ${theme.breakpoints.up("md")} {
            max-height: 2.5em;
        }
    }
`;

const viewOptions = [
    {
        id: "all",
        label: "All Team Spaces",
        value: 0,
    },
    {
        id: "locked",
        label: "Locked",
        value: 1,
    },
    {
        id: "unlocked",
        label: "Unlocked",
        value: 2,
    },
];

const orderingOptions = [
    {
        id: "dateCreated",
        label: "Date Created",
        value: 0,
    },
];

export default function TeamSpaces() {
    const intl = useIntl();
    const dataSortId = useId();
    const dataFilterId = useId();
    const dispatch = useDispatch();

    const [results, setResults] = useState([]);
    const [resultsFiltered, setResultsFiltered] = useState([]);

    const [filterView, setFilterView] = useState(0);
    const [filterSearch, setFilterSearch] = useState("");
    const [filterOrdering, setFilterOrdering] = useState(0);

    const orgUsers = useSelector((state) => state.teamSpaces.getOrgUsers);
    const teamSpaces = useSelector((state) => state.teamSpaces.getTeamSpaces);

    useEffect(() => {
        dispatch(getOrgUsers());
        dispatch(getTeamSpaces());
    }, [dispatch]);

    useEffect(() => {
        if (orgUsers.result && teamSpaces.result) {
            setResults(
                teamSpaces.result.map((teamSpace) => {
                    const userInfo = orgUsers.result.find(
                        (orgUser) => orgUser.datahubDeveloperId === teamSpace.createdBy
                    );
                    return {
                        ...teamSpace,
                        createdByName: userInfo
                            ? `${userInfo.firstName} ${userInfo.lastName}`
                            : intl.formatMessage(teamSpacesMessages.deletedUserAccount),
                    };
                })
            );
        }
    }, [orgUsers.result, teamSpaces.result, intl]);

    useEffect(() => {
        if (!filterView && !filterSearch && !filterOrdering) {
            setResultsFiltered(results);
            return;
        }

        let filtered = [];
        const searchTerm = filterSearch.toLowerCase();
        if (results) {
            filtered = results.filter((project) => {
                switch (filterView) {
                    case 0: // All Team Spaces
                        break;
                    case 1: // Locked
                        if (!project.locked) return false;
                        break;
                    case 2: // Unlocked
                        if (project.locked) return false;
                        break;
                    default:
                        break;
                }
                return project.name.toLowerCase().indexOf(searchTerm) !== -1;
            });
        }
        setResultsFiltered(filtered);
    }, [results, filterView, filterSearch, filterOrdering]);

    return (
        <FeatureCheck feature={TEAM_SPACES}>
            <Header title={teamSpacesMessages.title}>{!!results.length && <NewTeamSpace />}</Header>
            <ContentContainer>
                {orgUsers.loading || teamSpaces.loading ? (
                    <CircularProgress size={32} data-testid="loading-spinner" />
                ) : (
                    <>
                        {orgUsers.error || teamSpaces.error ? (
                            <Notification variant="error" appearance="inline">
                                <Typography variant="body1" paragraph={true}>
                                    <FormattedMessage {...teamSpacesMessages.errorGeneric} />
                                </Typography>
                                <Typography variant="body1">
                                    <FormattedMessage
                                        {...teamSpacesMessages.errorContent}
                                        values={{ link: <ExternalLink type="support" /> }}
                                    />
                                </Typography>
                            </Notification>
                        ) : (
                            <>
                                {!!results.length ? (
                                    <>
                                        <SelectionControlsContainer>
                                            <DropDownMenu
                                                buttonId={dataFilterId}
                                                buttonProps={{
                                                    "aria-label": intl.formatMessage(
                                                        teamSpacesMessages.dataFilterAria
                                                    ),
                                                }}
                                                buttonFontWeight="bold"
                                                value={filterView}
                                                buttonVariant="outlined"
                                                items={viewOptions}
                                                onChange={(selection) =>
                                                    setFilterView(selection.value)
                                                }
                                                className="dropdownBox"
                                            />
                                            <Box>
                                                <SearchBox
                                                    label={teamSpacesMessages.searchBoxLabel}
                                                    placeholder={
                                                        teamSpacesMessages.searchBoxPlaceholder
                                                    }
                                                    search={filterSearch}
                                                    setSearch={setFilterSearch}
                                                />
                                                <DropDownMenu
                                                    buttonId={dataSortId}
                                                    buttonProps={{
                                                        "aria-label": intl.formatMessage(
                                                            teamSpacesMessages.dataSortAria
                                                        ),
                                                    }}
                                                    buttonFontWeight="bold"
                                                    value={filterOrdering}
                                                    buttonVariant="outlined"
                                                    items={orderingOptions}
                                                    onChange={(selection) =>
                                                        setFilterOrdering(selection.value)
                                                    }
                                                    className="dropdownBox"
                                                />
                                            </Box>
                                        </SelectionControlsContainer>
                                        <TeamSpacesList resultsFiltered={resultsFiltered} />
                                    </>
                                ) : (
                                    <EmptyState />
                                )}
                            </>
                        )}
                    </>
                )}
            </ContentContainer>
        </FeatureCheck>
    );
}
