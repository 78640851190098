import React from 'react';
import { useSelector } from 'react-redux'
import DashboardHeader from "./dashboard/DashboardHeader";
import DashboardContent from "./dashboard/DashboardContent";

const Dashboard = () => {
    const { loading } = useSelector(state => state.user.current);
    const configLoading = useSelector(state => state.config.current.loading);

    return <div style={{
        flex: '1 1 auto',
        flexDirection: 'column'
    }}>
        <DashboardHeader />
        { !(configLoading || loading) && <DashboardContent/>}
    </div>
}

export default Dashboard;
