import { matchRoute } from "./routes";

const BASE_TITLE = 'OS Data Hub';
const SEPARATOR = ' | ';

export const titles = [
    {path: 'account', name: 'Account settings'},
    {path: 'invite', name: 'Invitations'},
    {path: 'history', name: 'API transaction history'},
    {path: 'manageTeamMembers', name: 'Manage team members'},
    {path: 'companyInformation', name: 'Company information'},
    {path: 'billing', name: 'Billing information'},
    {path: 'contactPreferences', name: 'Contact preferences'},
    {path: 'payments', name: 'Payments'},
    {path: 'projects', name: 'My Projects'},
    {path: 'products', name: 'APIs'},
    {path: 'sign-up', name: 'Sign-up'},
    {path: 'dashboard', name: 'API Dashboard'},
    {path: 'wfs', name: 'OS Features API'},
    {path: 'wmts', name: 'OS Maps API'},
    {path: 'vts', name: 'OS Vector Tile API'},
    {path: 'docs', name: 'Documentation'},
    {path: 'overview', name: 'Overview'},
    {path: 'gettingStarted', name: 'Getting Started'},
    {path: 'technicalSpecification', name: 'Technical Specification'},
    {path: 'support', name: 'Support'},
    {path: 'plans', name: 'Plans'},
    {path: 'downloads', name: 'OS Downloads API', prefix: 'docs'},
    {path: 'downloads', name: 'OS Data downloads'},
    {path: 'legal', name: 'Legal | Documentation'},
    {path: 'recipeEditor', name: 'Recipe Editor'},
    {path: 'termsConditions', name: 'OS Data Hub Terms | Terms & Conditions'},
    {path: 'errorReportingTerms', name: 'Error Reporting Terms'},
    {path: 'vernacularNamesTerms', name: 'Vernacular Names Terms'},
    {path: 'frameworkContractTerms', name: 'Framework Contract (Partners) Terms'},
    {path: 'partnerContracts', name: 'Partner Contracts'},
    {path: 'sla', name: 'Service Level Agreement'},
    {path: 'serviceStatus', name: 'Service Status | Support'},
    {path: 'brandLogo', name: 'Brand logo | Documentation'},
    {path: 'brandImportance', name: 'The importance of our brand'},
    {path: 'logoOverview', name: 'Logo overview'},
    {path: 'logoFullColour', name: 'Logo overview - full colour'},
    {path: 'logoWhite', name: 'Logo overview - white'},
    {path: 'copyrightOverview', name: 'Copyright overview'},
    {path: 'logoCopyrightPositioning', name: 'Logo and copyright positioning'},
    {path: 'exclusionZone', name: 'Exclusion zone'},
    {path: 'minMax', name: 'Minimum and maximum'},
    {path: 'prohibitedUse', name: 'Prohibited use'},
    {path: 'errorsAndOmissions', name: 'Error Reporting'},
    {path: 'teamSpaces', name: 'Team Spaces'}
];

// Titles are built by matching parts of the location path to an array of mappings
export function generateTitle(location) {
    let title = BASE_TITLE;
    if (location) {
        let route = matchRoute(location.pathname);
        if (route) {
            let previousPathUnit;
            let pathUnits = route.path.split('/').filter(t => t);
            let titleParts = [];
            pathUnits.forEach(pathUnit => {

                // use prefixed entry over a regular match
                const titleMatch = titles.find(t => {
                    if (t.prefix) {
                        return `${previousPathUnit}/${pathUnit}` === `${t.prefix}/${t.path}`;
                    }
                    return t.path === pathUnit;
                });

                if (titleMatch) {
                    titleParts.unshift(titleMatch.name);
                }

                // prepend a title part for dynamic content
                if (pathUnit.indexOf(':') !== -1) {
                    if (location && location.state && location.state.title) {
                        titleParts.unshift(location.state.title);
                    }
                }
                previousPathUnit = pathUnit;
            });
            titleParts.push(BASE_TITLE);
            title = titleParts.join(SEPARATOR);
        }
    }
    return title;
}