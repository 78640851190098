import { defineMessages } from "react-intl";

const ownersTableMessages = defineMessages({
    ownersTableHeading: {
        id: "ownersTableMessages.ownersTableHeading",
        defaultMessage: "Owners",
        description: "Owners table heading",
    },
    remove: {
        id: "ownersTabMessages.remove",
        defaultMessage: "Remove",
        description: "Remove label",
    },
    cannotRemoveAdmin: {
        id: "ownersTabMessages.cannotRemoveAdmin",
        defaultMessage: "You cannot remove Admin users",
        description: "Cannot remove label",
    },
    cannotRemoveAdminLoggedIn: {
        id: "ownersTabMessages.cannotRemoveAdminLoggedIn",
        defaultMessage: "As an Admin, you are automatically an Owner.",
        description: "Cannot remove label for logged-in admin",
    },
    ownerHelp: {
        id: "ownersTableMessages.ownerHelp",
        defaultMessage: "<b>Owners</b> can do anything in a Team space, including deleting it, and managing who has access (all <b>Organisational Admins</b> can also manage all Team spaces)",
        description: "Owner help",
    },
    youSuffix: {
        id: "ownersTableMessages.youSuffix",
        defaultMessage: " (you)",
        description: "You suffix",
    },
});

export default ownersTableMessages;
