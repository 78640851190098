import React, {useMemo} from "react";
import { DatePicker } from '@mui/x-date-pickers';
import FormControl from "@mui/material/FormControl";
import {toISODate, fromISOString} from "../../../util/dateUtils";
import LayoutWithKeyboardView from '../../../components/datePicker/LayoutWithKeyboardView';
import ToolbarWithKeyboardViewSwitch from '../../../components/datePicker/ToolbarWithKeyboardViewSwitch';

// The built-in date picked used by the react-awesome-query-builder library expects a different version of the
// @mui/x-date-pickers library, and the APIs do not match up. We cover over the gaps by providing our own picker.
// The value stored in the tree should be an iso-formatted date string, and the value we put into the picker
// needs to be a JavaScript Date instance.
export default function FeatureQueryDatePicker(props) {
    const {value, setValue, readonly, customProps} = props;
    const dateFormat = 'dd/MM/yyyy';
    const selectedDate = useMemo(function() {
        if(value && value.length) {
            //Do not use new Date(value) here, using fromISOString() fixes a bug where the first time you select on the date picker it would populate the previous day.
            return fromISOString(value);
        }
        return null;
    }, [value]);

    const handleChange = (currentDateTime) => {
        if(!currentDateTime || isNaN(currentDateTime)) {
            setValue('Invalid Date');
        } else {
            const dateString = toISODate(currentDateTime);
            setValue(dateString);
        }
    };

    return (
        <FormControl>
            <DatePicker
                disableFuture={true}
                readOnly={readonly}
                disabled={readonly}
                slots={{
                    layout: LayoutWithKeyboardView,
                    toolbar: ToolbarWithKeyboardViewSwitch
                }}
                format={dateFormat}
                views={['year', 'month', 'day']}
                value={selectedDate}
                onChange={handleChange}
                slotProps={{textField: {variant: 'standard'}}}
                {...customProps}
            />
        </FormControl>
    );
}
