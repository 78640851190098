import { defineMessages } from "react-intl";

const ownersTabMessages = defineMessages({
    noResultsAdminPrompt: {
        id: "ownersTabMessages.noResultsAdminPrompt",
        defaultMessage: "No results. To add a user not shown, <a>invite them to Data Hub</a>.",
        description: "No results admin prompt",
    },
    noResultsPrompt: {
        id: "ownersTabMessages.noResultsPrompt",
        defaultMessage: "No results. To add a user not shown, ask the admin to invite them to Data Hub.",
        description: "No results prompt",
    },
    ownerUserEntry: {
        id: "ownersTabMessages.ownerUserEntry",
        defaultMessage: "{firstName} {lastName} (you)",
        description: "Entry with user as an owner",
    },
    youSuffix: {
        id: "ownersTabMessages.youSuffix",
        defaultMessage: " (you)",
        description: "You suffix",
    },
});

export default ownersTabMessages;
