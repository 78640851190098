import {createUseStyles} from "react-jss";
import {defineMessages, FormattedMessage} from "react-intl";
import {Typography} from "@mui/material";
import React from "react";
import {SAMPLE_CATALOGUE} from "../../../../../shared/catalogues";
import {CatalogueTag} from "../../../../components/CatalogueTag";
import getProvider from "../../../../hooks/getProvider";
import providerLogo_OS from "./provider-logo-os.svg";

const messages = defineMessages({
    providedBy: {
        id: 'SampleDataTagAndProvider.providedBy',
        defaultMessage: 'Provided by {provider}',
        description: 'Provided By label'
    }
});

const styles = createUseStyles(theme => ({
    tagContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
            marginBottom: theme.spacing(3),
            marginLeft: theme.spacing(2)
        },
        [theme.breakpoints.down('lg')]: {
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2)
        }
    },
    providerImage: {
        maxWidth: '80px',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    }
}));

export default function SampleDataTagAndProvider({ product }) {
    const classes = styles();
    const providerName = getProvider(product);

    return (
        <div className={classes.tagContainer}>
            <CatalogueTag type={SAMPLE_CATALOGUE} size='md'/>
            <img src={providerLogo_OS} alt={`${providerName} logo`} className={classes.providerImage}></img>
            <Typography variant="caption" color="textSecondary">
                <FormattedMessage {...messages.providedBy} values={{provider: providerName}}/>
            </Typography>
        </div>
    )
}