import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import withStyles from 'react-jss';
import { osColour } from 'omse-components';
import { Doughnut } from 'react-chartjs-2';
import classNames from 'classnames';
import stripesSvg from '../../../../components/icons/stripes.svg';
import CostInfoTooltip from '../../../../components/payment/CostInfoTooltip';

const messages = defineMessages({
    openTitle: {
        id: 'APIUsage.openTitle',
        defaultMessage: 'OS OpenData',
        description: 'Title for the OS OpenData graphic'
    },
    openUsage: {
        id: 'APIUsage.openUsage',
        defaultMessage: 'of unlimited*',
        description: 'OS OpenData usage caption'
    },
    premiumTitle: {
        id: 'APIUsage.premiumTitle',
        defaultMessage: 'Premium data',
        description: 'Title for the Premium Data graphic'
    },
    freePremiumTitle: {
        id: 'APIUsage.freePremiumTitle',
        defaultMessage: 'Free Premium data',
        description: 'Title for the Premium Data graphic'
    },
    psgaTitle: {
        id: 'APIUsage.psgaTitle',
        defaultMessage: 'Public Sector data',
        description: 'Title for the Public Sector Data graphic'
    },
    internalTitle: {
        id: 'APIUsage.internalTitle',
        defaultMessage: 'OS Internal data',
        description: 'Title for the OS Internal data graphic'
    },
    eaiTitle: {
        id: "APIUsage.eaiTitle",
        defaultMessage: "Energy And Infrastructure",
        description: "Title for the E&I data graphic"
    },
    premiumUsage: {
        id: 'APIUsage.premiumUsage',
        defaultMessage: 'of £{limit, number} free**',
        description: 'Premium Data usage caption'
    },
    eaiUsage: {
        id: "APIUsage.eaiUsage",
        defaultMessage: "of {limit, number}",
        description: "Eai usage when user has bought an api plan"
    },
    eaiUsageNoPlans1: {
        id: "APIUsage.eaiUsageNoPlans1",
        defaultMessage: "Add an"
    },
    eaiUsageNoPlans2: {
        id: "APIUsage.eaiUsageNoPlans2",
        defaultMessage: "Energy & Infrastructure Plan"
    },
    devTitle: {
        id: 'APIUsage.devTitle',
        defaultMessage: 'Dev Premium',
        description: 'Title for the Premium Data graphic'
    },
    unlimitedUsage: {
        id: 'APIUsage.unlimitedUsage',
        defaultMessage: 'of unlimited**',
        description: 'Unlimited premium data usage caption'
    },
    numericUsageValue: {
        id: 'APIUsage.numericUsageValue',
        defaultMessage: '{count, number}',
        description: 'Count of data used'
    },
    compactDecimalTransactions: {
        id: 'APIUsage.compactDecimalTransactions',
        defaultMessage: '{count, number, ::.00 compact-short}',
        description: 'Compact display of transactions e.g. 12.45B'
    },
    upgradePrompt: {
        id: 'APIUsage.upgradePrompt',
        defaultMessage: 'Upgrade for',
        description: 'Upgrade prompt label'
    },
    upgradePrice: {
        id: 'APIUsage.upgradePrice',
        defaultMessage: '£1,000 free p/m**',
        description: 'Upgrade price label'
    },
    inclVat: {
        id: 'APIUsage.inclVat',
        defaultMessage: 'incl. VAT',
        description: 'incl. VAT'
    },
    unavailableBillPart1: {
        id: 'APIUsage.unavailableBillPart1',
        defaultMessage: 'Estimated bill currently',
        description: 'Estimated bill unavailable part 1'
    },
    unavailableBillPart2: {
        id: 'APIUsage.unavailableBillPart2',
        defaultMessage: 'unavailable',
        description: 'Estimated bill unavailable part 2'
    }
});

const graphicSize = '9.75rem';

function styles(theme) {
    return {
        root: {
            flex: '0 1 auto',
            marginRight: theme.spacing(4),
            '&:last-of-type': {
                marginRight: 0
            }
        },
        graphicContainer: {
            position: 'relative',
            marginBottom: theme.spacing(1.5),
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            height: graphicSize,
            width: graphicSize
        },
        graphKey: {
            height: 16,
            width: 16,
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(0.5)
        },
        keyLabel: {
            marginBottom: theme.spacing(1),
            color: osColour.neutral.stone,
            display: 'flex',
            '& > span': {
                flex: 'none'
            }
        },
        disableKeyLabel: {
            color: osColour.neutral.stone,
        },
        openDataKey: {
            backgroundColor: osColour.primary.lighterBerry
        },
        premiumDataKey: {
            backgroundColor: osColour.primary.foxglove
        },
        chargeablePremiumDataKey: {
            backgroundImage: `url(${stripesSvg})`
        },
        disabledKey: {
            backgroundColor: osColour.neutral.mist
        },
        psgaDataKey: {
            backgroundColor: osColour.primary.foxglove
        },
        eaiDataKey:{
            backgroundColor: osColour.primary.foxglove
        },
        graphLabel: {
            position: 'relative',
            zIndex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            '& span': {
                lineHeight: 1.2
            }
        },
        premiumData: {
            '& span': {
                color: osColour.primary.foxglove
            }
        },
        chargeablePremiumData: {
            '& span': {
                color: osColour.primary.foxglove
            }
        },
        openData: {
            '& span': {
                color: osColour.primary.berry
            }
        },
        psgaData: {
            '& span': {
                color: osColour.primary.foxglove
            }
        },
        eaiData:{
            textAlign:"center",
            marginLeft:theme.spacing(2),
            marginRight:theme.spacing(2),
            '& span': {
                color: osColour.primary.foxglove
            }
        },
        disabledData: {
            '& span': {
                color: osColour.neutral.stone
            }
        },
        totalUsed: {
            fontFamily: 'OSGill, sans-serif',
            fontSize: '1.25rem',
            fontWeight: 'normal'
        },
        totalUsedInfo: {
            fontFamily: 'OSGill, sans-serif',
            fontSize: '0.8125rem',
            fontWeight: 'normal'
        },
        chart: {
            position: 'absolute',
            height: '100%',
            width: '100%'
        },
        tooltip: {
            '& svg': {
                color: osColour.primary.foxglove
            }
        },
        canvasWrapper: {
            height: '100%',
            width: '100%'
        }
    }
}

const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
        arc: {
            borderWidth: 0
        }
    },
    cutout: '80%',
    plugins: {
        tooltip: {
            enabled: false
        },
        legend: {
            display: false
        }
    }
};

function APIUsageChart(props) {
    const { classes, variant, chartData,
        transactions, vatRateAvailable, loading, price, limit } = props;
    let keyLabelClass = classes.keyLabel;
    let keyClass = classes.openDataKey;
    let keyMessage = messages.openTitle;
    let labelClass = classes.openData;
    let usedMessage = messages.numericUsageValue;
    let remainingMessage = messages.openUsage;
    let priceFormatted = null;
    let showUnavailableBillMsg = false;
    let showInclVat = false;

    if (variant === 'freePremium') {
        keyClass = classes.premiumDataKey;
        keyMessage = messages.freePremiumTitle;
        labelClass = classes.premiumData;
        usedMessage = messages.numericUsageValue;
        remainingMessage = messages.premiumUsage;
        priceFormatted = '£' + (price/100.0).toFixed(2);
    }

    if (variant === 'chargeablePremium') {
        keyClass = classes.chargeablePremiumDataKey;
        keyMessage = messages.premiumTitle;
        labelClass = classes.chargeablePremiumData;
        usedMessage = messages.numericUsageValue;
        remainingMessage = null;
        if (vatRateAvailable) {
            const cost = (price / 100.0).toFixed(2);
            priceFormatted = '£' + cost;
            if (cost > 0) {
                showInclVat = true;
            }
        } else {
            if (price > 0) {
                showUnavailableBillMsg = true;
            } else {
                priceFormatted = '£0.00'
            }
        }
    }

    if (variant === 'devPremium') {
        keyClass = classes.premiumDataKey;
        keyMessage = messages.devTitle;
        labelClass = classes.premiumData;
        usedMessage = messages.numericUsageValue;
        remainingMessage = messages.unlimitedUsage;
    }

    if (variant === 'disabledPremium') {
        keyLabelClass = classes.disableKeyLabel;
        keyClass = classes.disabledKey;
        labelClass = classes.disabledData;
        keyMessage = messages.premiumTitle;
        usedMessage = messages.upgradePrompt;
        remainingMessage = messages.upgradePrice;
    }

    if (variant === 'psga') {
        keyClass = classes.psgaDataKey;
        keyMessage = messages.psgaTitle;
        labelClass = classes.psgaData;
        usedMessage = messages.numericUsageValue;
        remainingMessage = messages.unlimitedUsage;
    }

    if (variant === 'internal') {
        keyClass = classes.premiumDataKey;
        keyMessage = messages.internalTitle;
        labelClass = classes.premiumData;
        usedMessage = messages.numericUsageValue;
        remainingMessage = messages.unlimitedUsage;
    }

    if (variant === 'eai') {
        keyClass = classes.eaiDataKey;
        keyMessage = messages.eaiTitle;
        labelClass = classes.eaiData;
        usedMessage = messages.numericUsageValue;
        remainingMessage = messages.eaiUsage;
        if(limit === 1){
            usedMessage = messages.eaiUsageNoPlans1;
            remainingMessage = messages.eaiUsageNoPlans2;
        }
    }

    if (variant === 'devEai') {
        keyClass = classes.premiumDataKey;
        keyMessage = messages.devTitle;
        labelClass = classes.premiumData;
        usedMessage = messages.numericUsageValue;
        remainingMessage = messages.unlimitedUsage;
    }

    const chartTransactionsDisplayLimit = 999999999;
    if ((usedMessage === messages.numericUsageValue) && (transactions > chartTransactionsDisplayLimit)) {
        usedMessage = messages.compactDecimalTransactions;
    }

    const labelId = variant + 'label';
    const statsId = variant;
    const ariaDescBy = `${labelId} ${statsId}`;

    return (
        <div className={classes.root}>
                <Typography id={labelId} variant='body2' className={classNames(keyLabelClass, classes.keyLabel)}>
                    <span className={classNames(keyClass, classes.graphKey)}></span>
                    <FormattedMessage {...keyMessage}/>
                </Typography>
                <div className={classNames(classes.graphicContainer)}>
                {!loading && (
                    <p id={statsId} className={classNames(labelClass, classes.graphLabel)}>
                        <Typography className={classes.totalUsed} component='span'>
                            <FormattedMessage {...usedMessage} values={{count: transactions}}/>
                        </Typography>
                        {priceFormatted && (
                            <Typography className={classes.totalUsed} component='span'>
                                {priceFormatted}
                            </Typography>
                        )}
                        {showInclVat && (
                            <Typography className={classes.totalUsedInfo} component='span'>
                                <FormattedMessage {...messages.inclVat}/>
                            </Typography>
                        )}
                        {showInclVat && (
                            <CostInfoTooltip classes={{tooltip: classes.tooltip}}/>
                        )}
                        {remainingMessage && (
                            <Typography className={classes.totalRemaining} component='span'>
                                <FormattedMessage {...remainingMessage} values={{limit: limit}}/>
                            </Typography>
                        )}
                        {showUnavailableBillMsg && (
                            <>
                                <Typography className={classes.totalUsedInfo} component='span'>
                                    <FormattedMessage {...messages.unavailableBillPart1}/>
                                </Typography>
                                <Typography className={classes.totalUsedInfo} component='span'>
                                    <FormattedMessage {...messages.unavailableBillPart2}/>
                                </Typography>
                            </>
                        )}
                    </p>
                )}
                <div className={classes.chart}>
                    <div aria-labelledby={labelId} aria-describedby={ariaDescBy} role="img" className={classes.canvasWrapper}>
                        <Doughnut data={chartData} options={chartOptions} />
                    </div>
                </div>
            </div>
        </div>
    );
}

APIUsageChart.propTypes = {
    classes: PropTypes.object.isRequired,
    variant: PropTypes.oneOf(['open', 'freePremium', 'chargeablePremium', 'devPremium', 'disabledPremium', 'psga', 'internal', 'eai', 'devEai']).isRequired,
    chartData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    transactions: PropTypes.number.isRequired,
    vatRateAvailable: PropTypes.bool,
    price: PropTypes.number,
    limit: PropTypes.number,
}

export default withStyles(styles)(APIUsageChart);
