import { defineMessages } from "react-intl";

const errorHandlingMessages = defineMessages({
    nameFieldRequired: {
        id: "errorHandlingMessages.nameFieldRequired",
        description: "User interaction error > nameFieldRequired",
        defaultMessage: "Name is required.",
    },
    nameFieldLengthInvalid: {
        id: "errorHandlingMessages.nameFieldLengthInvalid",
        description: "User interaction error > nameFieldLengthInvalid",
        defaultMessage: "Name must be between 1 and 255 characters.",
    },
    descriptionFieldLengthInvalid: {
        id: "errorHandlingMessages.descriptionFieldLengthInvalid",
        description: "User interaction error > descriptionFieldLengthInvalid",
        defaultMessage: "Description must be between 0 and 255 characters.",
    },
    invalidRole: {
        id: "errorHandlingMessages.invalidRole",
        description: "User interaction error > invalidRole",
        defaultMessage: "Invalid user role. Please contact your organisation administrator.",
    },
    invalidPermission: {
        id: "errorHandlingMessages.invalidPermission",
        description: "User interaction error > invalidPermission",
        defaultMessage: "Permission is not allowed based on the user's role.",
    },
    genericUnauthorized: {
        id: "errorHandlingMessages.genericUnauthorized",
        description: "User interaction error > genericUnauthorized",
        defaultMessage: "Unauthorized. Please contact your organisation administrator.",
    },
    genericServerError: {
        id: "errorHandlingMessages.genericServerError",
        description: "User interaction error > Generic Server Error",
        defaultMessage: "We ran into a problem our end creating that team. Please try again and if the problem persists, please {link}",
    },
});

export default errorHandlingMessages;
