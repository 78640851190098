import { useState, useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import newTeamSpaceMessages from "./NewTeamSpace.msg";
import { AddButton, ExternalLink, osColour, theme, useActionIdSelector } from "omse-components";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CONTRACTOR_READ_ONLY_ROLE, CONTRACTOR_ROLE } from "../../../shared/roles";
import {
    Button,
    Dialog,
    Grow,
    Box,
    IconButton,
    Stepper,
    Step,
    StepLabel,
    Typography,
} from "@mui/material";
import {
    AddCircle as AddCircleIcon,
    Close as CloseIcon,
    KeyboardArrowLeft as KeyboardArrowLeftIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon,
} from "@mui/icons-material";
import TeamSpaceAccess from "./newTeamSpace/TeamSpaceAccess";
import TeamSpaceDetails from "./newTeamSpace/TeamSpaceDetails";
import { createTeamSpace, getOrgUsers } from "../../modules/teamSpaces/actions";
import routePaths, { useRedirect } from "../../util/routes";
import { errorFormattedMessage } from "./util/errorHandling";

const Container = styled(Box)`
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
    & .error {
        color: ${osColour.status.error};
    }
`;

const StepContainer = styled(Box)(
    ({ theme }) => `
    padding: ${theme.spacing(1)} 0;
    ${theme.breakpoints.up("sm")} {
        min-height: 28em;
    }
`
);

const ControlTop = styled(Box)`
    margin: 0;
    padding: ${theme.spacing(2)} ${theme.spacing(3)};
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${theme.spacing(3)};
`;

const StepConnector = styled("span")(
    ({ isComplete }) => `
    margin: 0 0.6em;
    min-width: ${theme.spacing(5)};
    border-bottom: 2px solid ${isComplete ? osColour.primary.berry : osColour.neutral.rock};
`
);

const ControlBottom = styled(ControlTop)`
    padding: ${theme.spacing(3)} ${theme.spacing(4)};
    background: ${osColour.neutral.clouds};
    & .MuiButton-root {
        /* Prevents change in button width between steps. */
        min-width: ${theme.spacing(16)};
    }
    ${theme.breakpoints.down("sm")} {
        /* Prevents overlap with Cookie Control. */
        justify-content: end;
    }
`;

export default function NewTeamSpace() {
    const user = useSelector((state) => state.user.current);

    function isContractor(role) {
        return role === CONTRACTOR_ROLE || role === CONTRACTOR_READ_ONLY_ROLE;
    }

    if (user.loading || isContractor(user.result.role)) {
        return null;
    }

    return <NewTeamSpaceDialog />;
}

const defaultNewTeamSpaceParameters = {
    name: "",
    description: "",
    locked: false,
    // ...
};

function NewTeamSpaceDialog() {
    const intl = useIntl();
    const redirect = useRedirect();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.values.sm}px)`);
    const [showDialog, setShowDialog] = useState(false);
    const [activeStepId, setActiveStepId] = useState(0);
    const [disableNextAction, setDisableNextAction] = useState(true);
    const [owners, setOwners] = useState([]);
    const [members, setMembers] = useState([]);

    const [newTeamSpaceParameters, setNewTeamSpaceParameters] = useState(
        defaultNewTeamSpaceParameters
    );
    const [createTeamSpaceNameResult, dispatch] = useActionIdSelector("teamSpaces.createTeamSpace");
    const loadingOrgUsers = useSelector((state) => state.teamSpaces.getOrgUsers.loading);

    const selectedUserIds = useMemo(() => {
        const ids = [
            ...owners.map((owner) => owner.datahubDeveloperId),
            ...members.map((member) => member.datahubDeveloperId),
        ];
        return ids;
    }, [owners, members]);

    useEffect(() => {
        if (createTeamSpaceNameResult.result) {
            if (createTeamSpaceNameResult.result.content?.id) {
                redirect.push(
                    routePaths.teamSpace.replace(
                        ":teamSpaceId",
                        createTeamSpaceNameResult.result.content.id
                    )
                );
            }
        }
    }, [createTeamSpaceNameResult, redirect]);

    useEffect(() => {
        if (showDialog) {
            dispatch(getOrgUsers());
        }
    }, [showDialog, dispatch]);

    const handleCloseEvent = () => {
        setShowDialog(false);

        // Remove user information from the state
        // AFTER the dialog has fully closed.
        setTimeout(() => {
            setActiveStepId(0);
            setNewTeamSpaceParameters(defaultNewTeamSpaceParameters);
            setDisableNextAction(true);
            setOwners([]);
            setMembers([]);
        }, 250);
    }

    function handleCreateTeamSpace() {
        dispatch(
            createTeamSpace({
                ...newTeamSpaceParameters,
                name: newTeamSpaceParameters.name.trimEnd(),
                ownerDatahubDeveloperIds: owners.map((owner) => owner.datahubDeveloperId),
                members: members,
            })
        );
    }

    return (
        <>
            <Button
                variant="contained"
                data-testid="open-dialog-createteamspace"
                startIcon={<AddCircleIcon />}
                onClick={() => setShowDialog(true)}
            >
                {intl.formatMessage(newTeamSpaceMessages.openDialogButton)}
            </Button>
            {showDialog && (
                <Dialog
                    maxWidth={"sm"}
                    fullWidth={true}
                    fullScreen={isMobile}
                    open={true}
                    TransitionComponent={Grow}
                >
                    <Container>
                        <ControlTop>
                            <Stepper
                                activeStep={activeStepId}
                                connector={
                                    !isMobile && (
                                        <StepConnector
                                            aria-hidden={true}
                                            isComplete={activeStepId >= 1}
                                        />
                                    )
                                }
                            >
                                <Step data-testid="dialog-createteamspace-step1">
                                    <StepLabel>
                                        {intl.formatMessage(newTeamSpaceMessages.stepLabelDetails)}
                                    </StepLabel>
                                </Step>
                                <Step data-testid="dialog-createteamspace-step2">
                                    <StepLabel>
                                        {intl.formatMessage(newTeamSpaceMessages.stepLabelAccess)}
                                    </StepLabel>
                                </Step>
                            </Stepper>
                            <IconButton
                                data-testid="close-dialog-createteamspace"
                                onClick={() => handleCloseEvent()}
                                aria-label={intl.formatMessage(
                                    newTeamSpaceMessages.closeButtonAriaLabel
                                )}
                            >
                                <CloseIcon />
                            </IconButton>
                        </ControlTop>
                        <StepContainer>
                            {createTeamSpaceNameResult.error && (
                                <Typography variant="body1" className="error">
                                    <FormattedMessage
                                        {...errorFormattedMessage(createTeamSpaceNameResult)}
                                        values={{ link: <ExternalLink type="support" /> }}
                                    />
                                </Typography>
                            )}
                            {!activeStepId && (
                                <TeamSpaceDetails
                                    newTeamSpaceParameters={newTeamSpaceParameters}
                                    setNewTeamSpaceParameters={setNewTeamSpaceParameters}
                                    setDisableNextAction={setDisableNextAction}
                                />
                            )}
                            {activeStepId >= 1 && (
                                <TeamSpaceAccess
                                    newTeamSpaceParameters={newTeamSpaceParameters}
                                    setNewTeamSpaceParameters={setNewTeamSpaceParameters}
                                    setDisableNextAction={setDisableNextAction}
                                    owners={owners}
                                    setOwners={setOwners}
                                    members={members}
                                    setMembers={setMembers}
                                    selectedUserIds={selectedUserIds}
                                />
                            )}
                        </StepContainer>
                        <ControlBottom>
                            <Box>
                                {!activeStepId && (
                                    <Button
                                        variant="outlined"
                                        data-testid="cancel-dialog-createteamspace"
                                        onClick={() => handleCloseEvent()}
                                    >
                                        {intl.formatMessage(newTeamSpaceMessages.cancelButton)}
                                    </Button>
                                )}
                                {activeStepId >= 1 && (
                                    <Button
                                        variant="outlined"
                                        onClick={() => setActiveStepId(0)}
                                        startIcon={<KeyboardArrowLeftIcon />}
                                    >
                                        {intl.formatMessage(newTeamSpaceMessages.backButton)}
                                    </Button>
                                )}
                            </Box>
                            <Box>
                                {!activeStepId && (
                                    <Button
                                        variant="contained"
                                        disabled={disableNextAction}
                                        onClick={() => setActiveStepId(1)}
                                        endIcon={<KeyboardArrowRightIcon />}
                                    >
                                        {intl.formatMessage(newTeamSpaceMessages.nextButton)}
                                    </Button>
                                )}
                                {activeStepId >= 1 && (
                                    <AddButton
                                        variant="contained"
                                        label={newTeamSpaceMessages.doneButton}
                                        showIcon={false}
                                        working={createTeamSpaceNameResult.working}
                                        disabled={
                                            disableNextAction ||
                                            loadingOrgUsers ||
                                            createTeamSpaceNameResult.working
                                        }
                                        action={handleCreateTeamSpace}
                                    >
                                        {intl.formatMessage(newTeamSpaceMessages.doneButton)}
                                    </AddButton>
                                )}
                            </Box>
                        </ControlBottom>
                    </Container>
                </Dialog>
            )}
        </>
    );
}
