import React, { useState} from 'react';
import { useSelector} from 'react-redux';
import { createUseStyles } from 'react-jss';
import { styles } from "../../DownloadStyles";
import { defineMessages, FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { DropDownMenu } from 'omse-components';
import OpenDataDownloadsItem from './OpenDataDownloadsItem';
import routes from "../../../../util/routes";
import SearchBox from "../../../../components/SearchBox";
import { TotalResultsBar } from '../../../../components/TotalResultsBar';
import getProvider from "../../../../hooks/getProvider";
import {useQuery} from "@tanstack/react-query";
import {fetchCatalogue} from "../../../../modules/downloads";

const useStyles = createUseStyles(styles);

const messages = defineMessages({
    title: {
        id: 'OpenDataDownloads.title',
        defaultMessage: 'OpenData Downloads',
        description: 'Label for OS OpenData downloads'
    },
    subtitle: {
        id: 'OpenDataDownloads.subtitle',
        defaultMessage: 'Ordnance Survey provides this free service to download OpenData geospatial datasets from OS and other data providers.',
        description: 'Sub title for OS OpenData downloads'
    },
    searchLabel: {
        id: 'OpenDataDownloads.searchLabel',
        defaultMessage: 'Search OpenData downloads',
        description: 'Label for Search OS OpenData downloads'
    },
    searchPlaceholder: {
        id: 'OpenDataDownloads.searchPlaceholder',
        defaultMessage: 'Search by name',
        description: 'Placeholder for Search OS OpenData downloads'
    },
    allData: {
        id: 'OpenDataDownloads.allDataLabel',
        defaultMessage: 'All types of data',
        description: 'Label for All data'
    },
    lookups: {
        id: 'OpenDataDownloads.lookups',
        defaultMessage: 'Lookups',
        description: 'Lookups label'
    },
    networks: {
        id: 'OpenDataDownloads.networks',
        defaultMessage: 'Networks',
        description: 'Networks label'
    },
    heights: {
        id: 'OpenDataDownloads.heights',
        defaultMessage: 'Heights',
        description: 'Heights label'
    },
    greenspace: {
        id: 'OpenDataDownloads.greenspaces',
        defaultMessage: 'Greenspaces',
        description: 'Greenspaces label'
    },
    mapping: {
        id: 'OpenDataDownloads.mapping',
        defaultMessage: 'Mapping',
        description: 'Mapping label'
    },
    clearSearch: {
        id: 'OpenDataDownloads.clearSearch',
        defaultMessage: 'Clear search',
        description: 'Clear search label'
    },
    allProviders: {
        id: 'OpenDataDownloads.allDataProviders',
        defaultMessage: 'All data providers',
        description: 'Label for all data providers'
    },
    geological: {
        id: 'OpenDataDownloads.geological',
        defaultMessage: 'Geological',
        description: 'Geological label'
    }
});

export default function OpenDataDownloads() {
    const classes = useStyles();
    const { downloadsUrl } = useSelector(state => state.config.current.result);
    const { data: result, isLoading: loading} = useQuery({queryKey:["catalogues"],  queryFn: () => fetchCatalogue(downloadsUrl)});

    const allData = messages.allData.defaultMessage;
    const allProviders = messages.allProviders.defaultMessage;
    const [search, setSearch] = useState("");
    const [selectedCategoryFilterOption, setSelectedCategoryFilterOption] = useState(allData);
    const [selectedProviderFilterOption, setSelectedProviderFilterOption] = useState(allProviders);

    const categoryFilterOptions = [{
        id: allData,
        label: allData,
        value: allData
    }];

    const providerFilterOptions = [{
        id: allProviders,
        label: allProviders,
        value: allProviders
    }];

    if (result) {
        result
            .map(catalogueItem => catalogueItem.category)
            .filter((value, index, array) => array.indexOf(value) === index)
            .sort()
            .forEach(category => {
                categoryFilterOptions.push({
                    id: category,
                    label: messages[category.toLowerCase()] || category,
                    value: category
                })
            });

        result
            .map(catalogueItem => getProvider(catalogueItem))
            .filter((value, index, array) => array.indexOf(value) === index)
            .sort()
            .forEach(provider => {
                providerFilterOptions.push({
                    id: provider,
                    label: provider,
                    value: provider
                })
            });
    }

    const filteredDownloads = [];

    if (result) {
        const catalogueFilteredDownloads = result.filter(catalogueItem =>
            catalogueItem.category === selectedCategoryFilterOption || selectedCategoryFilterOption === allData);

        const providerFilteredDownloads = catalogueFilteredDownloads.filter(catalogueItem => {
            if(allProviders === selectedProviderFilterOption) {
                return true
            }
            return getProvider(catalogueItem) === selectedProviderFilterOption
        });

        const searchTerms = search.toLowerCase().trim().split(' ');
        searchTerms
            .reduce((previous, term) => {
                const filtered = providerFilteredDownloads.filter(catalogueItem =>
                    previous.indexOf(catalogueItem) === -1 & catalogueItem.name.toLowerCase().indexOf(term) !== -1);

                return previous.concat(filtered);
            }, [])
            .forEach(matchingDownload => filteredDownloads.push(matchingDownload));
    }

    return (
        <>
            <header className={classes.root}>
                <Typography variant='h1' color='primary'>
                    <FormattedMessage {...messages.title} />
                </Typography>
                <Typography variant='body1' color='textPrimary'>
                    <FormattedMessage {...messages.subtitle} />
                </Typography>
            </header>
            <div className={classes.content}>
                <div className={classes.controls}>
                    <SearchBox label={messages.searchLabel}
                               placeholder={messages.searchPlaceholder}
                               search={search}
                               setSearch={setSearch}
                               className={classes.searchOption}
                    />

                    <div className={classes.filterControls}>
                        <DropDownMenu
                            buttonId='dataFilter'
                            buttonProps={{'aria-label': 'Filter by data structure'}}
                            buttonFontWeight='bold'
                            value={selectedCategoryFilterOption}
                            buttonVariant='outlined'
                            buttonClassName={classes.filterControl}
                            items={categoryFilterOptions}
                            onChange={selection => setSelectedCategoryFilterOption(selection.value)}
                        />

                        <DropDownMenu
                            buttonId='providerFilter'
                            buttonProps={{'aria-label': 'Filter by provider'}}
                            buttonFontWeight='bold'
                            value={selectedProviderFilterOption}
                            buttonVariant='outlined'
                            buttonClassName={classes.filterControl}
                            items={providerFilterOptions}
                            onChange={selection => setSelectedProviderFilterOption(selection.value)}
                        />
                    </div>
                    <TotalResultsBar isLoading={loading} totalResults={filteredDownloads.length} />
                </div>

                <div className={classes.downloads} aria-live='polite' data-testid="downloads">
                    {(loading)
                        ? <CircularProgress size={32} className={classes.loader} />
                        : filteredDownloads.map((item) => (
                            <OpenDataDownloadsItem
                                item={item}
                                key={item.name}
                                path={routes.openDataItem.replace(':downloadId', item.id)}
                            />
                        ))
                    }
                </div>
            </div>
        </>
    );
}
