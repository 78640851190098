import styled from "@emotion/styled";
import { contentPadding } from "omse-components";

export const ContentContainer = styled("section")`
    padding-top: ${contentPadding.top / 2}px;
    padding-left: ${contentPadding.left / 2}px;
    padding-right: ${contentPadding.right / 2}px;
    padding-bottom: ${contentPadding.bottom / 2}px;
    max-width: ${contentPadding.maxWidth}px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    gap: 1em;
`;
