import {useLocation, useRouteMatch} from "react-router-dom";
import {useSelector} from "react-redux";
import {useQuery} from "@tanstack/react-query";
import {fetchDownloads, fetchProduct} from "../modules/downloads";

const getProductResult = (productId, location, product, productLoading) => {
    if (location?.state?.item) {
        product = location.state.item;
        productLoading = false;
    } else if (product?.id && product.id !== productId) {
        product = null;
        productLoading = true;
    }

    return {
        product,
        productLoading
    }
}

const getDownloadsResult = (productId, downloads, downloadsLoading) => {
    if (downloads?.id && downloads.id !== productId) {
        downloads = null;
        downloadsLoading = true;
    }

    return {
        downloads: downloads || [],
        downloadsLoading
    }
}

const getError = (productId, productResultError, downloadsResultError) => {
    const isError =
        productResultError && productResultError.id === productId &&
        downloadsResultError && downloadsResultError.id === productId;
    return {
        error: isError
    }
}

export default function useOpenDataItem() {
    const match = useRouteMatch();
    const location = useLocation();
    const { downloadsUrl } = useSelector((state) => {
        return state.config.current.result;
    });
    const productId = match.params.downloadId;
    const { data: productResult, isLoading: productResultLoading, error: productResultError} = useQuery({
        queryKey:["product"],
        queryFn: () => fetchProduct(downloadsUrl, productId),
        retry: (failureCount, error) => error.status === 404 ? 0 : 3 - failureCount
    });
    const { data: downloadsResult, isLoading: downloadsResultLoading, error: downloadsResultError} = useQuery({
        queryKey:["downloads"],
        queryFn: () => fetchDownloads(downloadsUrl, productId),
        retry: (failureCount, error) => error.status === 404 ? 0 : 3 - failureCount
    });


    return {
        ...getProductResult(productId, location, productResult, productResultLoading),
        ...getDownloadsResult(productId, downloadsResult, downloadsResultLoading),
        ...getError(productId, productResultError, downloadsResultError)
    }
}