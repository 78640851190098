import React, {useMemo, useRef, useEffect, useState, useCallback} from 'react';
import {Query, Builder, Utils as QbUtils} from '@react-awesome-query-builder/mui';
import './QueryBuilder.css';
import {useSelector, useDispatch} from "react-redux";
import {Button, createTheme, ThemeProvider, Typography} from "@mui/material";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {createUseStyles} from 'react-jss';
import {setCurrentQueryTree, setFeatureTypeFilter} from "../../../modules/recipeEditor/actions";
import classNames from 'classnames';
import buildConfig from "./buildConfig";
import {ReactComponent as FilterIcon} from "../../../components/icons/filter.svg";
import {LinkButton, Notification} from "omse-components";
import queryBuilderLabelMaker from "./queryBuilderLabelMaker";
import { themeConfig } from "omse-components";

// NGD-232723
const theme = createTheme(themeConfig, {
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                popper: {
                    width: 'fit-content !important'
                }
            }
        }
    }
});

const useStyles = createUseStyles(theme => ({
    queryBuilder: {
        padding: theme.spacing(3),
        paddingRight: `calc(${theme.spacing(3)} - 10px)` // Adjust the right margin, as the query builder css will move it by 10 anyway
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(2),
    },
    floatRight: {
        marginLeft: 'auto',
        marginRight: theme.spacing(3)
    }
}));

const messages = defineMessages({
    applyButton: {
        id: 'FeatureFilterQueryBuilder.applyButton',
        defaultMessage: 'Apply filter',
        description: 'Apply filter button text'
    },
    clearFilterButton: {
        id: 'FeatureFilterQueryBuilder.clearFilterButton',
        defaultMessage: 'Clear all filters',
        description: 'Text for clear all filters link button'
    },
    filterApplied: {
        id: 'FeatureFilterQueryBuilder.filterApplied',
        defaultMessage: 'You have successfully applied your filter. You can edit it or close the filter panel.',
        description: 'Toast message for applied filters'
    }
});

function hasChanges(savedQuery, expression) {
    return Boolean((!savedQuery && expression && expression.length) ||
        (savedQuery !== expression && !(!savedQuery && !expression)));
}

const TREE_ID = QbUtils.uuid();
const EMPTY_TREE = QbUtils.loadTree({id: TREE_ID, type: "group", path: [TREE_ID]});

// We do not use React.useState() in this component, as the component will be unmounted when the panel that
// contains it is closed. Instead, we need to put the component state into the redux store.
export default function FeatureFilterQueryBuilder() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const intl = useIntl();
    const [showToast, setShowToast] = useState(false);

    const {result: featureTypeSchema} = useSelector(state => state.recipes.featureTypeSchema);
    const featureTypes = useSelector(state => state.recipeEditor.featureTypes);

    const selectedFeatureId = featureTypeSchema.featureId;
    const selectedFeatureType = featureTypes.find(item => item.featureTypeId === selectedFeatureId);

    // There are two possible locations for the tree state in the redux store.
    // If the user is part way through an edit then the current query tree will be the most up to date.
    // If they are resuming an edit then the featureTypes array will contain a tree.
    // If neither of those has a tree then we should create a new one.

    let {tree, expression, canSave} = useSelector(state => state.recipeEditor.currentQueryTree);
    if(!tree && selectedFeatureType) {
        tree = selectedFeatureType.filterTree;
    }
    if(!tree) {
        tree = EMPTY_TREE;
    }

    const queryBuilderRef = useRef(null);
    useEffect(() => {
        // Label up the query builder for screen readers
        queryBuilderLabelMaker(queryBuilderRef.current, intl);
    }, [tree, intl]); // Set up new labels whenever the tree changes

    const savedQuery = selectedFeatureType && selectedFeatureType.filterExpression;

    const config = useMemo(function() {
        return buildConfig(featureTypeSchema);
    }, [featureTypeSchema]);

    const renderBuilder = (props) => (
        <div ref={queryBuilderRef} className={classNames("query-builder-container", classes.queryBuilder)}>
            <div className="query-builder qb-lite">
                <ThemeProvider theme={theme}>
                    <Builder {...props} />
                </ThemeProvider>
            </div>
        </div>
    )

    // We wrap this change function in a callback so that the value of the function is as stable as possible,
    // e.g. so that we don't inject new props into the Query component when we do not have to.
    const onChange = useCallback((immutableTree, config) => {
        if(immutableTree === EMPTY_TREE || immutableTree === selectedFeatureType.filterTree) {
            // This is not real change, as the existing tree has just been passed back to us without alteration
            return;
        }

        const checkTree = QbUtils.checkTree(immutableTree, config);
        let query = QbUtils.sqlFormat(checkTree, config);

        // Todo : Reapply the extra validation steps for blank operators, fields and values
        // QueryTreeUtils.validateTree(tree) instead of QbUtils.isValidTree(tree)
        let canSave = QbUtils.isValidTree(immutableTree, config) && hasChanges(savedQuery, query);
        dispatch(setCurrentQueryTree(immutableTree, query, canSave, true));
    }, [dispatch, savedQuery, selectedFeatureType]);

    function handleApplyFilters() {
        dispatch(setFeatureTypeFilter(selectedFeatureId, expression, tree));
        dispatch(setCurrentQueryTree());
        setShowToast(true);
    }

    function handleClearAll() {
        if(savedQuery) {
            dispatch(setCurrentQueryTree(EMPTY_TREE, null, true, true));
        } else {
            dispatch(setCurrentQueryTree(EMPTY_TREE, null, false, false));
        }
    }

    // Hacky code to help debug the tree while running locally
    if(window.location.hostname === 'localhost') {
        console.log(`Rendering tree.\nexpression: ${expression}`);
        console.dir(tree);
    }

    return <div className={classes.root}>
        <LinkButton className={classes.floatRight}
                    onClick={handleClearAll}>
            <FilterIcon aria-hidden={true} />
            <FormattedMessage {...messages.clearFilterButton}/>
        </LinkButton>
        <Query {...config}
               renderBuilder={renderBuilder}
               value={tree}
               onChange={onChange}/>
        <Button key='apply'
                onClick={handleApplyFilters}
                color='primary'
                variant='contained'
                disabled={!canSave}
                className={classes.floatRight}>
            <FormattedMessage {...messages.applyButton}/>
        </Button>
        {showToast && <Notification variant='success' appearance='toast' onClose={() => {setShowToast(false);}} autoClose={true}>
            <Typography role='alert' variant='body1'>
                <FormattedMessage {...messages.filterApplied}/>
            </Typography>
        </Notification>}
    </div>;
}