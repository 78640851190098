import { useEffect } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import Link from '../../../components/Link';
import routes from "../../../util/routes";
import { defineMessages, FormattedDate, FormattedMessage } from "react-intl";
import { border1, osColour } from 'omse-components';
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import ProjectMode from "../../../components/ProjectMode";
import { getPaymentConfigData } from "../../../modules/payments/actions";
import styled from '@emotion/styled';
import ProjectStats from './ProjectStats';

const messages = defineMessages({
    openData: {
        id: 'ProjectListEntry.openData',
        defaultMessage: 'OS OpenData',
        description: 'Label for the OS OpenData part of the usage graph'
    },
    premiumData: {
        id: 'ProjectListEntry.premiumData',
        defaultMessage: 'Premium data',
        description: 'Label for the premium data part of the usage graph'
    },
    projectCreatedDate: {
        id: 'ProjectListEntry.createdDate',
        defaultMessage: 'Created: ',
        description: 'Label for the created date label'
    },
    organisationName: {
        id: 'ProjectListEntry.organisationName',
        defaultMessage: '({orgName})',
        description: 'Label for the organisation name'
    }
});

const StyledListItem = styled(ListItem)(({ theme }) => `
    margin-bottom: ${theme.spacing(1)};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 0;
    padding-right: 0;
    border-top: ${border1};
    ${theme.breakpoints.down('sm')} {
        padding-top: ${theme.spacing(1.5)};
        padding-bottom: 0;
        margin-bottom: 0;
    }
    & .titleContainer {
        flex: 1 1 auto;
    }
    & .linkStyle {
        font-size: ${theme.typography.h2.fontSize};
        font-weight: ${theme.typography.h2.fontWeight};
    }
    & .usageContainer {
        width: 100%;
    }
    & .listHeader {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        flex-wrap: wrap;
        margin-bottom: ${theme.spacing(1)};
        ${theme.breakpoints.down('sm')} {
            justify-content: unset;
        }
        & a {
            margin-right: ${theme.spacing(1)};
        }
    }
    & .created {
      display: flex;
      flex-wrap: wrap;
      margin-top: ${theme.spacing(1)};
    }
    & .createdLabel {
        color: ${osColour.neutral.stone};
    }
    & .createdDate {
        margin-left: ${theme.spacing(1)};
        margin-right: ${theme.spacing(1)};
    }
    & .orgName {
        margin-right: ${theme.spacing(1)};
    }
`);

const ProjectListEntry = (props) => {
    const { project, orgName, userStatsResult, className } = props;
    const dispatch = useDispatch();
    const {result, error, loading} = useSelector(state => state.payments.config);
    const vatRate = result?.vatRate;

    useEffect(() => {
        if (!vatRate && !loading && !error) {
            dispatch(getPaymentConfigData());
        }
    }, [vatRate, loading, error, dispatch]);

    return (
        <StyledListItem key={project.projectId} className={className}>
            <div className='listHeader'>
                <div className='titleContainer'>
                    <Link path={routes.project.replace(':projectId', project.projectId)}
                        state={{
                            projectName: project.name
                        }}
                        search={project.orgId ? { orgId: project.orgId } : undefined}
                        className='linkStyle'>
                        {project.name}
                    </Link>
                </div>
                <div className='created'>
                    <Typography component='span' className='createdLabel' variant='body1'>
                        <FormattedMessage {...messages.projectCreatedDate} />
                    </Typography>
                    <Typography component='span' className='createdDate' variant='body1'>
                        <FormattedDate value={project.createdAt} day='numeric' month='short' year='numeric' />
                    </Typography>
                    {orgName &&
                        <Typography component='span' className='orgName' variant='body1'>
                            <FormattedMessage {...messages.organisationName} values={{ orgName }} />
                        </Typography>
                    }
                    <ProjectMode project={project} />
                </div>
            </div>
            <div className='usageContainer'>
                <ProjectStats project={project} vatRate={vatRate} userStatsResult={userStatsResult} />
            </div>
        </StyledListItem>
    );
}

ProjectListEntry.propTypes = {
    project: PropTypes.object.isRequired,
    orgName: PropTypes.string,
    userStatsResult: PropTypes.object
};

export default ProjectListEntry;