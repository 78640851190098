import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {createUseStyles} from 'react-jss';
import Modal from "@mui/material/Modal";
import {osColour} from 'omse-components';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import {FormattedMessage, defineMessages, useIntl} from 'react-intl';
import {ReactComponent as CloseIcon} from "../../../components/icons/close-large.svg";
import classNames from 'classnames';

const messages = defineMessages({
    next: {
        id: 'DownloadImageModal.next',
        defaultMessage: 'Next',
        description: 'Label for the Next button'
    },
    nextAriaLabel: {
        id: 'DownloadImageModal.nextAriaLabel',
        defaultMessage: 'View next image',
        description: 'Aria Label for the Next button'
    },
    back: {
        id: 'DownloadImageModal.back',
        defaultMessage: 'Back',
        description: 'Label for the Back button'
    },
    backAriaLabel: {
        id: 'DownloadImageModal.backAriaLabel',
        defaultMessage: 'View Previous Image',
        description: 'Aria Label for the Back button'
    },
})

const useStyles = createUseStyles({
    closeIcon: {
        color: osColour.neutral.stone
    },
    zoomedImg: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: '1px 1px 5px 2px rgba(0, 0, 0, 0.4)',
        outline: 'none',

        // This helps the UI on small screens, including mobile
        maxWidth: '100%',
        maxHeight: '100%',
    },
    zoomedClose: {
        zIndex: 5,
        background: osColour.neutral.white,
        opacity: 0.9,
        height: 64,
        width: 64,
        borderRadius: 32,
        position: 'absolute',
        right: 10,
        top: 10,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    mobileStepper: {
        display: 'flex'
    },
    carouselStyle: {
        position: 'relative',
        overflow: 'hidden',
        height: "856px", // Matches the old carousel component
        width: "1280px" // Matches image size
    },
    image: {
        position: 'absolute',
        transition: 'left 0.3s linear'
    }
});



function DownloadImageModal({images, close, productName, initialIndex}){
    const [index, setIndex] = useState(initialIndex || 0);
    const intl = useIntl();
    const classes = useStyles();
    const carouselRef = useRef();

    const handleNext = useCallback(function(index) {
        if(index === images.length - 1) {
            // No swiping right when we are already on the last image
            return index;
        }
        return index + 1;
    }, [images.length]);

    const handleBack = useCallback(function(index) {
        if(index === 0) {
            // No swiping left when we are already on the first image
            return index;
        }
        return index - 1;
    }, []);

    useEffect(function() {
        let startX;
        function start(event) {
            const carouselElement = carouselRef.current;
            if(carouselElement && carouselElement.contains(event.target)) {
                event.preventDefault();
                startX = event.clientX;
            }
        }
        function end(event) {
            const carouselElement = carouselRef.current;
            if(carouselElement && carouselElement.contains(event.target)) {
                event.preventDefault();
                const deltaX = startX - event.clientX;
                if (deltaX < 0) {
                    setIndex(handleBack);
                } else {
                    setIndex(handleNext);
                }
            }
        }

        window.addEventListener('mousedown', start);
        window.addEventListener('mouseup', end);
        return function cleanup() {
            window.removeEventListener('mousedown', start);
            window.removeEventListener('mouseup', end);
        }
    }, [carouselRef])

    let itemCount = images.length;

    const nextButton = <Button size="small"
                               onClick={() => setIndex(handleNext)}
                               disabled={index === itemCount - 1}
                               tabIndex={0}
                               aria-label={intl.formatMessage(messages.nextAriaLabel)}
    >
        <FormattedMessage {...messages.next}/>
    </Button>;
    const backButton = <Button size="small"
                               onClick={() => setIndex(handleBack)}
                               disabled={index === 0}
                               tabIndex={0}
                               aria-label={intl.formatMessage(messages.backAriaLabel)}
    >
        <FormattedMessage {...messages.back}/>
    </Button>;

    const imgArray = [];
    for(let i = 0; i < images.length; i++) {
        const url = images[i].large;
        const leftPosition = (i - index) * 1280; // 1280 is the image size
        imgArray.push(
            <img key={i}
                 src={url}
                 alt={`Example of product - full screen modal: ${productName} ${i + 1}`}
                 draggable="false"
                 className={classes.image}
                 style={{ left: leftPosition }}
            />
        );
    }

    return <Modal
        open={true}
        onClose={close}>
        <div className={classNames({[classes.zoomedImg]: true})}>
            <button className={classNames({[classes.zoomedClose]: true})}
                  onClick={close}
                  onKeyDown={event => event.key === 'Enter' && close()}
                  aria-label='Close preview modal'
                  tabIndex={0}
            >
                <CloseIcon width={24}
                           height={24}
                           className={classes.closeIcon}
                           alt=''
                           aria-hidden={true}
                />
            </button>
            <div className={classes.carouselStyle} ref={carouselRef}>
                { imgArray }
            </div>

            {images.length > 1 &&
            <MobileStepper className={classNames({[classes.mobileStepper]: true})}
                           steps={images.length}
                           variant="dots"
                           activeStep={index}
                           nextButton={nextButton}
                           backButton={backButton}
            />
            }
        </div>
    </Modal>;
}

DownloadImageModal.propTypes = {
    images: PropTypes.array.isRequired,
    initialIndex: PropTypes.number.isRequired,
    close: PropTypes.func.isRequired,
    productName: PropTypes.string.isRequired
}

export default DownloadImageModal;
