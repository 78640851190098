import { defineMessages } from "react-intl";

const teamSpacesListMessages = defineMessages({
    tableHeaderName: {
        id: "selectionResultsMessages.tableHeaderName",
        description: "Header for the Team Space name column",
        defaultMessage: "Team Space",
    },
    tableHeaderCreatedBy: {
        id: "selectionResultsMessages.tableHeaderCreatedBy",
        description: "Header for the Team Space Created By column",
        defaultMessage: "Created by",
    },
    tableHeaderDescription: {
        id: "selectionResultsMessages.tableHeaderDescription",
        description: "Header for the Team Space Description column",
        defaultMessage: "Description",
    },
    teamSpaceLockedAria: {
        id: "selectionResultsMessages.teamSpaceLockedAria",
        description: "The aria-label for the locked Team Space icon",
        defaultMessage: "Locked Team Space",
    },
    teamSpaceUnlockedAria: {
        id: "selectionResultsMessages.teamSpaceUnlockedAria",
        description: "The aria-label for the unlocked Team Space icon",
        defaultMessage: "Unlocked Team Space",
    },
    paginationHasResults: {
        id: "selectionResultsMessages.paginationHasResults",
        description: "The total number of results",
        defaultMessage: "{pageStart}-{pageEnd} of {total} result{isPlural}.",
    },
    paginationHasNoResults: {
        // Note: This is not the "Empty State" message - it
        // only affects situations when result filters are in use.
        id: "selectionResultsMessages.paginationHasNoResults",
        description: "The error message displayed when the user has no results.",
        defaultMessage: "No Results. Please refine your search filters.",
    },
    paginationNavigateFirstPageAria: {
        id: "selectionResultsMessages.paginationNavigateFirstPageAria",
        description: "The aria-label for the first-page pagination control",
        defaultMessage: "navigate to the first page",
    },
    paginationNavigatePreviousPageAria: {
        id: "selectionResultsMessages.paginationNavigatePreviousPageAria",
        description: "The aria-label for the previous-page pagination control",
        defaultMessage: "navigate to the previous page",
    },
    paginationNavigateNextPageAria: {
        id: "selectionResultsMessages.paginationNavigateNextPageAria",
        description: "The aria-label for the next-page pagination control",
        defaultMessage: "navigate to the next page",
    },
    paginationNavigateLastPageAria: {
        id: "selectionResultsMessages.paginationNavigateLastPageAria",
        description: "The aria-label for the last-page pagination control",
        defaultMessage: "navigate to the last page",
    },
});

export default teamSpacesListMessages;
