import useFeatureCheck from "./useFeatureCheck";
import Error404 from "../pages/Error404";

export default function withFeatureCheck(Component, featureFlag) {

    return function WithFeatureCheckWrapper(props) {
        const check = useFeatureCheck(featureFlag);
        if(check) {
            return <Component {...props}/>;
        }
        return <Error404/>;
    }
}
