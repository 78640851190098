import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Tab, Tabs } from "@mui/material";
import { useIntl } from "react-intl";
import styled from "@emotion/styled";
import { osColour } from "omse-components";
import { LockOpenOutlined, LockOutlined } from "@mui/icons-material";
import OwnersTab from "./OwnersTab";
import MembersTab from "./MembersTab";
import teamSpaceAccessMessages from "./TeamSpaceAccess.msg";

const StyledTabs = styled(Tabs)(
    ({ theme }) => `
    margin: 0 ${theme.spacing(4)};
    border-bottom: solid 1px ${osColour.neutral.mist};

    & button {
        display: flex;
        flex-direction: row;
        place-items: center;
        gap: 0.2em;
        font-size: 1em;
    }

    & button svg {
        height: 0.6em;
    }

    & button svg.teamLocked {
        color: ${osColour.status.error};
    }

    & button svg.teamUnlocked {
        color: ${osColour.status.success};
    }

    & button svg {
        height: 0.8em;
    }

    & .indicator {
        background: ${osColour.primary.berry};
    }
`
);

export const ownersTab = "owners";
export const membersTab = "members";

export default function TeamSpaceAccess(p) {
    const intl = useIntl();
    const [currentTab, setCurrentTab] = useState(ownersTab);

    useEffect(() => {
        p.setDisableNextAction(p.newTeamSpaceParameters.name?.trim().length === 0);
        return () => p.setDisableNextAction(false);
        // eslint-disable-next-line
    }, []);

    return (
        <Box data-testid="dialog-createteamspace-step2panel">
            <StyledTabs
                classes={{ indicator: "indicator" }}
                value={currentTab}
                onChange={(_, newValue) => setCurrentTab(newValue)}
            >
                <Tab
                    value={ownersTab}
                    label={intl.formatMessage(teamSpaceAccessMessages.ownersTab)}
                    key={ownersTab}
                />
                <Tab
                    value={membersTab}
                    label={
                        <>
                            {intl.formatMessage(teamSpaceAccessMessages.membersTab)}
                            {p.newTeamSpaceParameters.locked ? (
                                <LockOutlined
                                    className="teamLocked"
                                    aria-label={intl.formatMessage(teamSpaceAccessMessages.membersTabLockedAria)}
                                />
                                    ) : (
                                <LockOpenOutlined
                                    className="teamUnlocked"
                                    aria-label={intl.formatMessage(teamSpaceAccessMessages.membersTabUnlockedAria)}
                                />
                            )}
                        </>
                    }
                    key={membersTab}
                />
            </StyledTabs>
            {currentTab === ownersTab && (
                <OwnersTab
                    newTeamSpaceParameters={p.newTeamSpaceParameters}
                    owners={p.owners}
                    setOwners={p.setOwners}
                    selectedUserIds={p.selectedUserIds}
                />
            )}
            {currentTab === membersTab && (
                <MembersTab
                    newTeamSpaceParameters={p.newTeamSpaceParameters}
                    setNewTeamSpaceParameters={p.setNewTeamSpaceParameters}
                    members={p.members}
                    setMembers={p.setMembers}
                    selectedUserIds={p.selectedUserIds}
                />
            )}
        </Box>
    );
}

TeamSpaceAccess.propTypes = {
    newTeamSpaceParameters: PropTypes.object.isRequired,
    setNewTeamSpaceParameters: PropTypes.func.isRequired,
    setDisableNextAction: PropTypes.func.isRequired,
};
