import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import { findRoute } from "../../util/routes";
import { InternalLink, osColour } from "omse-components";
import teamSpacesListMessages from "./TeamSpacesList.msg";
import { ReactComponent as TeamSpaceIcon } from "../../components/icons/teamSpace.svg";
import { ReactComponent as TeamSpaceLockedIcon } from "../../components/icons/teamSpaceLocked.svg";
import { LastPage, FirstPage, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
    Box,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableFooter,
    Typography,
    IconButton,
} from "@mui/material";

const CustomTable = styled(Table)`
    & > thead > tr {
        border-bottom: 1px solid ${osColour.primary.berry};
    }

    & > thead > tr > th {
        color: ${osColour.primary.berry};
        font-weight: bold;
        border: none;
    }

    & > tbody > tr {
        border-bottom: 1px solid ${osColour.neutral.mist};
    }

    & > tbody > tr > td {
        font-weight: normal;
        color: ${osColour.neutral.charcoal};
        border-bottom: none;
    }

    & > tbody > tr > td.teamSpaceName {
        max-width: 20em;
        display: flex;
        align-items: center;
        gap: 1em;
    }

    & > tbody > tr > td.teamSpaceName > svg {
        flex-shrink: 0;
        width: 2.2em;
        height: 2em;
    }

    & > tbody > tr > td.teamSpaceDescription > span {
        max-width: 20em;
    }

    & > tbody > tr > td.teamSpaceDescription > span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @supports (-webkit-line-clamp: 2) {
            overflow: hidden;
            white-space: initial;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    & > tfoot > tr > td {
        border-bottom: none;
    }

    & > tfoot > tr > td > div {
        display: flex;
        flex-wrap: wrap;
        gap: 1em;
        align-items: center;
        justify-content: space-between;
    }

    & .errorMessage {
        color: ${osColour.status.error};
    }
`;

const MAX_RESULTS_PER_PAGE = 10;

function SelectionResults({ resultsFiltered }) {
    const intl = useIntl();
    const paginationDisabled = resultsFiltered.length < MAX_RESULTS_PER_PAGE;

    return (
        <TableContainer component={Box}>
            <CustomTable>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {intl.formatMessage(teamSpacesListMessages.tableHeaderName)}
                        </TableCell>
                        <TableCell>
                            {intl.formatMessage(teamSpacesListMessages.tableHeaderCreatedBy)}
                        </TableCell>
                        <TableCell>
                            {intl.formatMessage(teamSpacesListMessages.tableHeaderDescription)}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody data-testid="teamspaces-results">
                    {resultsFiltered?.map(
                        (result) =>
                            result && (
                                <TableRow key={result.id}>
                                    <TableCell className="teamSpaceName">
                                        {result.locked ? (
                                            <TeamSpaceLockedIcon
                                                aria-label={intl.formatMessage(
                                                    teamSpacesListMessages.teamSpaceLockedAria
                                                )}
                                            />
                                        ) : (
                                            <TeamSpaceIcon
                                                aria-label={intl.formatMessage(
                                                    teamSpacesListMessages.teamSpaceUnlockedAria
                                                )}
                                            />
                                        )}
                                        <InternalLink
                                            path={`${findRoute("teamSpaces").path}/${result.id}`}
                                            message={result.name}
                                        />
                                    </TableCell>
                                    <TableCell className="teamSpaceCreatedBy">
                                        {result.createdByName}
                                    </TableCell>
                                    <TableCell className="teamSpaceDescription">
                                        <span>{result.description}</span>
                                    </TableCell>
                                </TableRow>
                            )
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={3}>
                            <Box>
                                {resultsFiltered.length ? (
                                    <Typography variant="body1" role="alert">
                                        {intl.formatMessage(
                                            teamSpacesListMessages.paginationHasResults,
                                            {
                                                pageStart: 1,
                                                pageEnd:
                                                    resultsFiltered.length <= MAX_RESULTS_PER_PAGE
                                                        ? resultsFiltered.length
                                                        : MAX_RESULTS_PER_PAGE,
                                                total: resultsFiltered.length,
                                                isPlural: resultsFiltered.length === 1 ? "" : "s",
                                            }
                                        )}
                                    </Typography>
                                ) : (
                                    <Typography
                                        role="alert"
                                        variant="body1"
                                        className="errorMessage"
                                    >
                                        {intl.formatMessage(
                                            teamSpacesListMessages.paginationHasNoResults
                                        )}
                                    </Typography>
                                )}
                                <Box>
                                    <IconButton
                                        onClick={() => null}
                                        aria-label={intl.formatMessage(
                                            teamSpacesListMessages.paginationNavigateFirstPageAria
                                        )}
                                        disabled={paginationDisabled}
                                    >
                                        <FirstPage />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => null}
                                        aria-label={intl.formatMessage(
                                            teamSpacesListMessages.paginationNavigatePreviousPageAria
                                        )}
                                        disabled={paginationDisabled}
                                    >
                                        <KeyboardArrowLeft />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => null}
                                        aria-label={intl.formatMessage(
                                            teamSpacesListMessages.paginationNavigateNextPageAria
                                        )}
                                        disabled={paginationDisabled}
                                    >
                                        <KeyboardArrowRight />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => null}
                                        aria-label={intl.formatMessage(
                                            teamSpacesListMessages.paginationNavigateLastPageAria
                                        )}
                                        disabled={paginationDisabled}
                                    >
                                        <LastPage />
                                    </IconButton>
                                </Box>
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </CustomTable>
        </TableContainer>
    );
}

SelectionResults.propTypes = {
    resultsFiltered: PropTypes.arrayOf(
        PropTypes.shape({
            // Will change once DB models confirmed.
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            name: PropTypes.string,
            locked: PropTypes.bool,
            favourite: PropTypes.bool,
            createdBy: PropTypes.string,
            createdAt: PropTypes.string,
        })
    ),
};

export default SelectionResults;
