import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { Typography } from "@mui/material";
import { border1, contentPadding, osColour, theme } from "omse-components";
import BackLink from "../../components/BackLink";

const HeaderContainer = styled("header")`
    border-bottom: ${border1};

    & > div {
        padding-top: ${contentPadding.top / 2}px;
        padding-bottom: ${contentPadding.bottom}px;
        padding-left: ${contentPadding.left / 2}px;
        padding-right: ${contentPadding.right / 2}px;
        display: flex;
        gap: 1em;
        flex-wrap: wrap;
        align-items: center;
        max-width: ${contentPadding.maxWidth}px;
        ${theme.breakpoints.down("sm")} {
            flex-direction: column;
            align-items: stretch;
        }
    }

    & > div > h1 {
        color: ${osColour.primary.berry};
        flex-grow: 1;
    }
`;

function Header(p) {
    const { children, title, backPath, backLabel } = p;
    return (
        <HeaderContainer>
            {backPath && backLabel && <BackLink path={backPath} label={backLabel} />}
            <div>
                <Typography variant="h1">
                    <FormattedMessage {...title} />
                </Typography>
                {children}
            </div>
        </HeaderContainer>
    );
}

Header.propTypes = {
    title: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string,
        description: PropTypes.string,
    }),
    children: PropTypes.node,
};

export default Header;
