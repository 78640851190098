// Tracks when getImg is used and runs a callback when all images have loaded/errored.

export const useImageLoadCallback = (onContentLoaded, loggedIn) => {
    let totalImages = 0;
    let imagesLoaded = 0;
    const onImagesLoaded = () => {
        imagesLoaded += 1;
        if (imagesLoaded === totalImages) {
            onContentLoaded();
        }
    };
    const getImg = (src, alt, style, loggedOutOnlyImg) => {
        if (!loggedIn || (!!loggedIn && !loggedOutOnlyImg)) {
            totalImages += 1;
        }
        return <img src={src} alt={alt} onLoad={onImagesLoaded} onError={onImagesLoaded} style={style} />
    }
    return {
        getImg
    }
}