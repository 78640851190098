import React from 'react';
import withStyles from 'react-jss';
import Typography from '@mui/material/Typography';
import Header from './Header';
import Content from './Content';
import Authentication from './Authentication';
import ResponseCodes from './ResponseCodes';
import ParametersTable from './ParametersTable';
import DocLinks from "./DocLinks";
import styles from './style';
import routes from '../../util/routes';
import Document from './Document';
import MatchStatusCodes from './match/MatchStatusCodes';
import PlacesDatasetExplained from './places/PlacesDatasetExplained';
import MatchSearchTips from './match/MatchSearchTips';
import { InternalLink } from 'omse-components';
import useFeatureCheck from "../../util/useFeatureCheck";
import features from "../../../shared/features"
import TextBox from '../../components/TextBox';
import InlineCodeSnippet from '../../components/InlineCodeSnippet';

const fqAcceptedValues = <><InlineCodeSnippet>CLASSIFICATION_CODE:[code]</InlineCodeSnippet><br /><InlineCodeSnippet>LOGICAL_STATUS_CODE:[code]</InlineCodeSnippet><br /><InlineCodeSnippet>COUNTRY_CODE:[code]</InlineCodeSnippet><br /><InlineCodeSnippet>LOCAL_CUSTODIAN_CODE:[code]</InlineCodeSnippet><br /><InlineCodeSnippet>LPI_LOGICAL_STATUS_CODE:[code]</InlineCodeSnippet></>
const fqDescription = <>A filter that allows filtering of results by classification code, logical status code, country code and local custodian code. Filtering for classification code will accept wildcard ( <InlineCodeSnippet>*</InlineCodeSnippet> ) searches. Multiple searches can be done by separating the codes with a space. Multiple filters can be used together by including the parameter multiple times: <InlineCodeSnippet>fq=CLASSIFICATION_CODE:CI CLASSIFICATION_CODE:RD&fq=LOGICAL_STATUS_CODE:6</InlineCodeSnippet></>

const fqParams = [<InlineCodeSnippet>fq</InlineCodeSnippet>, 'optional', 'string', fqAcceptedValues, fqDescription]

const keyParam = [<InlineCodeSnippet>key</InlineCodeSnippet>, 'required', 'string', 'Free text', 'The API Key provided by the OS Data Hub.']
const queryParam = [<InlineCodeSnippet>query</InlineCodeSnippet>, 'required', 'string', 'Free text', 'The free text search parameter.']
const formatParam = [<InlineCodeSnippet>format</InlineCodeSnippet>, 'optional', 'string', <><InlineCodeSnippet>JSON</InlineCodeSnippet><br /><InlineCodeSnippet>XML</InlineCodeSnippet></>, <>The format in which the response will be returned. Default: <InlineCodeSnippet>JSON</InlineCodeSnippet>.</>]
const maxresultsParam = [<InlineCodeSnippet>maxresults</InlineCodeSnippet>, 'optional', 'integer', <><InlineCodeSnippet>1</InlineCodeSnippet> - <InlineCodeSnippet>100</InlineCodeSnippet></>, <>The maximum number of results to return. Default: <InlineCodeSnippet>100</InlineCodeSnippet>.</>]
const offsetParam = [<InlineCodeSnippet>offset</InlineCodeSnippet>, 'optional', 'integer', 'An integer', 'Offset the list of returned results by this amount.']
const datasetParam = [<InlineCodeSnippet>dataset</InlineCodeSnippet>, 'optional', 'string', <><InlineCodeSnippet>DPA</InlineCodeSnippet><br /><InlineCodeSnippet>LPI</InlineCodeSnippet></>, <>The dataset to return. Multiple values can be sent, separated by a comma. Default: <InlineCodeSnippet>DPA</InlineCodeSnippet>.</>]
const lrParam = [<InlineCodeSnippet>lr</InlineCodeSnippet>, 'optional', 'string', <><InlineCodeSnippet>EN</InlineCodeSnippet><br /><InlineCodeSnippet>CY</InlineCodeSnippet></>, <>Which language of addresses to return. Valid languages are English and Welsh. Default value is both languages. Default: <InlineCodeSnippet>EN</InlineCodeSnippet>, <InlineCodeSnippet>CY</InlineCodeSnippet>.</>]
const minmatchParam = [<InlineCodeSnippet>minmatch</InlineCodeSnippet>, 'optional', 'double', <><InlineCodeSnippet>0.1</InlineCodeSnippet> - <InlineCodeSnippet>1.0</InlineCodeSnippet> (inclusive)</>, 'The minimum match score a result has to have to be returned.']
const matchprecisionParam = [<InlineCodeSnippet>matchprecision</InlineCodeSnippet>, 'optional', 'integer', <><InlineCodeSnippet>1</InlineCodeSnippet> - <InlineCodeSnippet>10</InlineCodeSnippet></>, 'The decimal point position at which the match score value is to be truncated.']
const outputSrsParam = [<InlineCodeSnippet>output_srs</InlineCodeSnippet>, 'optional', 'string', <><InlineCodeSnippet>BNG</InlineCodeSnippet><br /><InlineCodeSnippet>EPSG:27700</InlineCodeSnippet><br /><InlineCodeSnippet>WGS84</InlineCodeSnippet><br /><InlineCodeSnippet>EPSG:4326</InlineCodeSnippet><br /><InlineCodeSnippet>EPSG:3857</InlineCodeSnippet><br /><InlineCodeSnippet>EPSG:4258</InlineCodeSnippet></>, <>The intended output spatial reference system. Default: <InlineCodeSnippet>EPSG:27700</InlineCodeSnippet>.</>]
const postcodeParam = [<InlineCodeSnippet>postcode</InlineCodeSnippet>, 'required', 'string', 'A UK style postcode', 'The postcode search parameter.']
const uprnParam = [<InlineCodeSnippet>uprn</InlineCodeSnippet>, 'required', 'integer', 'Integer in format of a UPRN', 'A Valid UPRN.']
const srsParam = [<InlineCodeSnippet>srs</InlineCodeSnippet>, 'optional', 'string', <><InlineCodeSnippet>BNG</InlineCodeSnippet><br /><InlineCodeSnippet>EPSG:27700</InlineCodeSnippet><br /><InlineCodeSnippet>WGS84</InlineCodeSnippet><br /><InlineCodeSnippet>EPSG:4326</InlineCodeSnippet><br /><InlineCodeSnippet>EPSG:3857</InlineCodeSnippet><br /><InlineCodeSnippet>EPSG:4258</InlineCodeSnippet></>, 'The spatial reference system for the input coordinate set.']
const pointParam = [<InlineCodeSnippet>point</InlineCodeSnippet>, 'required', 'string', 'A set of comma-separated coordinates', 'One comma-separated coordinate set that specifies the coordinate to which the nearest record in a straight line should be found. The maximum distance the search will try is 1,000 metres. The precision of the coordinates is to two decimal places (that is, 1 cm accuracy).']
const radiusParam = [<InlineCodeSnippet>radius</InlineCodeSnippet>, 'optional', 'double', <><InlineCodeSnippet>0.01</InlineCodeSnippet> - <InlineCodeSnippet>1000</InlineCodeSnippet></>, <>The radius in metres to search within. Maximum is 1,000 metres. If the radius exceeds that amount then an error message will be returned (see below). The precision of the distance is to two decimal places (that is, 1 cm accuracy). Default: <InlineCodeSnippet>100</InlineCodeSnippet>.</>]
const bboxParam = [<InlineCodeSnippet>bbox</InlineCodeSnippet>, 'required', 'string', 'A pair of comma-separated coordinates', 'A pair of comma-separated coordinates with an accuracy of two decimal places or less, that specify the lower left and upper right coordinates of the bounding box. The maximum size of the bounding box is 1 km2. If the bounding box exceeds this, an error message is returned. The precision of the coordinates is to an accuracy of 8 metres.']
const findBboxParam = [<InlineCodeSnippet>bbox</InlineCodeSnippet>, 'optional', 'string', 'A pair of comma-separated coordinates', <>Limits results to only those contained within the supplied British National Grid bounding box. The bounding box must be specified with four comma-separated numbers, indicating the lower-left and upper-right coordinates in the format <InlineCodeSnippet>bbox=left,bottom,right,top</InlineCodeSnippet>. The bounding box must be entirely within the British National Grid.</>]
const referencepointParam = [<InlineCodeSnippet>referencepoint</InlineCodeSnippet>, 'optional', 'integer', 'A reference point integer', 'Reference point geometry is the basis from which the distance value is calculated. Output is ordered by ascending distance from given reference point only if parameter has been specified.']

const placesFindQueryParams = (findSupportsBbox) => [
    keyParam,
    queryParam,
    formatParam,
    maxresultsParam,
    offsetParam,
    datasetParam,
    findSupportsBbox ? findBboxParam : undefined,
    lrParam,
    minmatchParam,
    matchprecisionParam,
    fqParams,
    outputSrsParam
].filter(p => p);
const placesPostcodeQueryParams = () => [
    keyParam,
    postcodeParam,
    formatParam,
    maxresultsParam,
    offsetParam,
    datasetParam,
    lrParam,
    fqParams,
    outputSrsParam
];
const placesUprnQueryParams = () => [
    keyParam,
    uprnParam,
    formatParam,
    datasetParam,
    lrParam,
    fqParams,
    outputSrsParam
];
const placesNearestQueryParams = () => [
    keyParam,
    pointParam,
    radiusParam,
    formatParam,
    datasetParam,
    lrParam,
    fqParams,
    outputSrsParam,
    srsParam,
];
const placesBoundingQueryBox = () => [
    keyParam,
    bboxParam,
    formatParam,
    maxresultsParam,
    offsetParam,
    datasetParam,
    lrParam,
    fqParams,
    outputSrsParam,
    srsParam
];
const placesRadiusQueryParams = () => [
    keyParam,
    pointParam,
    radiusParam,
    formatParam,
    maxresultsParam,
    offsetParam,
    datasetParam,
    lrParam,
    fqParams,
    outputSrsParam,
    srsParam,
]
const placesPolygonQueryParams = () => [
    keyParam,
    referencepointParam,
    maxresultsParam,
    offsetParam,
    datasetParam,
    lrParam,
    fqParams,
    outputSrsParam,
    srsParam,
]
const placesPolygonBodyParams = [
    [<InlineCodeSnippet>type</InlineCodeSnippet>, 'required', 'object', 'A feature', 'The first name:value pair for the GeoJSON polygon.'],
    [<InlineCodeSnippet>geometry</InlineCodeSnippet>, 'required', 'object', 'A polygon object', 'Here is where the polygon object is placed.']
]
const placesPolygonHeaderParams = [
    [<InlineCodeSnippet>Content-Type</InlineCodeSnippet>, 'required', 'string', <InlineCodeSnippet>application/json</InlineCodeSnippet>, 'The Content-Type header']
]

function PlacesDetail(props) {
    const findSupportsBbox = useFeatureCheck(features.PLACES_FIND_SUPPORTS_BBOX)
    return <Document>
        <Header back={true}>OS Places API: Technical specification</Header>
        <Content>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>This guide explains the content of the OS Places API, and the methods that you can use to call it.
                    If you are not familiar with the OS Places API you may want to read the <InternalLink
                        path={routes.placesOverview} message="overview documentation" /> before proceeding.
                </p>

                <p>The OS Places API provides a detailed view of an address and its life cycle. It contains all the
                    records of AddressBase&reg; Premium and AddressBase&reg; Premium – Islands and so provides all the
                    information relating to
                    an address or property from creation to retirement.
                </p>
                <p>
                    It contains local authority, Ordnance Survey and Royal Mail&reg; addresses, current addresses,
                    and alternatives for current addresses, provisional addresses (such as planning developments) and
                    historic information, plus OWPAs and cross references to the OS MasterMap&reg; TOIDS&reg;.
                </p>
                <p>
                    OS Places API contains addresses located within the United Kingdom, Jersey, Guernsey and the Isle of
                    Man. For address records
                    in Jersey and Guernsey the coordinates will be <InlineCodeSnippet>0.0</InlineCodeSnippet> as they fall outside of the British National
                    Grid. This means they are not
                    compatible with the GeoSearch operations.
                </p>
            </Typography>
            <Typography variant='h1' component={'h2'}>Technical Detail</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    OS Places API is an API, returning queries in either XML or JSON.
                    The GeoSearch resources require coordinates in either EPSG:27700, EPSG:4326, EPSG:3857 or EPSG:4258.
                    The find resource enables rapid searches of AddressBase® Premium to drill down and isolate ambiguous
                    addressing details.
                    Postcode searches need the area and district integers as a minimum. e.g. Using our head office
                    postcode as an example – SO16 0AS:
                </p>
                    <ul>
                        <li>
                            <b>SO</b> represents the area
                        </li>
                        <li>
                            <b>SO16</b> is the district
                        </li>
                        <li>
                            <b>SO16 0</b> is the sector
                        </li>
                        <li>
                            <b>SO16 0AS</b> is the unit
                        </li>
                    </ul>
            </Typography>
            <Authentication gettingStartedRoute={routes.placesIntro}/>
            <Typography variant='h2'>Encryption</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    As of October 2018, all calls must be made using a secure hypertext encryption (HTTPS).
                </p>
                <p>
                    Ordnance Survey APIs no longer support calls made using SSL v2/v3 or TLS v1.0/v1.1.
                    Thus, all calls made to any of our APIs must support TLS v1.2 as of 24th October 2018 in line with
                    current cyber security recommendations.
                </p>
            </Typography>
            <ResponseCodes/>
            <Typography variant='h2'>Operation</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p> OS Places API has seven types of request, that fall into two categories:</p>
                <ul>
                    <li>
                        Capture and Verification
                        <ul>
                            <li><b>Find</b> A free text search designed for quick use.</li>
                            <li><b>Postcode</b> A search based on a property’s postcode.</li>
                            <li><b>UPRN</b> A search that takes a UPRN as the search parameter.</li>
                        </ul>
                    </li>
                    <li>
                        GeoSearch
                        <ul>
                            <li><b>Nearest</b> Can find the closest address to a given point.</li>
                            <li><b>Bounding box</b> Can find all addresses inside a bounding box.</li>
                            <li><b>Radius</b> Can find all addresses that intersect a given circle.</li>
                            <li><b>Polygon</b> Can find all addresses in a polygon or multi-polygon object.</li>
                        </ul>
                    </li>
                </ul>
            </Typography>
            <Typography variant='h3'>Find</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    A free text search designed for quick use e.g. form filling.
                </p>
                <TextBox
                    text="https://api.os.uk/search/places/v1/find?query=Ordnance%20Survey%2C%20Adanac%20Drive%2C%20SO16&key="
                    label="OS Places API - 'Find' Endpoint"
                    variant="code"
                />
                <ParametersTable values={placesFindQueryParams(findSupportsBbox)}/>
            </Typography>
            <Typography variant='h3'>Postcode</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    A search based on a property’s postcode. The minimum for the resource is the area and district e.g.
                    SO16, and will accept
                    a full postcode consisting of the area, district, sector and unit e.g. SO16 0AS .
                </p>
                <TextBox
                    text="https://api.os.uk/search/places/v1/postcode?postcode=SO16&key="
                    label="OS Places API - 'Postcode' Endpoint"
                    variant="code"
                />
                <ParametersTable values={placesPostcodeQueryParams()}/>
            </Typography>
            <Typography variant='h3'>UPRN</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    A search that takes a UPRN as the search parameter.
                </p>
                <TextBox
                    text="https://api.os.uk/search/places/v1/uprn?uprn=200010019924&key="
                    label="OS Places API - 'UPRN' Endpoint"
                    variant="code"
                />
                <ParametersTable values={placesUprnQueryParams()}/>
            </Typography>
            <Typography variant='h3'>Nearest</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    Takes a pair of coordinates (X and Y) as an input to determine the closest address.
                </p>
                <TextBox
                    text="https://api.os.uk/search/places/v1/nearest?point=437293,115515&key="
                    label="OS Places API - 'Nearest' Endpoint"
                    variant="code"
                />
                <ParametersTable values={placesNearestQueryParams()}/>
            </Typography>
            <Typography variant='h3'>BBox</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    Takes two points and creates a bounding box. All addresses within this bounding box are then
                    returned.
                </p>
                <TextBox
                    text="https://api.os.uk/search/places/v1/bbox?bbox=437201,115447,437385,115640&key="
                    label="OS Places API - 'BBox' Endpoint"
                    variant="code"
                />
                <ParametersTable values={placesBoundingQueryBox()}/>
            </Typography>
            <Typography variant='h3'>Radius</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    Takes a pair of coordinates as the centre for a circle and returns all addresses that are
                    intersected by it.
                </p>
                <TextBox
                    text="https://api.os.uk/search/places/v1/radius?point=437293,115515&radius=100&key="
                    label="OS Places API - 'Radius' Endpoint"
                    variant="code"
                />
                <ParametersTable values={placesRadiusQueryParams()}/>
            </Typography>
            <Typography variant='h3'>Polygon</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    A POST Request that takes a geoJSON polygon or multi-polygon object and returns all addresses that
                    are in the object.
                </p>
                <TextBox
                    text="https://api.os.uk/search/places/v1/polygon?key="
                    label="OS Places API - 'Polygon' Endpoint"
                    variant="code"
                />
            </Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    Polygon POST request query parameters
                </p>
                <ParametersTable values={placesPolygonQueryParams()}/>
            </Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    Polygon POST request body parameters
                </p>
                <ParametersTable values={placesPolygonBodyParams}/>
            </Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    Polygon POST request header parameters
                </p>
                <ParametersTable values={placesPolygonHeaderParams}/>
            </Typography>
            <MatchStatusCodes/>
            <PlacesDatasetExplained/>
            <MatchSearchTips searchType='search' specificSearchType='find'/>
            <DocLinks product='places' hasGettingStarted={true} hasDemo={true}/>
        </Content>
    </Document>
}

export default withStyles(styles)(PlacesDetail);