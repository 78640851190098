import { defineMessages } from "react-intl";

const teamSpaceMessages = defineMessages({
    title: {
        id: "teamSpaceMessages.title",
        defaultMessage: "Team Space",
        description: "Team Space > Title",
    },
    backToTeamSpacesPage: {
        id: "teamSpaceMessages.backToTeamSpacesPage",
        defaultMessage: "Team Spaces",
        description: "Team Space > Back to Team Spaces page",
    },
    errorGeneric: {
        id: "teamSpaceMessages.errorGeneric",
        description: "Team Space > Error > Generic",
        defaultMessage: "There was a problem accessing the Team Spaces service.",
    },
    errorContent: {
        id: "teamSpaceMessages.errorContent",
        description: "Team Space > Error > Content",
        defaultMessage: "Please try again, and if the problem persists then {link}.",
    },
});

export default teamSpaceMessages;
