import React from 'react';
import { VernacularNames } from "omse-components";
import FeatureCheck from "../components/FeatureCheck";
import { useSelector } from 'react-redux';

export function VernacularNamesReporting(props) {
    const {mapTesting} = useSelector(state => state.config.current.result.mapTesting);

    if (mapTesting) {
        //This is set in omse-components/src/omse-ol/Map/Map.js
        console.log('Storing map object into the vnMap global variable');
    }

    return (
        <FeatureCheck feature="vn">
            <VernacularNames {...props}/>
        </FeatureCheck>
    );
}

export default VernacularNamesReporting;
