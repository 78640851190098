import React, {Fragment} from 'react';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage} from 'react-intl';
import Typography from '@mui/material/Typography';
import { ExternalLink } from 'omse-components';

const messages = defineMessages({
    downloadTitle: {
        id:'SampleDataDownloadsItem.downloadTitle',
        defaultMessage: 'Download {product}',
        description: 'label for download title'
    },
    downloadSubTitle: {
        id:'SampleDataDownloadsItem.downloadSubTitle',
        defaultMessage: 'All SampleData can be freely downloaded under the <a>Open Government Licence</a>. Please acknowledge reproduced materials.',
        description: 'sub label for download title'
    }
});

const styles = theme => ({
    downloadFormHeading: {
        marginTop: theme.spacing(3),
        textAlign: 'left',
        maxWidth: 650
    },
    downloadTitle: {
        marginBottom: theme.spacing(1.5)
    },
    downloadSubTitle: {
        marginBottom: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(4.5)
        }
    }
});

export function SampleDataHeading(props) {
    let {classes, title} = props;
    return (<div className={classes.downloadFormHeading}>
            <Fragment>
                <Typography variant='h2' className={classes.downloadTitle} color='textPrimary'>
                    <FormattedMessage {...messages.downloadTitle} values={{product: title}} />
                </Typography>
                <Typography variant='h5' component='h3' className={classes.downloadSubTitle} color='textPrimary' data-testid="subtitle">
                    <FormattedMessage {...messages.downloadSubTitle}  values={{
                        a: chunks => (
                            // ToDo: change this for the correct sample data license docs
                            <ExternalLink
                                type="generic"
                                href="http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
                                message={chunks}
                            />
                        )
                    }}/>
                </Typography>
            </Fragment>
    </div>);
}

SampleDataHeading.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired
};

export default withStyles(styles)(SampleDataHeading);
