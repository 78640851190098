import React, {Fragment} from 'react';
import Typography from '@mui/material/Typography';
import {createUseStyles} from 'react-jss';
import {osColour} from 'omse-components';
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage} from 'react-intl';

// Todo: modify this class for sample data requirements
const messages = defineMessages({
    coverage: {
        id: 'SampleDataDesc.actions',
        defaultMessage: 'Coverage:',
        description: 'Label coverage'
    },
    allOfGb: {
        id: 'SampleDataDesc.allOfGb',
        defaultMessage: 'All of Great Britain',
        description: 'Label all of GB'
    },
    allOfGbAndNi: {
        id: 'SampleDataDesc.allOfGbAndNi',
        defaultMessage: 'All of Great Britain and Northern Ireland',
        description: 'Label all of GB and NI'
    },
    allOfUk: {
        id: 'SampleDataDesc.allOfUk',
        defaultMessage: 'All of the United Kingdom',
        description: 'Label all of UK'
    },
    customAreaOption: {
        id: 'SampleDataDesc.customAreaOption',
        defaultMessage: ' (Option to set a custom area)',
        description: 'Label custom area option'
    },
    setCustomArea: {
        id: 'SampleDataDesc.setCustomArea',
        defaultMessage: 'Set a custom area',
        description: 'Set custom area'
    },
    dataStructure: {
        id: 'SampleDataDesc.dataStructure',
        defaultMessage: 'Data structure:',
        description: 'Label data structure'
    },
    supplyFormat: {
        id: 'SampleDataDesc.supplyFormat',
        defaultMessage: 'Supply format:',
        description: 'Label supply format'
    },
    versionDate: {
        id: 'SampleDataDesc.versionDate',
        defaultMessage: 'Version Date:',
        description: 'Label version date'
    }
});

const styles = createUseStyles(theme => ({
    downloadMetaData: {
        marginTop: 10
    },
    metaDataLabel: {
        color: osColour.neutral.stone,
        marginRight: 4
    },
    description: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1.5)
    }
}));

function displayFormats(formats) {
    if(formats.length > 2) {
        formats = formats.slice(0, -1).join(', ') + ', and ' + formats[formats.length-1];
    } else {
        formats = formats.join(', and ');
    }
    return formats;
}

export function textFormattedProductVersion(version) {
    if (version?.match(/^[0-9]{4}-[0-9]{2}$/)) {
        const intlFormatter = new Intl.DateTimeFormat(undefined, {year: "numeric", month: "long"});
        return intlFormatter.format(new Date(version));
    }
    return version;
}

export default function SampleDataDesc({item, summary = true}) {
    const classes = styles();

    let gbEnabled = false;
    let niEnabled = false;
    let ukEnabled = false;
    if (item?.areas && item.areas.length > 0) {
        gbEnabled = item.areas.indexOf('GB') !== -1;
        niEnabled = item.areas.indexOf('NI') !== -1;
        ukEnabled = item.areas.indexOf('UK') !== -1;
    }

    let description = [];
    if (summary) {
        description.push(
            <Typography variant='h5' component={'div'} color='textPrimary'>
                {item.description[0]}
            </Typography>
        );

    } else {
        item.description.forEach((value, index) => {
            description.push(
                <Typography key={`description-part-${index}`} variant='h5' component={'div'} color='textPrimary'>
                    {value}
                </Typography>
            )
        })
    }

    return (<Fragment>
        <div className={classes.description}>
            {description}
        </div>
        <div className={classes.downloadMetaData}>
            <Typography variant='body1'>
                <span className={classes.metaDataLabel}>
                    <FormattedMessage {...messages.coverage} />
                </span>
                    {gbEnabled && !niEnabled &&
                        <FormattedMessage {...messages.allOfGb} />
                    }
                    {gbEnabled && niEnabled &&
                        <FormattedMessage {...messages.allOfGbAndNi} />
                    }
                    {ukEnabled &&
                        <FormattedMessage {...messages.allOfUk} />
                    }
                    {item.customAreaEnabled &&
                        <Fragment>
                            {gbEnabled?
                                <FormattedMessage {...messages.customAreaOption} />
                            :
                                <FormattedMessage {...messages.setCustomArea} />
                            }
                        </Fragment>
                    }
            </Typography>
            <Typography variant='body1'>
                <span className={classes.metaDataLabel}>
                    <FormattedMessage {...messages.dataStructure} />
                </span>
                {item.dataStructures && item.dataStructures.join(', ')}
            </Typography>
            <Typography variant='body1'>
                <span className={classes.metaDataLabel}>
                    <FormattedMessage {...messages.supplyFormat} />
                </span>
                {displayFormats(item.supplyFormats)}
            </Typography>
            <Typography variant='body1'>
                <span className={classes.metaDataLabel}>
                    <FormattedMessage {...messages.versionDate} />
                </span>
                {textFormattedProductVersion(item.version)}
                {item.versionFrequency ? ` (${item.versionFrequency})` : ''}
                {item.warning &&
                    <Typography variant='body2' component='span'>
                        &nbsp;{item.warning}
                    </Typography>
                }
            </Typography>
        </div>
    </Fragment>);
}

SampleDataDesc.propTypes = {
    item: PropTypes.object.isRequired,
    summary: PropTypes.bool
};
