import React, { useEffect, useRef, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import { defineMessages, FormattedMessage } from 'react-intl';
import {
    AddButton,
    border1,
    contentPadding,
    InputBox,
    modules,
    Notification,
    osColour,
    ExternalLink
} from "omse-components";
import ContentHeader from '../components/ContentHeader';
import userConstants, { emailMatcher } from "../constants/user";
import { hasImpersonateUserPermission, hasManageContactDetailsPermission } from "../util/permissions";
import check from "../util/featureCheck";
import features from '../../shared/features';
import { setEmail } from '../modules/account/actions';
import queryString from 'query-string';

const { updateUser, refreshUser } = modules.actions.user;

const messages = defineMessages({
    title: {
        id: 'Account.title',
        defaultMessage: 'Account settings',
        description: 'Label for the Account settings header'
    },
    yourProfile: {
        id: 'Account.yourProfile',
        defaultMessage: 'Your profile',
        description: 'Label for the Your profile header'
    },
    password: {
        id: 'Account.password',
        defaultMessage: 'Password',
        description: 'Label for the Password header'
    },
    firstNameLabel: {
        id: 'Account.firstName',
        defaultMessage: 'First name',
        description: 'Label for the First name field'
    },
    lastNameLabel: {
        id: 'Account.lastName',
        defaultMessage: 'Surname',
        description: 'Label for the last name field'
    },
    jobTitleLabel: {
        id: 'Account.jobTitle',
        defaultMessage: 'Job title',
        description: 'Label for the job title field'
    },
    telephoneNumberLabel: {
        id: 'Account.telephoneNumber',
        defaultMessage: 'Telephone number',
        description: 'Label for the tel no field'
    },
    privacyPolicy: {
        id: 'Account.privacyPolicy',
        defaultMessage: 'All the information you provide will be used in accordance with our {privacyPolicyLink}.',
        description: 'Text for the privacy policy sentence'
    },
    privacyPolicyLink: {
        id: 'Account.privacyPolicyLink',
        defaultMessage: 'privacy policy',
        description: 'Text for the privacy policy link'
    },
    saveChanges: {
        id: 'Account.saveChanges',
        defaultMessage: 'Save changes',
        description: 'Label for the Save changes button'
    },
    changePassword: {
        id: 'Account.changePassword',
        defaultMessage: 'Change password',
        description: 'Label for the Change password button'
    },
    savingChanges: {
        id: 'Account.savingChanges',
        defaultMessage: 'Saving changes',
        description: 'Label for button when saving changes'
    },
    saved: {
        id: 'Account.saved',
        defaultMessage: 'Your changes have been saved.',
        description: 'Label for the saved changes notification'
    },
    fieldValidation: {
        id: 'Account.fieldValidation',
        defaultMessage: 'Please enter a {field}.',
        description: 'field validation message'
    },
    email: {
      id: 'Account.email',
      defaultMessage: 'Email',
      description: 'Label for the Registered Email header'
    },
    registeredEmailLabel: {
        id: 'Account.registeredEmail',
        defaultMessage: 'Your registered email address',
        description: 'Label for the Registered Email Address field'
    },
    changeEmail: {
        id: 'Account.changeEmail',
        defaultMessage: 'Change email',
        description: 'Label for the Change email button'
    },
    emailMustDifferValidationError: {
        id: 'emailMustDifferValidationError',
        defaultMessage: 'The email must differ from the current setting',
        description: 'Message shown when Change Email is attempted for the current address'
    },
    emailSaveError: {
        id: 'emailSaveError',
        defaultMessage: 'The email address you entered may already be in use. Please {link} for assistance.',
        description: 'Message shown when email update fails'
    },
    support: {
        id:'Account.support', 
        defaultMessage: 'Support', 
        description: 'Support heading text'
    },
    logIn: {
        id:'Account.logIn',
        defaultMessage: 'Log in', 
        description: 'Support button label'
    },
    supportTargetEmail: {
        id:'Account.supportTargetEmail',
        defaultMessage: 'Log-in as user',
        description: 'Prompt for support log-in'
    },
    supportRole: {
        id:'Account.supportRole',
        defaultMessage: 'Support role not authorised',
        description: 'Error for support role'
    },
    userNotFound: {
        id:'Account.userNotFound',
        defaultMessage: 'User not found',
        description: 'User not found label'
    },
    emailRequired: {
        id:'Account.emailRequired',
        defaultMessage: 'Email required', 
        description: 'Requires email label'
    },
    enterEmailAddress: {
        id:'Account.enterEmailAddress',
        defaultMessage: 'Enter email address',
        description: 'Support log-in placeholder text'
    },
    saveError: {
        id: 'Account.saveError',
        defaultMessage: 'Failed to update user details',
        description: 'Save error'
    }
});

const useStyles = createUseStyles((theme) => ({
    subtitle: {
        marginBottom: theme.spacing(2),
    },
    content: {
        flex: '1 1 auto',
        paddingLeft: contentPadding.left,
        paddingRight: contentPadding.right,
        paddingBottom: contentPadding.bottom,
        maxWidth: contentPadding.maxWidth,
        marginTop: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            padding: contentPadding.mobile,
            margin: 0
        }
    },
    actionButton: {
        marginTop: theme.spacing(2),
        display: 'block'
    },
    profile: {
        margin: 0,
        border: "none",
        padding: `0px 0px ${theme.spacing(3)} 0px`
    },
    padding: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        borderTop: border1
    },
    fieldError: {
        color: osColour.status.error,
        marginTop: theme.spacing(0.5)
    },
    fieldset: {
        margin: 0,
        padding: 0,
        border: "none"
    }
}));

export const Account = (props) => {
    const theme = useTheme();
    const classes = useStyles({ theme });
    const dispatch = useDispatch();
    const location = useLocation();

    const userDetails = useSelector(state => state.user.current.result);
    const update = useSelector(state => state.user.update);
    const currentConfig = useSelector(state => state.config.current);
    const accountEmail = useSelector(state => state.account.email);

    const { firstName = '', lastName = '', jobTitle = '', telephoneNumber = '', permissions, email } = userDetails;

    const [errors, setErrors] = useState({});
    const [notification, setNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [emailProcessing, setEmailProcessing] = useState(false);
    const [account, setAccount] = useState({ firstName, lastName, jobTitle, telephoneNumber, permissions, email });
    const [saveError, setSaveError] = useState(null);

    const config = currentConfig && currentConfig.result;

    const changePasswordRef = useRef();
    const supportLogInRef = useRef();

    useEffect(() => {
        if (location) {
            const parsed = queryString.parse(location.search);
            const hasError = Object.keys(parsed).indexOf('error') !== -1;
            if (hasError) {
                switch(parsed.error) {
                    case 'changeEmail':
                        setErrors({email: messages.emailSaveError});
                        if (changePasswordRef.current && typeof changePasswordRef.current.scrollIntoView === 'function') {
                            changePasswordRef.current.scrollIntoView();
                        }
                        break;
                    case 'supportUser':
                        setErrors({supportTargetEmail: messages.userNotFound});
                        break;
                    case 'supportEmail':
                        setErrors({supportTargetEmail: messages.emailRequired});
                        break;
                    case 'supportRole':
                        setSaveError(messages.supportRole);
                        break;
                    default:
                        break;
                }
                if (parsed.targetEmail) {
                    setAccount({supportTargetEmail: parsed.targetEmail});
                }
                if ((parsed.error === 'supportUser' || parsed.error === 'supportEmail')
                    && supportLogInRef.current && typeof supportLogInRef.current.scrollIntoView === 'function') {
                        supportLogInRef.current.scrollIntoView();
                }
            }
        }
        dispatch(refreshUser());
    }, [location, dispatch]);

    useEffect(() => {
        if(!update.working) {
            if (update.result) {
                setNotification(true);
                setNotificationMessage(messages.saved);
            }
        }
    }, [update]);
    useEffect(() => {
        if (!update.working) {
            if (update.error) {
                let res = {};
                try {
                    res = JSON.parse(update.error.httpResponse);
                } catch (e) {
                    setSaveError(messages.saveError)
                }
                if (res.required) {
                    setErrors(prevErrors => {
                        const newErrors = { ...prevErrors };
                        res.required.forEach(required => {
                            newErrors[required] = messages.fieldValidation;
                        });
                        return newErrors;
                    });
                }
                if (res.error) {
                    setSaveError({defaultMessage: res.error, id: 'Account.saveError'});
                }
            }
        }
    }, [update]);

    useEffect(() => {
        // This will trigger after the change email pre-process request finishes
        if (!accountEmail.loading && emailProcessing) {
            if (accountEmail.error) {
                setErrors(currenErrors => ({...currenErrors, email: messages.emailSaveError}));
                setEmailProcessing(false);
            } else if (accountEmail.result && accountEmail.result.new_email && accountEmail.result.cer_id) {
                window.location.assign(`/api/auth/changeEmail?redirect_url=/account&new_email=${accountEmail.result.new_email}&cer_id=${accountEmail.result.cer_id}`);
            }
        }
    }, [accountEmail, emailProcessing]);
    
    const validateFirstName = (firstName) => {
        if (firstName == null || firstName.trim() === '') {
            return messages.fieldValidation;
        }
        if (firstName.length > 25) {
            return userConstants.messages.firstNameLengthError;
        }
        return null;
    };

    const validateLastName = (lastName) => {
        if (lastName == null || lastName.trim() === '') {
            return messages.fieldValidation;
        }
        if (lastName.length > 50) {
            return userConstants.messages.lastNameLengthError;
        }
        return null;
    };

    const validatePhone = (phone) => {
        let phoneError = null;
        if(phone != null && phone !== '') {
            if(phone.length > 30) {
                phoneError = userConstants.messages.phoneLengthError;
            } else if(!userConstants.phoneMatcher.test(phone)) {
                phoneError = userConstants.messages.phoneValidationError;
            }
        }
        return phoneError;
    };

    const validateJobTitle = (title) => {
        let jobTitleError = null;
        if(title != null && title !== '') {
            if(title.length > 100) {
                jobTitleError = userConstants.messages.jobTitleLengthError;
            } else if(!userConstants.nameMatcher.test(title)) {
                jobTitleError = userConstants.messages.jobTitleValidationError
            }
        }
        return jobTitleError;
    };

    const validateEmailAddress = (email) => {
        let emailAddressError = null;
        if(email === "") {
            emailAddressError = userConstants.messages.emailValidationError;
        }
        if (email != null && email !== '') {
            if (!emailMatcher.test(email)) {
                emailAddressError = userConstants.messages.emailValidationError;
            }
            if (email.toLowerCase() === userDetails.email.toLowerCase()) {
                emailAddressError = messages.emailMustDifferValidationError;
            }
        }
        return emailAddressError;
    };

    const saveAction = () => {
        let firstNameError = validateFirstName(account.firstName);
        let lastNameError = validateLastName(account.lastName);
        let phoneError = validatePhone(account.telephoneNumber);
        let jobTitleError = validateJobTitle(account.jobTitle);

        setErrors({
            errors,
            firstName: firstNameError,
            lastName: lastNameError,
            job: jobTitleError,
            telephone: phoneError
        });

        if (!firstNameError && !lastNameError && !phoneError && !jobTitleError) {
            dispatch(updateUser(account.firstName, account.lastName, account.jobTitle, account.telephoneNumber));
        }
    };

    const acceptNotification = () => {
        setNotification(false);
    };

    const clearSaveError = () => {
        setSaveError(null);
    };

    const onChangeInput = event => {
        const field = event.target.id;
        const value = event.target.value;
        setAccount({...account, [field]: value});
        if (value.trim() !== '') {
            setErrors({...errors, [field]: false});
        }
    };

    const changePassword = () => {
        window.location.assign('/api/auth/passwordReset?redirect_url=/account');
    };

    const changeEmailAddress = () => {
        const emailAddressError = validateEmailAddress(account.email);
        setErrors({...errors, email: emailAddressError});
        if (!emailAddressError) {
            setEmailProcessing(true);
            dispatch(setEmail(account.email));
        }
    };
    const supportLogIn = () => {
        const {supportTargetEmail} = account;
        const emailAddressError = validateEmailAddress(supportTargetEmail);
        setErrors({...errors, supportTargetEmail: emailAddressError});
        if (!emailAddressError && supportTargetEmail) {
            window.location.assign(`/api/auth/supportLogIn?targetEmail=${supportTargetEmail}`);
        }
    }

    return (
        <div className={classes.root}>
            <ContentHeader 
                title={messages.title} />
            <div className={classes.content}>
                {notification &&
                    <Notification variant='success'
                                appearance='toast'
                                autoClose={true}
                                onClose={acceptNotification}>
                        <Typography variant='body1'>
                            <FormattedMessage {...notificationMessage} />
                        </Typography>
                    </Notification>
                }
                {saveError && 
                    <Notification variant='error'
                                appearance='toast'
                                autoClose={true}
                                onClose={clearSaveError}>
                    <Typography variant='body1'>
                        <FormattedMessage {...saveError} />
                    </Typography>
                </Notification>
                }

                <fieldset className={classes.profile}>
                    <Typography variant='h2' color='primary' className={classes.subtitle} component="legend">
                        <FormattedMessage {...messages.yourProfile} />
                    </Typography>
                    <InputBox id='firstName'
                              label={messages.firstNameLabel}
                              error={errors.firstName}
                              errorValues={{field: 'First name'}}
                              aria-invalid={errors.firstName}
                              value={account.firstName || ''}
                              onChange={onChangeInput}
                              onEnterKey={saveAction}
                              required={true}
                              autoComplete='given-name' />
                    <InputBox id='lastName'
                              label={messages.lastNameLabel}
                              error={errors.lastName}
                              errorValues={{field: 'Surname'}}
                              aria-invalid={errors.lastName}
                              value={account.lastName || ''}
                              onChange={onChangeInput}
                              onEnterKey={saveAction}
                              required={true}
                              autoComplete='family-name' />
                    {hasManageContactDetailsPermission(account) &&
                    <InputBox id='jobTitle'
                              label={messages.jobTitleLabel}
                              error={errors.job}
                              aria-invalid={errors.job}
                              value={account.jobTitle || ''}
                              onChange={onChangeInput}
                              onEnterKey={saveAction}
                              autoComplete='on' />
                    }
                    {hasManageContactDetailsPermission(account) &&
                    <InputBox id='telephoneNumber'
                              label={messages.telephoneNumberLabel}
                              error={errors.telephone}
                              aria-invalid={errors.telephone}
                              value={account.telephoneNumber || ''}
                              onChange={onChangeInput}
                              onEnterKey={saveAction}
                              placeholder="03453757535"
                              autoComplete='tel' />
                    }
                     <AddButton
                        classes={{button:classes.actionButton}}
                        label={update.working? messages.savingChanges : messages.saveChanges}
                        showIcon={false}
                        working={update.working}
                        action={saveAction}
                    />
                </fieldset>
                {check(features.CHANGE_EMAIL, config) &&
                    <fieldset className={classes.profile}>
                        <Typography variant='h2' color='primary' className={classes.subtitle} component="legend">
                            <FormattedMessage {...messages.email} />
                        </Typography>
                        <InputBox id='email'
                                  label={messages.registeredEmailLabel}
                                  error={errors.email}
                                  aria-invalid={errors.email}
                                  errorValues={{
                                      field: 'email',
                                      link: <ExternalLink type='support' />
                                  }}
                                  value={account.email || ''}
                                  onChange={onChangeInput}
                                  onEnterKey={changeEmailAddress}
                                  placeholder="example@domain.co.uk"
                                  autoComplete='email' />
                        <AddButton
                            classes={{button:classes.actionButton}}
                            label={messages.changeEmail}
                            showIcon={false}
                            working={(accountEmail && accountEmail.loading) || emailProcessing}
                            action={changeEmailAddress}
                        />
                    </fieldset>
                }
                <div className={classes.padding} ref={changePasswordRef}>
                    <Typography variant='h2' color='primary' className={classes.subtitle}>
                        <FormattedMessage {...messages.password} />
                    </Typography>
                    <Button
                        className={classes.actionButton}
                        color='primary'
                        variant='contained'
                        onClick={changePassword}>
                        <FormattedMessage {...messages.changePassword} />
                    </Button>
                </div>
                {(check(features.SUPPORT_LOG_IN, config) && hasImpersonateUserPermission(userDetails)) &&
                    <div className={classes.padding} ref={supportLogInRef}>
                        <fieldset className={classes.fieldset}>
                            <Typography variant='h2' color='primary' className={classes.subtitle} >
                                <FormattedMessage {...messages.support} />
                            </Typography>
                            <InputBox id='supportTargetEmail'
                                label={messages.supportTargetEmail}
                                error={errors.supportTargetEmail}
                                aria-invalid={errors.supportTargetEmail}
                                value={account.supportTargetEmail || ''}
                                onChange={onChangeInput}
                                onEnterKey={supportLogIn}
                                required={true}
                                placeholder={messages.enterEmailAddress.defaultMessage}
                            />
                            <Button className={classes.actionButton}
                                color='primary'
                                variant='contained'
                                onClick={supportLogIn}>
                                <FormattedMessage {...messages.logIn} />
                            </Button>
                        </fieldset>
                    </div>
                }
            </div>
        </div>
    )
}

export default Account;