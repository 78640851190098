import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Typography from "@mui/material/Typography";
import withStyles from "react-jss";
import { messages } from "./noProjects/Messages";
import CreateProjectButton from "../../components/CreateProjectButton";
import { useSelector } from "react-redux";
import apiSVG from "../../components/icons/api-large.svg";
import { border1, osColour } from "omse-components";
import WhatNext from "../../components/WhatNext";
import { hasManageProjectsPermission } from "../../util/permissions";

const styles = (theme) => ({
    content: {
        margin: "0 auto",
        maxWidth: 724,
    },
    button: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    image: {
        paddingTop: theme.spacing(8),
        [theme.breakpoints.down("sm")]: {
            paddingTop: theme.spacing(2),
        },
    },
    info: {
        display: "flex",
        alignItems: "flex-end",
    },
    contentCenter: {
        color: osColour.neutral.charcoal,
        paddingTop: theme.spacing(3),
        flex: "1 0 auto",
        textAlign: "center",
    },
    contentLeft: {
        paddingTop: theme.spacing(13),
        flex: "1 0 auto",
        textAlign: "left",
        [theme.breakpoints.down("sm")]: {
            paddingTop: theme.spacing(4),
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    WhatIsAProject: {
        color: osColour.neutral.charcoal,
        paddingBottom: theme.spacing(5),
        borderBottom: border1,
        [theme.breakpoints.down("sm")]: {
            paddingBottom: theme.spacing(4),
        },
        "& h2": {
            marginBottom: theme.spacing(2),
        },
    },
    noProjectsHeader: {
        marginBottom: theme.spacing(1.5),
    },
});

const NoProjects = ({ classes }) => {
    const userDetails = useSelector((state) => state.user.current.result);

    const whatIsAProject = (classes) => {
        return (
            <div className={classes.WhatIsAProject}>
            <Typography variant="h2">
                <FormattedMessage
                    {...messages.whatIsAProjectHeader}
                />
            </Typography>
            <Typography paragraph={true} variant="h5">
                <FormattedMessage
                    {...messages.whatIsAProjectParagraph1}
                />
            </Typography>
            <Typography paragraph={true} variant="h5">
                <FormattedMessage
                    {...messages.whatIsAProjectParagraph2}
                />
            </Typography>
        </div>
        )
    }

    if (!hasManageProjectsPermission(userDetails)) {
        return (
            <div className={classes.content}>
                <div className={classes.contentCenter}>
                    <img
                        alt=""
                        className={classes.image}
                        src={apiSVG}
                        width={124}
                    />
                    <Typography
                        variant="h2"
                        className={classes.noProjectsHeader}
                    >
                        <FormattedMessage
                            {...messages.noProjectsReadOnlyHeader}
                        />
                    </Typography>
                </div>
                <div className={classes.contentLeft}>
                    {whatIsAProject(classes)}
                </div>
            </div>
        );
    }
    return (
        <div className={classes.content}>
            <div className={classes.contentCenter}>
                <img
                    alt=""
                    className={classes.image}
                    src={apiSVG}
                    width={124}
                />
                <Typography variant="h2" className={classes.noProjectsHeader}>
                    <FormattedMessage {...messages.noProjectsHeader} />
                </Typography>
                <Typography variant="h5">
                    <FormattedMessage {...messages.noProjectsAccompaniment} />
                </Typography>
            </div>
            <div className={classes.button}>
                <CreateProjectButton
                    label={messages.createFirstProjectButton}
                    componentId="NoProjects"
                />
            </div>
            <div className={classes.contentLeft}>
                {whatIsAProject(classes)}
                <WhatNext />
            </div>
        </div>
    );
};


NoProjects.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NoProjects);
