import React, {Fragment} from 'react';
import PremiumDatasetsTable from "../tables/PremiumDatasetsTable";
import PremiumDatasetsOSOrders from "../tables/PremiumDatasetsOSOrders";
import routePaths, {PSGA_DESCRIPTION_URL, PSGA_DOWNLOADS_VIDEO, FCP_URL} from "../../../../util/routes";
import Link from "../../../../components/Link";
import figChangeLog from '../img/download/data-package-changelog.png';
import figDataPackageDetails from '../img/download/data-package-details.png';
import figDrawingToolsAvailable from '../img/download/drawing-tools-available.png';
import figTileUploadExample from '../img/download/tile-upload-example.png';
import figDataPackageVersions from '../img/download/data-package-versions.png';
import figMultipleZipFiles from '../img/download/multiple-zip-files.png';
import figManageProductLicences from '../img/download/manage-product-licences.png';
import figAcceptFrameworkContract from '../img/download/accept-framework-contract.png';
import figAcceptPartnerUsageContract from '../img/download/accept-partner-usage-contract.png';
import DrawingToolsTable from "../tables/DrawingToolsTable";
import {defineMessages} from "react-intl";
import faqStyles from '../styles/support';
import { theme, ExternalLink } from "omse-components";

const messages = defineMessages({
    psga: {
        id: 'download.psga',
        defaultMessage: 'Public Sector Geospatial Agreement (PSGA)',
        description: 'Description for PSGA message'
    },
    psgaDownloadsVideo: {
        id: 'download.psgaDownloadsVideo',
        defaultMessage: 'How to Download Premium Data video',
        description: 'Description for PSGA Download video message'
    },
    productRefresh: {
        id: 'download.productRefresh',
        defaultMessage: 'OS products refresh dates',
        description: 'Label for the link to OS product refresh dates'
    },
    fcp: {
        id: 'download.fcp',
        defaultMessage: 'Framework Contract – Partners (FCP)',
        description: 'Description for FCP message'
    },
});

export default function download(getImg) {

    const classes = faqStyles(theme);

    return [
        {
            heading: 'Support and products available',
            faqs: [
                {
                    id: 'whatSupportAvailable',
                    title: 'What support is available?',
                    body: <Fragment>
                        <p>Watch our <ExternalLink type='generic' href={PSGA_DOWNLOADS_VIDEO} message={messages.psgaDownloadsVideo} />.
                            For technical support please <ExternalLink type='support' /> quoting the data package ID.</p>
                    </Fragment>
                },
                {
                    id: 'whichBrowsersDevices',
                    title: 'Which browsers/devices can I use for ordering data?',
                    body: <p>The OS Data Hub is compatible with a variety of browsers and devices, however when creating
                        data
                        packages to download data, we recommend using a desktop device rather than mobile or touch
                        device due
                        to usability of the drawing tools. The list of compatible browsers is provided here:&nbsp;
                        <Link path={routePaths.support} hash='#browserCompatibility'>FAQs – Account and API: What
                            browsers and devices are compatible?</Link>
                    </p>

                },
                {
                    id: 'whatCanIOrder',
                    title: 'What can I order?',
                    body: <div style={{display: 'inline-block'}}><p>
                        Premium product data is available to download as data packages on the OS Data Hub.</p>
                        <p>As a <ExternalLink type='generic' href={PSGA_DESCRIPTION_URL} message={messages.psga} />
                        member on the Public Sector Plan, you're entitled to download data for all of Great Britain.
                            If you're signed up to the Premium Plan, once you sign the <ExternalLink type='generic' href={FCP_URL} message={messages.fcp} />,
                        then you'll be able to download our premium products.</p>
                        <p>Points of Interest is the only premium product not yet available on the OS Data Hub – see&nbsp;
                        <a href="#howOrderProductsNotAvailableOnDataHub">
                            How do I order PSGA Premium products not available on the OS Data Hub?
                        </a>
                        &nbsp;for more information on how to order.
                    </p></div>
                },
                {
                    id: 'whatProductsAvailable',
                    title: 'What premium products and formats are available to download?',
                    body: <Fragment>
                        <p>The following premium products and formats are currently available on the OS Data Hub:</p>
                        <PremiumDatasetsTable/>
                    </Fragment>
                },
                {
                    id: 'howOrderPSGAProductsNotAvailableOnDataHub',
                    title: 'How do I order PSGA Premium products not available on the OS Data Hub?',
                    body: <Fragment>
                        <p>Some PSGA Premium product variants supplied only via DVD, HDD or SFTP must still be ordered
                            on OS Orders as they are not yet available via download. These are:
                        </p>
                        <PremiumDatasetsOSOrders/>
                    </Fragment>
                },
                {
                    id: 'areDataPackagesAvailableIndefinitely',
                    title: 'Are data packages available to download indefinitely?',
                    body: <p>New data packages and download orders from your OS Orders account are available for 12
                        months from the initial request date. Product updates are available for 12 months from the date
                        the
                        update was produced.</p>
                },
                {
                    id: 'howLongUntilDataPackageReadyForDownload',
                    title: 'How long will it take for my data package to be available for download? ',
                    body: <p>Our product fulfilment systems remain unchanged and our Service Level Agreement to customers continues for data
                        to be available for download within five days from the initial request. Most products
                        are
                        fulfilled in less than a day however there can be exceptions for larger and more complex
                        requests.
                        Products that can take longer to fulfil are AddressBase, AddressBase Plus, AddressBase Premium,
                        Code-Point with Polygons and OS MasterMap Topography.</p>
                },
                {
                    id: 'physicalMedia',
                    title: 'I can’t take data via download. Can I still take data on physical media?',
                    body: <p>The OS Data Hub supports data downloads only. If you can’t receive data via download and
                            need data
                            on physical media then continue to order this on your OS Orders account. This is an exceptions
                            process
                        only for customers who can’t receive download data. Please <ExternalLink type='support' /> to
                            talk about alternative data delivery requirements.</p>
                },
                {
                    id: 'howOrderProductsNotAvailableOnDataHub',
                    title: 'How do I order products not available on the OS Data Hub?',
                    body: <p>You can order Points of Interest using the pre-existing ordering system.</p>
                },
            ],
        },
        {
            heading: 'Creating data packages and downloading data',
            faqs: [
                {
                    id: 'whatIsADataPackage',
                    title: 'What is a data package?',
                    body: <p>A data package is a downloadable file or group of files containing product data you want to
                        receive. There can be more than one version of a data package when new product updates are
                        available to
                        download. To download (order) data, you create a data package in the OS Data Hub for the product
                        you want,
                        and specify the area, format and update cycle. Once the system has fulfilled the data package,
                        you
                        can download it from the data package list, under <i>Data packages</i>, in the secondary navigation menu displayed when logged into your
                        OS
                        Data Hub account.</p>
                },
                {
                    id: 'whoCanCreateDataPackage',
                    title: 'Who can create a data package?',
                    body: <p>OS Data Hub users with admin or contractor read/write roles can create new data packages
                        for
                        their organisation. See our <Link path={routePaths.supportPlans} hash={'#roleDifference'}>table
                            of
                            OS Data Hub roles </Link> for more details.</p>
                },
                {
                    id: 'whoCanAccessMyDataPackages',
                    title: 'Who can access my data packages?',
                    body: <p>Any OS Data Hub user within an organisation is able to view and download data packages for
                        their organisation</p>
                },
                {
                    id: 'howDoIRequestDataForDownload',
                    title: 'How do I request data for download on the OS Data Hub?',
                    body: <ol>
                        <li>Log in to your organisation account.</li>
                        <li>Click <i>Download</i> in the navigation menu. You will be taken to the <i>Premium Data
                            Downloads</i> catalogue.
                        </li>
                        <li>Search and select the product you want.</li>
                        <li>Click <i>Add data package</i> button.</li>
                        <li>Enter package name. We suggest using a name that will help you easily identify the data
                            package
                            later. Data package names don’t have to be unique.
                        </li>
                        <li>Choose your area. For user defined areas, draw your area of interest using the drawing tools
                            or upload a polygon to the
                            map from the polygon library.
                        </li>
                        <li>Select file format. We show only the available file formats for that selection.</li>
                        <li>Select updates. We show only the available updates for that selection.</li>
                        <li>Click <i>Create data package</i> button.</li>
                        <li>The confirmation page tells you the data package is being created, with options to <i>Add
                            another
                            data package</i> or click to view the data package status in the data package list. You will
                            also
                            receive a confirmation email when a data package is created.
                        </li>
                        <li>The data package list displays all the data packages for your organisation. Once a data
                            package
                            has been fulfilled, the status will change from ‘Available soon’ to ‘Download’. You will
                            also receive an email when the data package is ready to download.
                        </li>
                        <li>Click to open the data package and download the zip file(s).
                        </li>
                    </ol>
                },
                {
                    id: 'howDoIDownloadData',
                    title: 'How do I download data?',
                    body: <Fragment>
                        <p>Navigate to <i>Data packages</i> in the secondary navigation menu. In the data package list click the data
                            package name or the <i>Download</i> icon to open the data package details. Where multiple
                            individual download files exist for a data package, these are grouped into a zip file for
                            ease of use. Click <i>Download zip file</i> to download the data to your required location.
                            Grouped download zip files have a 2GB size limit. Individual files greater than 2GB will not
                            be split and will still be available to download as a single file.</p>

                        <p>Large data sets such as OS MasterMap Topography will have multiple grouped download zip
                            files. The grouped zip files are named by data package ID and OS order ID. However, the name
                            of the product download data files inside the grouped zip file remains in the same readable
                            format as they did previously in OS Orders.</p>

                        <p>It is possible to download files simultaneously, however your browser may restrict
                            the number of files and this is likely to impact your download speed.&nbsp;
                            <b>
                                When you get more than one zip file, make sure you load these files in the correct number
                                sequence to your system.
                            </b>
                        </p>
                        {getImg(figMultipleZipFiles, 'OS Data Hub Screenshot: Data Packages download screen', classes.downloadImgMinWidth)}
                    </Fragment>
                },
                {
                    id: 'whatDrawingToolsAvailable',
                    title: 'What drawing tools are available?',
                    body: <Fragment>
                        <p>You’ll see the drawing toolbar when ordering an area of interest. Tooltips are displayed when
                            you hover over each of the icons. The functions currently available are:</p>
                        {getImg(figDrawingToolsAvailable, 'OS Data Hub Screenshot: Drawing Tools banner with selection icons and search box', classes.downloadImgMinWidth)}
                        <DrawingToolsTable/>
                    </Fragment>
                },
                {
                    id: 'whatsInThePolygonLibrary',
                    title: 'What’s in the polygon library?',
                    body: <Fragment>
                        <p>The polygon library consists of the following folders:</p>
                        <ul>
                            <li>My polygons
                                <ul style={{listStyleType: 'circle'}}>
                                    <li>Data packages – polygons from all data packages and OS Orders containing an area of
                                        interest
                                    </li>
                                    <li>Saved - user saved polygons</li>
                                </ul>
                            </li>
                            <li>Predefined polygons - predefined polygons from OS Boundary Line product and other data
                                sources
                            </li>
                        </ul>
                    </Fragment>
                },
                {
                    id: 'howDoIUploadAFileToPolygonLibrary',
                    title: 'How do I upload a file to the polygon library?',
                    body: <Fragment>
                        <p>If you’ve drawn a polygon in your own geographic information system (GIS), you can upload the
                            polygon to the map and save this to the polygon library. Files must:</p>
                        <ul>
                            <li>Use .WKT file format (max file size 2MB).</li>
                            <li>Use .SHP file format (max file size 2MB).</li>
                            <li>Not contain more than 50,000 points and up to 2,000 polygons for your entire area of
                                interest.
                            </li>
                        </ul>
                        <p>For multiple files, the merged/combined total must meet the total criteria above.</p>
                    </Fragment>
                },
                {
                    id: 'howDoIUploadATileListToPolygonLibrary',
                    title: 'How do I upload a tile list to the polygon library?',
                    body: <Fragment>
                        <p>If you’ve created a tile list for your area of interest, you can upload the list to the map.
                            The list must:</p>
                        <ul>
                            <li>Use .TXT file format with one tile reference per line.</li>
                            <li>Contain no more than a maximum number of 2,500 tile references.</li>
                        </ul>
                        <p>A mix of different types of tile reference in the tile list is supported such as TQ, TQ20,
                            TQ20SE.</p>
                        {getImg(figTileUploadExample, 'Diagram of grid reference types and their related scales', classes.downloadImgWidth)}
                    </Fragment>
                },
                {
                    id: 'whereAreMySavedPolygonsOSOrders',
                    title: 'Where are my saved polygons from OS Orders?',
                    body: <p>Your saved polygons from the OS Orders polygon library are not accessible from the OS Data
                        Hub although you can re-use polygons from previous OS Orders. These are accessible from the Data
                        packages folder in the polygon library.</p>
                },
                {
                    id: 'canIChangeDataPackage',
                    title: 'Can I change a data package?',
                    body: <Fragment>
                        <p>Yes. You can manage and change your data packages via the <i>Data Package</i> page on the OS
                            Data Hub.</p>
                    </Fragment>
                },
                {
                    id: 'howDoIKnowWhenDataPackageHasBeenEdited',
                    title: 'How do I know when a data package has been edited?',
                    body: <Fragment>
                        <p>Every data package has a change history log containing all changes made to it. The log can be
                            viewed by any user from the data package details page. The change log records when a data
                            package was created, renamed, expanded or resupplied. Email notifications are also sent to
                            the organisation’s Principal Contact admin user, the person who originally requested the
                            data package and the person requesting the change, informing them the data package has been
                            edited. </p>
                        {getImg(figChangeLog, 'OS Data Hub Screenshot: Data Package changelog view screen', classes.downloadImgMinWidth)}
                    </Fragment>
                },
                {
                    id: 'howDoIExpandDataPackage',
                    title: 'How do I expand a polygon on my data package?',
                    body: <Fragment>
                        <p>Use the map drawing tools, upload your own file or choose a polygon from the polygon library
                            to expand a polygon on an existing data package. The <i>Expand area</i> button is disabled on GB
                            coverage data packages.</p>
                        <ol>
                            <li>As an admin or contractor read/write user, open the data package details from the data package list.</li>
                            <li>Click <i>Expand area</i> button.</li>
                            <li>If using the map drawing tools, simply draw a new polygon or rectangle onto the map to
                                either overlap the existing polygon or draw an additional, separate area. The new polygon
                                is shown with a dotted background.</li>
                            <li>If the expanded polygon is required for future use, save to your Saved folder in the
                                polygon library.</li>
                            <li>Click <i>Expand area</i> button and the confirmation page is displayed.</li>
                            <li>Go back to the data package details to view:
                                <ol type={'a'}>
                                    <li>The thumbnail of the expanded polygon.</li>
                                    <li>The change history log – an entry is added to the change log with the date and
                                        person who requested the expansion.</li>
                                </ol>
                            </li>
                        </ol>

                        <p>While the expansion is in progress, the data package is locked to prevent further editing
                            from occurring and the <i>Expand area</i> and <i>Get a resupply</i> buttons are disabled.
                            These buttons are re-enabled once processing is complete. If a failure occurs during the
                            fulfilment process and no new version is created, the data package is unlocked, and the
                            buttons are re-enabled for you to retry the expansion.
                            Please <ExternalLink type='support' /> if the problem persists.
                        </p>

                        <p>When you expand an area, you’ll initially receive a full supply of data for the new requested
                            area as a new version of the data package. This is accessible from
                            the <i>download version</i> drop-down menu on the data package details page. Future updates will
                            apply to the entire expanded polygon on the next scheduled product update.</p>

                        <p>We’ll send an email to the organisation’s Principal Contact admin user, the person who
                            originally requested the data package and the person requesting the change informing them
                            the data package has been edited. The email will contain a link to that data package.</p>

                        <p>Please note that if you are an Energy & Infrastructure Plan user, when you expand a data
                        package you will be presented with an additional price to cover this change to your data package
                         over your remaining contract period. A breakdown of the price will also be shown before you
                        confirm.</p>
                    </Fragment>
                },
                {
                    id: 'howDoIResupply',
                    title: 'How do I get a resupply of data for an existing data package?',
                    body: <Fragment>
                        <ol>
                            <li>As an admin or contractor read/write user, open the data package details from the data package list.</li>
                            <li>Click <i>Get a resupply</i> button.</li>
                            <li>Click <i>Get Resupply</i> on the confirmation dialog box.</li>
                            <li>An entry is added to the change log with the date and person who requested the resupply.</li>
                        </ol>

                        <p>While the resupply is in progress, the data package is locked to prevent further editing from
                            occurring and the <i>Expand area</i> and <i>Get a resupply</i> buttons are disabled. These buttons are
                            re-enabled once processing is complete. If a failure occurs during the fulfilment process
                            and no new version is created, the data package is unlocked, and the buttons are re-enabled
                            for you to retry the resupply. Please <ExternalLink type='support' /> if the problem persists.</p>

                        <p>When you request a resupply, you’ll receive a full supply of data of the whole area as a new
                            version of the data package. This is accessible from the <i>download version</i> drop-down menu on the
                            data package details page. Future updates will remain unaffected. </p>

                        <p>We’ll send an email to the organisation’s Principal Contact admin user, the person who originally
                            requested the data package and the person requesting the change informing them the data
                            package has been edited. The email will contain a link to that data package.</p>
                    </Fragment>
                },
                {
                    id: 'howDoIChangeDataPackageName',
                    title: 'How do I change a data package name?',
                    body: <Fragment>
                        <ol>
                            <li>As an admin or contractor read/write user, open the data package details from the data package list.</li>
                            <li>Click the pencil icon next to the data package name.</li>
                            <li>Type the new name and press enter. The changes are saved.</li>
                            <li>An entry is added to the change log with the date and person who requested the rename.</li>
                        </ol>
                    </Fragment>
                },
                {
                    id: 'howDoIReduceTheSizeOfPolygon',
                    title: 'How do I reduce the size of a polygon on an existing data package?',
                    body: <Fragment>
                        <p>Polygons on existing data packages can only be expanded. If you require a smaller area, please
                            create a new data package.</p>
                    </Fragment>
                },
                {
                    id: 'howDoIDeleteDataPackage',
                    title: 'How do I delete an existing data package?',
                    body: <Fragment>
                        <ol>
                            <li>As an admin or contractor read/write user, open the data package details from the data package list.</li>
                            <li>Click the <i>Delete package</i> button.</li>
                            <li>Click <i>Delete package</i> on the confirmation dialog box.</li>
                            <li>The package will be permanently deleted from your account, it will not appear in your packages list and it cannot be downloaded.</li>
                        </ol>
                    </Fragment>
                },
                {
                    id: 'howDoIStopReceivingUpdates',
                    title: 'How do I stop receiving product updates for an existing data package?',
                    body: <Fragment>
                        <ol>
                            <li>As an admin or contractor read/write user, open the data package details from the data package list.</li>
                            <li>Click the <i>Stop updates</i> button.</li>
                            <li>Click <i>Stop updates</i> on the confirmation dialog box.</li>
                            <li>You will not receive any further updates. It cannot be re-activated.
                                It can still be downloaded for 12 months from when you received your last supply.
                                You can still <a href="#howDoIResupply">request a resupply</a>.
                            </li>
                        </ol>
                    </Fragment>
                }
            ]
        },
        {
            heading: 'My downloads',
            faqs: [
                {
                    id: 'whereCanIViewMyOrgsDataPackages',
                    title: 'Where can I view my organisation’s data packages?',
                    body: <p>The data packages list displays all the data packages requested by your organisation. Find
                        them under <i>Data packages</i>, which is found in the secondary navigation menu of the <i>Download</i> page.</p>
                },
                {
                    id: 'whereCanIViewDataPackageDetails',
                    title: 'Where can I view the data package details?',
                    body: <Fragment>
                        <p>Navigate to <i>Data packages</i> in the secondary navigation menu. Click the data package link to view the
                            details
                            of an individual data package. You can view the area of interest by clicking <i>Expand
                                map</i>.</p>
                        {getImg(figDataPackageDetails, 'OS Data Hub Screenshot: Data Package overview screen', classes.downloadImgMinWidth)}
                    </Fragment>
                },
                {
                    id: 'whereAreMyHoldings',
                    title: 'Where are my holdings?',
                    body: <p>We don’t currently use the term ‘holdings’ in the OS Data Hub as PSGA members can order
                        data
                        for all of Great Britain under the PSGA.</p>
                },
                {
                    id: 'whereAreMyOrders',
                    title: 'Where are my orders?',
                    body: <p>You can access the previous 12 months of download orders created in OS Orders from the data
                        package list, under <i>Data packages</i>, which is found in the secondary navigation menu on the <i>Download</i> page. You can open and view details of
                        these orders.</p>
                },
                {
                    id: 'whereAreMyContracts',
                    title: 'Where are my contracts?',
                    body: <p>Data packages created in the OS Data Hub create a separate contract in our fulfilment
                        system
                        which is linked to your organisation. Your contracts are each of the data packages in the data
                        package
                        list, under <i>Data packages</i> in the secondary navigation menu.</p>
                },
            ]
        },
        {
            heading: 'Updates and notifications',
            faqs: [
                {
                    id: 'howAccessPreviousVersionsOfData',
                    title: 'How do I access previous versions of download data?',
                    body: <Fragment>
                        <p>Navigate to Data packages in the secondary navigation menu. Click the data package link to view all versions
                            of the download data for this data package.</p>
                        {getImg(figDataPackageVersions, 'OS Data Hub Screenshot: Data Package download screen - download version drop-down', classes.downloadImgMinWidth)}
                        <p>
                            Data package versions are accessible from the drop-down menu, <i>Choose download version</i>. These
                            are sorted in date order, with the latest version at the top of the list. To view and download
                            the data from a previous data package version, select the previous version from the drop-down menu.
                            These versions are available to download for 12 months from the date the version was produced.
                        </p>
                        <p>
                            The <i>Product version</i> is associated to the product publication date, where this data is available.
                            See <ExternalLink
                                type='generic'
                                href='https://www.ordnancesurvey.co.uk/business-government/tools-support/product-refresh'
                                message={messages.productRefresh}
                            /> for further information.
                        </p>
                    </Fragment>
                },
                {
                    id: 'howKnowWhenNewDataPackageOrUpdateAvailable',
                    title: 'How do I know when a new data package or an update is available to download?',
                    body: <p>We’ll send an email to the organisation’s Principal Contact admin user and the person who
                        originally requested the data package to say a new data package, or an update, is available to download.
                        The email will contain a link to that data package.</p>
                },
                {
                    id: 'whatEmailNotifications',
                    title: 'What email notifications will I get?',
                    body: <p>See our <Link path={routePaths.supportPlans} hash={'#roleDifference'}>OS Data Hub user roles</Link> for
                        details on the email notifications you'll receive regarding data packages.</p>
                },
            ]
        },
        {
            heading: 'Managing licences',
            faqs: [
                {
                    id: 'howManageProductLicences',
                    title: 'How do I manage my product licences?',
                    body: <Fragment>
                        <p>The ‘Manage product licences’ page will be displayed if your organisation has a Licensed
                            Partner agreement with Ordnance Survey. Listed are the Partner Contracts and products your
                            organisation is licensed to use.</p>

                        <p>You can search for a product using the search bar at the top of the page.</p>

                        <p>The contract name will be displayed, and you can view the contract by clicking on the hyperlink.</p>

                        <p>The date your Partner Contract type is due to be renewed will be displayed depending on the ‘term’ you’ve selected.
                            {getImg(figManageProductLicences, 'OS Data Hub Screenshot: Manage Product Licences screen', classes.downloadImgMinWidth)}
                        </p>
                    </Fragment>
                },
                {
                    id: 'howAcceptFrameworkContractPartners',
                    title: 'How do I accept the Framework Contract (Partners)?',
                    body: <p>The Framework Contract (Partners) must be accepted before the Partner Contracts will become active.
                        Tick to accept the Framework Contract (Partners). You can follow the link to read the contract.
                        Once you click the 'Accept and continue' button, the available Partner Contracts will be activated.
                        {getImg(figAcceptFrameworkContract, 'OS Data Hub Screenshot: Modal for Accept Framework Contract')}
                    </p>
                },
                {
                    id: 'howAcceptPartnerUsageContract',
                    title: 'How do I accept a Partner Usage Contract?',
                    body: <Fragment>
                        <p>Accept a Partner Usage Contract type by either unlocking a contract for all products or
                        selecting specific products under certain contracts. You can follow the link to read the contract.
                        Select a duration for your licence using the dropdown menu.
                        {getImg(figAcceptPartnerUsageContract, 'OS Data Hub Screenshot: Modal with year length selection for the Developer Licence')}
                        </p>

                        <p>Click ‘Agree to licence’, then ‘Confirm licensing choices’.</p>
                    </Fragment>
                },
                {
                    id: 'whoCanOrderSitesAndBuildingHeight',
                    title: 'Who can order OS MasterMap Sites Layer and OS MasterMap Building Height Attribute data?',
                    body: <p>OS MasterMap Sites Layer and OS MasterMap Building Height Attribute are available as
                        enhancements to OS MasterMap Topography Layer. Customers are therefore entitled to download and
                        use this additional data for any area/s where they currently hold a valid OS MasterMap
                        Topography Layer licence.
                    </p>
                },
            ]
        }
    ]
}
