import { defineMessages } from "react-intl";

const teamSpaceDetailsMessages = defineMessages({
    title: {
        id: "teamSpaceDetailsMessages.title",
        defaultMessage: "Create a new Team Space",
        description: "New Team Space Details > Title",
    },
    nameInputLabel: {
        id: "teamSpaceDetailsMessages.nameInputLabel",
        defaultMessage: "Name",
        description: "New Team Space Details > Name Input",
    },
    nameErrorMessage: {
        id: "teamSpaceDetailsMessages.nameErrorMessage",
        defaultMessage: "A name must contain between 1-255 characters, and must not start/end with a space.",
        description: "New Team Space Details > Name Input Error",
    },
    descriptionInputLabel: {
        id: "teamSpaceDetailsMessages.descriptionInputLabel",
        defaultMessage: "Description (Optional)",
        description: "New Team Space Details > Description Input",
    },
});

export default teamSpaceDetailsMessages;
