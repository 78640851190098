import React from 'react';
import {createUseStyles} from 'react-jss';
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage} from 'react-intl';
import Typography from '@mui/material/Typography';
import DownloadImage from '../DownloadImage';
import {ExternalLink} from 'omse-components';

const messages = defineMessages({
    productInformation: {
        id: 'SampleDataDownloadsItem.productInformation',
        defaultMessage: 'Product Information',
        description: 'Label for product info link'
    },
    supportingInformation: {
        id: 'SampleDataDownloadsItem.supportingInformation',
        defaultMessage: 'Supporting information',
        description: 'Label for Supporting Information'
    },
    additionalInfo: {
        id: 'SampleDataDownloadsItem.additionalInfo',
        defaultMessage: 'Also available',
        description: 'Label for Additional Information'
    },
});

const useStyles = createUseStyles(theme => ({
    sideTitle: {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')] : {
            marginLeft: theme.spacing(2),
            marginBottom : theme.spacing(1.5)
        }
    },
    links: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(3),
        gap: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(2),
            gap: theme.spacing(2)
        }
    },
    sideInfo: {
        flex: '1',
        marginBottom: theme.spacing(6.5),
        [theme.breakpoints.up('lg')]: {
            marginLeft: theme.spacing(2)
        },
        [theme.breakpoints.down('lg')]: {
            marginLeft: theme.spacing(0)
        }
    }
}));

export function SampleDataSideInfo({item}) {
    const classes = useStyles();
    const images = [];
    for(let i = 0; i < item.imageCount; i++) {
        images.push(
            <DownloadImage key={i} images={item.images} fixedIndex={i} productName={item.name} />
        );
    }

    return (
        <aside className={classes.sideInfo}>
            <Typography variant='h4' component='h2' className={classes.sideTitle}>
                <FormattedMessage {...messages.supportingInformation}/>
            </Typography>
            <div className={classes.links}>
                {item.documentationUrl && (
                    <ExternalLink
                        type="generic"
                        href={item.documentationUrl}
                        message={messages.productInformation}
                    />
                )}
            </div>
            { images }
        </aside>
    );
}

SampleDataSideInfo.propTypes = {
    item: PropTypes.object.isRequired
};

export default SampleDataSideInfo;
