export const PREFIX = 'action:teamSpaces:';
export const GET_TEAMSPACES_LOAD_ACTION = PREFIX + 'getTeamSpaces:load';
export const GET_TEAMSPACES_LOADED_ACTION = PREFIX + 'getTeamSpaces:loaded';
export const GET_TEAMSPACE_LOAD_ACTION = PREFIX + 'getTeamSpace:load';
export const GET_TEAMSPACE_LOADED_ACTION = PREFIX + 'getTeamSpace:loaded';
export const CREATE_TEAMSPACE_ACTION = PREFIX + 'createTeamSpace';
export const GET_ORG_USERS_LOAD_ACTION = PREFIX + 'getOrgUsers:load';
export const GET_ORG_USERS_LOADED_ACTION = PREFIX + 'getOrgUsers:loaded';
export const GET_ORG_USERS_CLEAR_ACTION = PREFIX + 'getOrgUsers:clear';

export function createTeamSpace(teamSpace) {
    return {
        type: CREATE_TEAMSPACE_ACTION,
        name: teamSpace.name,
        description: teamSpace.description,
        ownerDatahubDeveloperIds: teamSpace.ownerDatahubDeveloperIds,
        members: teamSpace.members,
        locked: teamSpace.locked
    }
}

export function getTeamSpaces() {
    return {
        type: GET_TEAMSPACES_LOAD_ACTION
    }
}

export function getTeamSpace(teamSpaceId) {
    return {
        type: GET_TEAMSPACE_LOAD_ACTION,
        teamSpaceId
    }
}

export function getOrgUsers() {
    return {
        type: GET_ORG_USERS_LOAD_ACTION
    }
}

export function clearOrgUsers() {
    return {
        type: GET_ORG_USERS_CLEAR_ACTION
    }
}