// ChartJS defaults
import {Chart, Tooltip, ArcElement, CategoryScale, LinearScale, LineElement, PointElement, Filler} from 'chart.js';
import {osColour} from "omse-components";
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(annotationPlugin);
Chart.register(ArcElement);
Chart.register(LineElement);
Chart.register(PointElement);
Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(Filler);
Chart.register(Tooltip);

Chart.defaults.defaultFontFamily = "'Source Sans Pro','sans-serif'";
Chart.defaults.plugins.tooltip.mode = 'index';
Chart.defaults.plugins.tooltip.intersect = false;
Chart.defaults.plugins.tooltip.backgroundColor = 'white';
Chart.defaults.plugins.tooltip.titleFontColor = osColour.neutral.charcoal;
Chart.defaults.plugins.tooltip.titleFontSize = 16;
Chart.defaults.plugins.tooltip.titleMarginBottom = 12;
Chart.defaults.plugins.tooltip.titleFontStyle = 'normal';
Chart.defaults.plugins.tooltip.bodyFontColor = osColour.neutral.stone;
Chart.defaults.plugins.tooltip.bodyFontSize = 16;
Chart.defaults.plugins.tooltip.bodySpacing = 10;
Chart.defaults.plugins.tooltip.borderColor = osColour.neutral.mist;
Chart.defaults.plugins.tooltip.borderWidth = 2;
Chart.defaults.plugins.tooltip.cornerRadius = 4;
Chart.defaults.plugins.tooltip.xPadding = 12;
Chart.defaults.plugins.tooltip.yPadding = 12;
