//  A hook to return the vatRate adjuster.
import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";
import {getPaymentConfigData} from "../modules/payments/actions";

export default function useVatRateAdjuster() {
    const dispatch = useDispatch();
    const {result: paymentsConfigData, loading, error} = useSelector((state) => state.payments.config);

    useEffect(() => {
        if (!paymentsConfigData && !loading && !error) {
            dispatch(getPaymentConfigData());
        }
    }, [paymentsConfigData, dispatch, loading, error]);

    return paymentsConfigData && (paymentsConfigData.vatRate + 1);
}