import { defineMessages } from "react-intl";

const teamSpacesMessages = defineMessages({
    title: {
        id: "teamSpacesPageMessages.title",
        defaultMessage: "Team Spaces",
        description: "Team Spaces > Title",
    },
    searchBoxLabel: {
        id: "teamSpacesMessages.searchBoxLabel",
        description: "Team Spaces > Search Label",
        defaultMessage: "Search Team Spaces",
    },
    searchBoxPlaceholder: {
        id: "teamSpacesMessages.searchBoxPlaceholder",
        description: "Team Spaces > Search Filter",
        defaultMessage: "Find Team Spaces by name",
    },
    dataSortAria: {
        id: "teamSpacesMessages.dataSortAria",
        description: "Team Spaces > Data Sort > Aria Label",
        defaultMessage: "Sort Team Spaces by property",
    },
    dataFilterAria: {
        id: "teamSpacesMessages.dataFilterAria",
        description: "Team Spaces > Data Filter > Aria Label",
        defaultMessage: "Filter Team Spaces by property",
    },
    errorGeneric: {
        id: "teamSpacesMessages.errorGeneric",
        description: "Team Spaces > Error > Generic",
        defaultMessage: "There was a problem accessing the Team Spaces service.",
    },
    errorContent: {
        id: "teamSpacesMessages.errorContent",
        description: "Team Spaces > Error > Content",
        defaultMessage: "Please try again, and if the problem persists then {link}.",
    },
    deletedUserAccount: {
        id: "teamSpacesMessages.deletedUserAccount",
        defaultMessage: "Deleted user",
        description: "Text to display when a datahubDeveloperId does not match a record."
    },
});

export default teamSpacesMessages;
