import { osColour } from "omse-components";
import styled from "@emotion/styled";
import { FormattedMessage, useIntl } from "react-intl";
import ownersTabMessages from "./OwnersTab.msg";
import PropTypes from "prop-types";
import newTeamSpaceMessages from "../NewTeamSpace.msg";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    & > div {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    & > div:last-of-type {
        color: ${osColour.neutral.stone};
    }
    & > div span {
        word-break: break-all;
    }
    & .divider {
        margin: 0 0.5em;
    }
    & .selected {
        font-style: italic;
    }
`;

export default function OwnerMemberInfo({ info, user, currentTab, prevSelected }) {
    const intl = useIntl();
    const isLoggedInUser = info.datahubDeveloperId === user.datahubDeveloperId;

    if (prevSelected) {
        return (
            <Container>
                <div>
                    <span>{info.firstName}</span>&nbsp;
                    <span>
                        {info.lastName}
                        {isLoggedInUser ? intl.formatMessage(ownersTabMessages.youSuffix) : ""}
                    </span>
                </div>
                <div>
                    <span>
                        {info.role}
                        <span className="divider">|</span>
                    </span>
                    <span>{info.email}</span>
                    <span className="selected">
                        <FormattedMessage {...newTeamSpaceMessages.alreadySelected} values={{
                            tab: currentTab === 'owners' ? "a Member" : "an Owner"
                        }}/>
                    </span>
                </div>
            </Container>
        );
    }

    return (
        <Container>
            <div>
                <span>{info.firstName}</span>&nbsp;
                <span>
                    {info.lastName}
                    {isLoggedInUser ? intl.formatMessage(ownersTabMessages.youSuffix) : ""}
                </span>
            </div>
            <div>
                <span>
                    {info.role}
                    <span className="divider">|</span>
                </span>
                <span>{info.email}</span>
            </div>
        </Container>
    );
}

OwnerMemberInfo.propTypes = {
    info: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    currentTab: PropTypes.string,
    prevSelected: PropTypes.bool,
};
