import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {createUseStyles} from 'react-jss';
import {styles} from "../../DownloadStyles";
import {defineMessages, FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import routes from "../../../../util/routes";
import SearchBox from "../../../../components/SearchBox";
import {TotalResultsBar} from '../../../../components/TotalResultsBar';
import {SampleDataDownloadsItem} from "./SampleDataDownloadsItem";
import {DropDownMenu} from "omse-components";
import {SAMPLE_DATA} from "../../../../../shared/features";
import FeatureCheck from "../../../../components/FeatureCheck";
import {useQuery} from "@tanstack/react-query";
import {fetchSampleCatalogue} from "../../../../modules/downloads";

import {useHistory} from "react-router-dom";
import check from "../../../../util/featureCheck";

const useStyles = createUseStyles(styles);

const messages = defineMessages({
    title: {
        id: 'SampleDataDownloads.title',
        defaultMessage: 'SampleData Downloads',
        description: 'Label for OS SampleData downloads'
    },
    subtitle: {
        id: 'SampleDataDownloads.subtitle',
        defaultMessage: 'Ordnance Survey provides this free service to download Sample Data for geospatial dataset from OS.',
        description: 'Sub title for OS SampleData downloads'
    },
    searchLabel: {
        id: 'SampleDataDownloads.searchLabel',
        defaultMessage: 'Search SampleData downloads',
        description: 'Label for Search OS SampleData downloads'
    },
    searchPlaceholder: {
        id: 'SampleDataDownloads.searchPlaceholder',
        defaultMessage: 'Search by name',
        description: 'Placeholder for Search OS SampleData downloads'
    },
    clearSearch: {
        id: 'SampleDataDownloads.clearSearch',
        defaultMessage: 'Clear search',
        description: 'Clear search label'
    },
    allData: {
        id: 'OpenDataDownloads.allDataLabel',
        defaultMessage: 'All types of data',
        description: 'Label for All data'
    },
});

export default function SampleDataDownloads() {
    const history = useHistory();

    const classes = useStyles();
    const config = useSelector(state => state.config.current.result);
    const { data: result, isLoading: loading} = useQuery({queryKey:["sample-catalogues"],  queryFn: () => fetchSampleCatalogue()});


    const allData = messages.allData.defaultMessage;
    const [search, setSearch] = useState("");
    const [selectedCategoryFilterOption, setSelectedCategoryFilterOption] = useState(allData);
    const filteredDownloads = [];
    const categoryFilterOptions = [{
        id: allData,
        label: allData,
        value: allData
    }];

    if (result) {
        [...new Set(result.map(catalogueItem => catalogueItem.category))]
            .sort()
            .forEach(category =>
                categoryFilterOptions.push({
                    id: category,
                    label: messages[category.toLowerCase()] || category,
                    value: category
                })
            );

        const searchTerms = search.toLowerCase().trim().split(' ');
        searchTerms
            .reduce( (previous, term) => {
                const filtered = result.filter(catalogueItem =>
                    (catalogueItem.category === selectedCategoryFilterOption || selectedCategoryFilterOption === allData) &&
                    (previous.indexOf(catalogueItem) === -1 & catalogueItem.name.toLowerCase().indexOf(term) !== -1)
                );
                return previous.concat(filtered);
                }, [])
            .forEach(matchingDownload => filteredDownloads.push(matchingDownload));
    }

    if(!check(SAMPLE_DATA, config)){
        history.replace({
            pathname: '/error404'
        });
    }
    return (
        <>
            <FeatureCheck feature={SAMPLE_DATA}>
                <header className={classes.root}>
                    <Typography variant='h1' color='primary'>
                        <FormattedMessage {...messages.title} />
                    </Typography>
                    <Typography variant='body1' color='textPrimary'>
                        <FormattedMessage {...messages.subtitle} />
                    </Typography>
                </header>
                <div className={classes.content}>
                    <div className={classes.controls}>
                        <SearchBox label={messages.searchLabel}
                                   placeholder={messages.searchPlaceholder}
                                   search={search}
                                   setSearch={setSearch}
                                   className={classes.searchOption}
                        />
                        <div className={classes.filterControls}>
                            <DropDownMenu
                                buttonId='dataFilter'
                                buttonProps={{'aria-label': 'Filter by data structure'}}
                                buttonFontWeight='bold'
                                value={selectedCategoryFilterOption}
                                buttonVariant='outlined'
                                buttonClassName={classes.filterControl}
                                items={categoryFilterOptions}
                                onChange={selection => setSelectedCategoryFilterOption(selection.value)}
                            />
                        </div>
                        <TotalResultsBar isLoading={loading} totalResults={filteredDownloads.length}/>
                    </div>

                    <div className={classes.downloads} aria-live='polite' data-testid="downloads">
                        {(loading)
                            ? <CircularProgress size={32} className={classes.loader}/>
                            : filteredDownloads.map((item) => (
                                <SampleDataDownloadsItem
                                    item={item}
                                    key={item.name}
                                    path={routes.sampleDataItem.replace(':downloadId', item.id)}
                                />
                            ))
                        }
                    </div>
                </div>
            </FeatureCheck>
        </>
    );
}
