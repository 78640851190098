import { defineMessages } from "react-intl";

const membersTableMessages = defineMessages({
    membersTableHeading: {
        id: "membersTableMessages.membersTableHeading",
        defaultMessage: "Members",
        description: "Members table Members column heading",
    },
    membersTableAPIsHeading:{
        id: "membersTableMessages.membersTableAPIsHeading",
        defaultMessage: "APIs",
        description: "Members table APIs column heading",
    },
    membersTableDataHeading:{
        id: "membersTableMessages.membersTableDataHeading",
        defaultMessage: "Data packages",
        description: "Members table Data packages column heading",
    },
    emptyOpenTable: {
        id: "membersTableMessages.emptyOpenTable",
        defaultMessage: "The team is open, so everybody can see it.",
        description: "Members table open text",
    },
    membersHelpViewer: {
        id: "membersTableMessages.membersHelpViewer",
        defaultMessage: "<b>Viewers</b> can view the Team space, access APIs or download Data Packages, but can’t add or make changes to API projects or Data Packages inside the team.",
        description: "Members help viewer",
    },
    membersHelpEditor: {
        id: "membersTableMessages.membersHelpEditor",
        defaultMessage: "<b>Editors</b> can add and remove API projects and Data Packages. But they can’t manage who has access to the Team space.",
        description: "Members help editor",
    },
    remove: {
        id: "membersTableMessages.remove",
        defaultMessage: "Remove",
        description: "Remove label",
    },
});

export default membersTableMessages