import React, {Fragment, useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {createUseStyles} from 'react-jss';
import DownloadImageModal from "./DownloadImageModal";
import {ENTER} from '../../../constants/keys';
import {defineMessages, useIntl} from 'react-intl';

const useStyles = createUseStyles(theme => ({
    productImgContainer: {
        flex: '0 0 auto',
        width: 196,
        height: 196,
        overflow: 'hidden',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        position: 'relative'
    },
    productImg: {
        cursor: 'zoom-in',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 'auto'
        },
        position: 'absolute',
        transition: 'left 0.3s linear'
    }
}));

const messages = defineMessages({
    hoverLabel: {
        id: 'DownloadImage.toLiveTitle',
        defaultMessage: 'Open preview modal for {productName}',
        description: 'Title for the change dialog when switching to dev mode'
    }
});

function DownloadImage({fixedIndex, images, animate, productName, timeout = 5000}){
    const classes = useStyles();
    const intl = useIntl();
    const [open, setOpen] = useState(-1);
    const [index, setIndex] = useState(0);

    function close(){
        setOpen(-1);
    }

    const nextIndex = useCallback(index => {
        const nextIndex = index + 1;
        if(nextIndex === images.length) {
            return 0;
        } else {
            return nextIndex;
        }
    }, [images.length])

    useEffect(function() {
        if(animate) {
            const intervalId = setInterval(function() {
                setIndex(nextIndex);
            }, timeout);
            return function cleanUp() {
                clearInterval(intervalId);
            }
        }
    }, [animate]);

    function setupImage(imageIndex) {
        const myUrl = images[imageIndex].small;
        const myOpen = () => setOpen(imageIndex);
        // This position slides the image into the correct place... images before the current index are moved off
        // to the left, and images after the current position go to the right
        let leftPosition = 0;
        if(fixedIndex === -1) {
            leftPosition = (imageIndex - index) * 196;
        }
        return <img key={imageIndex}
                    src={myUrl}
                    height={196}
                    className={classes.productImg}
                    style={{ left: leftPosition }}
                    alt={`Example product: ${productName} ${imageIndex + 1}`}
                    aria-label={intl.formatMessage(messages.hoverLabel, {productName})}
                    onClick={myOpen}
                    onKeyUp={myOpen} />
    }

    let content;
    if(fixedIndex !== -1) {
        content = setupImage(fixedIndex);
    } else {
        let imgArray = [];
        for (let i = 0; i < images.length; i++) {
            imgArray.push(setupImage(i));
        }
        content = imgArray;
    }

    return <Fragment>
        {open !== -1 &&
            <DownloadImageModal images={images} initialIndex={open} close={close} productName={productName}/>
        }
        <div className={classes.productImgContainer}
             aria-live='off'
             tabIndex='0'
             onKeyDown={e => {
                if (e.key === ENTER) {
                    setOpen(0);
                }
             }
        }>
            {content}
        </div>
    </Fragment>;
}

DownloadImage.propTypes = {
    images: PropTypes.array.isRequired,
    fixedIndex: PropTypes.number.isRequired,
    productName: PropTypes.string
};

DownloadImage.defaultProps = {
    fixedIndex: -1
};

export default DownloadImage;
