import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ownersTabMessages from "./OwnersTab.msg";
import { CircularProgress, Autocomplete } from "@mui/material";
import { useIntl } from "react-intl";
import { ADMIN_ROLE, USER_ROLE, FINANCE_ROLE } from "../../../../shared/roles";
import SearchField from "./SearchField";
import { getOrgUsers } from "../../../modules/teamSpaces/actions";
import styled from "@emotion/styled";
import { css } from '@emotion/css';
import OwnersTable from "./OwnersTable";
import PropTypes from "prop-types";
import OwnerMemberInfo from "./OwnerMemberInfo";
import routePaths from '../../../util/routes';
import Link from '../../../components/Link';
import { ownersTab } from "./TeamSpaceAccess";

const noOptionsClass = css`
    &:empty {
        display: none;
    }
`;

const endAdornmentClass = css`
    margin-right: 8px;
`;

const Container = styled.div(({ theme }) => `
    padding: ${theme.spacing(3, 4)};
    & .loading {
        margin: ${theme.spacing(2)}
    }
`);

const validOwnerRoles = [ADMIN_ROLE, USER_ROLE, FINANCE_ROLE];
const MIN_SEARCH_LENGTH = 3;

export default function OwnersTab({ owners, setOwners, selectedUserIds }) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.current.result);
    const orgUsers = useSelector(state => state.teamSpaces.getOrgUsers.result);
    const orgUsersLoading = useSelector(state => state.teamSpaces.getOrgUsers.loading);

    const [filteredOrgUsers, setFilteredOrgUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [autocompleteKey, setAutocompleteKey] = useState('autocomplete-owners');

    useEffect(function () {
        if ((!orgUsers || orgUsers.length === 0)) {
            dispatch(getOrgUsers());
        }
    }, [dispatch, orgUsers]);

    useEffect(function () {
        if (user && orgUsers && orgUsers.length >= 0) {

            // Add all ADMIN org users to the owner list.
            const tempSelectedOwners = orgUsers.filter(orgUser => (orgUser.role === ADMIN_ROLE));
            if (!owners || owners.length === 0) {
                setOwners(tempSelectedOwners);
            }

            // Search field results filtering.
            const tempFilteredOrgUsers = orgUsers.filter(orgUser => (
                (validOwnerRoles.indexOf(orgUser.role) !== -1) &&
                ((user.role !== ADMIN_ROLE) || (user.datahubDeveloperId !== orgUser.datahubDeveloperId))) // non-Admin users can select themselves.
            );
            setFilteredOrgUsers(tempFilteredOrgUsers);
        }
    }, [user, orgUsers, owners, setOwners, setFilteredOrgUsers]);

    function resetAutocomplete() {
        setSearchTerm('');
        setAutocompleteKey(`autocomplete-owners-${Date.now()}`)
    }

    // Autocomplete change: select/clear option
    function handleAutoCompleteChange(setter, selectedUserItem, reason) {
        if (reason === 'selectOption') {
            if (selectedUserItem) {
                setOwners([...owners, selectedUserItem]);
                resetAutocomplete();
            }
        }
    }

    const onKeyUpHandler = (event) => {
        if (event) {
            setSearchTerm(event.target.value || '')
        }
    }

    const orgUserDetailsContainSearchTerm = useMemo(() => (orgUser) => {
        const st = searchTerm?.toLowerCase();
        const fn = orgUser.firstName?.toLowerCase();
        const ln = orgUser.lastName?.toLowerCase();
        const em = orgUser.email?.toLowerCase();
        const result = (
            (st) &&
            ((fn && fn.indexOf(st) !== -1) ||
                (ln && ln.indexOf(st) !== -1) ||
                (em && em.indexOf(st) !== -1) ||
                ((fn && ln) && (fn + " " + ln).indexOf(st) !== -1))
        )
        return result;
    }, [searchTerm]);

    return (
        <Container>
            <Autocomplete
                key={autocompleteKey}
                fullWidth
                clearOnEscape
                handleHomeEndKeys
                autoHighlight
                clearOnBlur={false}
                getOptionLabel={() => ''}
                getOptionDisabled={option => option === "" || selectedUserIds.includes(option.datahubDeveloperId)}
                forcePopupIcon={false}
                options={filteredOrgUsers || []}
                loading={orgUsersLoading}
                onChange={handleAutoCompleteChange}
                filterOptions={(options, state) => {
                    const results = options.filter(orgUser => (
                        (searchTerm.length >= MIN_SEARCH_LENGTH) &&
                        (!owners.find(owner => (owner.datahubDeveloperId === orgUser.datahubDeveloperId))) &&
                        orgUserDetailsContainSearchTerm(orgUser)
                    ));
                    return (results?.length > 0) ? results : [];
                }}
                renderInput={(params) => (
                    <SearchField {...params}
                        showClearSearchTerm={(searchTerm.length > 0)}
                        onClearSearchTerm={resetAutocomplete}
                    />
                )}
                renderOption={(props, option) => {
                    const prevSelected = selectedUserIds.includes(option.datahubDeveloperId);
                    return (
                        <li {...props} key={option.datahubDeveloperId}>
                            <OwnerMemberInfo 
                                info={option} 
                                user={user} 
                                currentTab={ownersTab} 
                                prevSelected={prevSelected} 
                            />
                        </li>
                    );
                }}
                css={{
                    endAdornment: endAdornmentClass,
                    noOptions: noOptionsClass
                }}
                classes={{
                    inputRoot: 'inputRoot',
                }}
                noOptionsText={(searchTerm.length >= MIN_SEARCH_LENGTH)
                    ? ((user.role === ADMIN_ROLE) ?
                        intl.formatMessage(ownersTabMessages.noResultsAdminPrompt, { a: chunks => <Link path={routePaths.manageTeamMembers}>{chunks}</Link> })
                        : intl.formatMessage(ownersTabMessages.noResultsPrompt))
                    : ''
                }
                onKeyUp={onKeyUpHandler}
            />

            {(orgUsersLoading || !user) &&
                <CircularProgress size={32} className="loading" />
            }

            {(!orgUsersLoading && user) && (
                <OwnersTable owners={owners} setOwners={setOwners} />
            )}
        </Container>
    );
}

OwnersTab.propTypes = {
    setOwners: PropTypes.func.isRequired,
    owners: PropTypes.array.isRequired,
    selectedUserIds: PropTypes.arrayOf(PropTypes.string)
};