import { defineMessages } from "react-intl";

const membersTabMessages = defineMessages({
    makePrivate: {
        id: "membersTabMessages.makePrivate",
        defaultMessage: "Make private",
        description: "The 'toggle locked' <Switch /> label.",
    },
    noResultsAdminPrompt: {
        id: "membersTabMessages.noResultsAdminPrompt",
        defaultMessage: "No results. To add a user not shown, <a>invite them to Data Hub</a>.",
        description: "No results admin prompt",
    },
    lockedTooltip: {
        id: "membersTabMessages.unlockedTooltip",
        defaultMessage: "Private team spaces can only be seen and accessed by assigned owners and members.",
        description: "The 'toggle is open' <Switch /> tooltip content.",
    },
    unlockedTooltipP1: {
        id: "membersTabMessages.lockedTooltipP1",
        defaultMessage: "<strong>Viewers</strong> can view the Team space, access APIs or download Data Packages, but can’t add or make changes to API projects or Data Packages inside the team.",
        description: "The 'toggle is private' <Switch /> tooltip content.",
    },
    unlockedTooltipP2: {
        id: "membersTabMessages.lockedTooltipP2",
        defaultMessage: "<strong>Editors</strong> can add and remove API projects and Data Packages. But they can’t manage who has access to the Team space",
        description: "The 'toggle is private' <Switch /> tooltip content.",
    },
    openMessage: {
        id: "membersTabMessages.unlockedMessage",
        defaultMessage: "This team space is open so everyone can see it.<br></br>You may make this space “Private” above and start adding members.",
        description: "The 'Open' team context message.",
    },
});

export default membersTabMessages