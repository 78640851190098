/**
 * This is the entry point for the front-end web application.
 * React-scripts needs to find this file, so we cannot move or rename it.
 * Most of the real client code is in the client directory.
 */

//Imports polyfills
import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import 'core-js/features/object/entries';
import 'core-js/features/number/is-integer';
import 'core-js/features/array/for-each';

// Dynamic polyfills
import { shouldPolyfill as shouldPolyfillIntlPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as shouldPolyfillIntlRelativetimeformat } from '@formatjs/intl-relativetimeformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillIntlLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillIntlNumberFormat } from '@formatjs/intl-numberformat/should-polyfill';

import React from 'react';
import './index.css';
import './responsive-navigation.css';
import App from './client/App';
import store from './client/store';
import { loadJSON, theme } from 'omse-components';
import { Provider } from 'react-redux';
import {IntlProvider} from 'react-intl';
import {BrowserRouter as Router} from 'react-router-dom';
import {LocalizationProvider} from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import enGB from 'date-fns/locale/en-GB';
import { createRoot } from 'react-dom/client';

// Component styles
import { ThemeProvider } from 'react-jss';

// External styles
import { ThemeProvider as MaterialThemeProvider, StyledEngineProvider } from '@mui/material/styles';

// Register all of the React components that render pages into the app
import './client/pages';
import './client/util/chart';
import InactivityLogoutProvider from './client/components/inactivityLogout/InactivityLogout';
import { QueryClient, QueryClientProvider} from "@tanstack/react-query";
// Fix issue with hash link scroll behaviour caused by zenscroll, a dependency of SwaggerUI.
window.noZensmooth = true;

enGB.options.weekStartsOn = 0;
const queryClient = new QueryClient();

async function dynamicPolyfill() {
    if (shouldPolyfillIntlLocale()) {
        await import('@formatjs/intl-locale/polyfill');
    }
    if (shouldPolyfillIntlPluralRules()) {
        await import('@formatjs/intl-pluralrules/polyfill');
        await import('@formatjs/intl-pluralrules/locale-data/en');
    }
    if (shouldPolyfillIntlRelativetimeformat()) {
        await import('@formatjs/intl-relativetimeformat/polyfill');
        await import('@formatjs/intl-relativetimeformat/locale-data/en');
    }
    if (shouldPolyfillIntlNumberFormat()) {
        await import('@formatjs/intl-numberformat/polyfill');
        await import('@formatjs/intl-numberformat/locale-data/en');
    }
}

dynamicPolyfill().then(() => {
    const locale = 'en-gb';

    function loadMessages() {
        if (window.location.search.indexOf('lang=zz') === -1) {
            return Promise.resolve({});
        }
        return loadJSON('messages_zz.json').catch(function () {
            console.error('Failed to load mock translated messages, falling back to application defaults');
            return {};
        });
    }

    loadMessages().then((messages) => {
        const container = document.getElementById('root');
        const root = createRoot(container);
        root.render(
            <QueryClientProvider client={queryClient}>
                <Provider store={store}>
                    <IntlProvider locale={locale} messages={messages} defaultLocale='en-gb'>
                        <StyledEngineProvider injectFirst>
                            <MaterialThemeProvider theme={theme}>
                                <ThemeProvider theme={theme}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                                        <Router>
                                            <InactivityLogoutProvider>
                                                <App/>
                                            </InactivityLogoutProvider>
                                        </Router>
                                    </LocalizationProvider>
                                </ThemeProvider>
                            </MaterialThemeProvider>
                        </StyledEngineProvider>
                    </IntlProvider>
                </Provider>
            </QueryClientProvider>
        );
    });
});
