import { defineMessages } from "react-intl";

const emptyStateMessages = defineMessages({
    title: {
        id: "emptyStateMessages.title",
        defaultMessage: "You don't have any Team Spaces, yet!",
        description: "Empty State > Title",
    },
    body: {
        id: "emptyStateMessages.body",
        defaultMessage: "A final empty state for Team Spaces will be arriving in a future user story.",
        description: "Empty State > Body Text",
    },
});

export default emptyStateMessages;
