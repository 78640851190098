import { defineMessages } from "react-intl";

const searchFieldMessages = defineMessages({
    add: {
        id: "searchFieldMessages.add",
        defaultMessage: "Add",
        description: "Add label",
    },
    ownersSearchPlaceholder: {
        id: "ownersTabMessages.ownersSearchPlaceholder",
        defaultMessage: "Find someone to add",
        description: "Owners Search placeholder",
    },
});

export default searchFieldMessages;
