import React, { useCallback, useEffect, useRef, useState} from 'react';
import {matchPath, useHistory, useLocation} from 'react-router';
import PropTypes from 'prop-types';
import {defineMessages,  useIntl} from 'react-intl';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import {headerHeight, modules, osColour, isWidthDown, withWidth, theme} from 'omse-components';
import {closeMenu} from '../../modules/menu/actions';
import {getNotificationStatus} from "../../modules/notifications/actions";
import routePaths, {accountRoutes, getLocation, routes} from '../../util/routes';
import {findTab, findTabLabel, getTabRoute} from '../../util/menu';
import {navActionsContainer, navNowrap, navHiddenTabsApp, navHeader, navMinimalHeader} from '../../util/responsive-navigation';
import {ESCAPE} from '../../constants/keys';
import {TEN_MINUTES_IN_MS} from '../../constants/constants';
import featureCheck from '../../util/featureCheck';
import {VN} from '../../../shared/features';
import { getUserDetailsDefaultContext } from '../../util/organisation';
import {hasShowVernacularNamesPermission} from "../../util/permissions";
import {holdingPageCheck} from "../../../shared/holdingPageCheck";
import UserSiteNotices from '../UserSiteNotices';
import DataHubLogo from './DataHubLogo';
import Notifications from '../Notifications';
import UserDetails from "./UserDetails";
import ConditionalWrapWithClass from "./ConditionalWrapWithClass";
import HeaderNavButtons from "./HeaderNavButtons";
import PriorityNotification from '../PriorityNotification';
import MenuIcon from "../../pages/appContent/responsiveNavigation/MenuIcon";

const {getUser} = modules.actions.user;

const messages = defineMessages({
    navLabel: {
        defaultMessage: 'Primary',
        description: 'aria-label for the primary navigation',
        id: 'Header.navLabel'
    },
});

export const useStyles = createUseStyles((theme) => ({
    '@global': {
        ':target': {
            scrollMarginTop: `calc(${headerHeight}px + ${theme.spacing(2)})`
        }
    },
    headerContainer: {
        position: 'sticky',
        width: '100%',
        top: 0,
        flex: '0 0 auto',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 5,
        '@media print': {
            position: 'static'
        }
    },
    header: {
        boxSizing: 'border-box',
        flex: '0 0 auto',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        boxShadow: '-2px 2px 3px 0 rgba(0, 0, 0, 0.2)',
        zIndex: 5,
        borderTop: `solid #e6e5e6 1px`,
        minHeight: headerHeight,
        background: osColour.neutral.white,
    },
    skipToMainContent: {
        position: 'absolute',
        left: '-10000px',
        top: 'auto',            
        overflow: 'hidden',
        '&:focus': {
            padding: '14px',
            position: 'fixed',
            borderRadius: '6px',   
            color: osColour.primary.darkerBerry,
            background: osColour.neutral.white,
            top: 8,
            left: 8,
            zIndex: 999
        }
    },
    vnMenu: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end'
    },
    vnAccountButtons: {
        alignSelf: 'flex-end'
    }
}));

const Header = ({ headerRef }) => {
    const classes = useStyles({theme})
    const history = useHistory()
    const location = useLocation()
    const intl = useIntl()
    const width = withWidth()
    const dispatch = useDispatch()

    const [currentTab, setCurrentTab] = useState(null)
    const [notifications, setNotifications] = useState(false)
    const [priorityNotification, setPriorityNotification] = useState({ title: ''})
    const [priorityOpen, setPriorityOpen] = useState(false)
    const [useOverflow, setUseOverflow] = useState(false)
    const [vnMenu, setVnMenu] = useState(false)

    const userDetails = useSelector(state => state.user.current.result);
    const userError = useSelector(state => state.user.current.error);
    const loading = useSelector(state => state.user.current.loading);
    const config = useSelector(state => state.config.current.result);
    const notificationStatus = useSelector(state => state.notifications.status.result)

    const priorityNotif = useSelector(state => {
        if (state.notifications.status.result?.messages) {
            return state.notifications.status.result.messages.priority;
        }
        return null;
    });
    
    const notificationCount = useSelector(state => {
        if (state.notifications.status.result?.messages) {
            return state.notifications.status.result.messages.count;
        }
        return 0;
    });

    const menuOpen = useSelector(state => state.menu.nav || state.menu.account);

    const logoRef = useRef()
    const lastTabRef = useRef()
    const userDetailsRef = useRef()

    const notificationsButtonRef = userDetailsRef?.current?.notificationButtonRef

    const keydown = useCallback((event) => {
        // esc key close
        if (event.key === ESCAPE) {
            if (notifications) {
                setNotifications(false)
            } else if (menuOpen) {
                dispatch(closeMenu())
            }
        }
    }, [dispatch, menuOpen, notifications])

    const displayVNNavBar = useCallback(() => {
        const checkRoute = routes.find(route => location.pathname.toLowerCase() === routePaths.vernacularNamesReporting);
        const checkAccess = userDetails && hasShowVernacularNamesPermission(userDetails);
        const checkFeatureFlag = featureCheck(VN, config);
        return Boolean(checkRoute && checkFeatureFlag && checkAccess);
    }, [config, location.pathname, userDetails])

    const updateUserActionsMode = useCallback(() => {
        if (userDetails && isWidthDown('md', width)) {
            let rect1;
            if (isWidthDown('sm', width)) {
                if (logoRef?.current) {
                    rect1 = logoRef.current.getBoundingClientRect();
                }
            } else if (lastTabRef?.current) {
                rect1 = lastTabRef.current.getBoundingClientRect();
            }
            let rect2;
            if (userDetailsRef?.current?.userActionsRef?.getBoundingClientRect) {
                rect2 = userDetailsRef.current.userActionsRef.getBoundingClientRect();
            }
            if (rect1 && rect2) {
                if (!useOverflow) {
                    const spacer = 10;
                    if (rect1.right > (rect2.left - spacer)) {
                        setUseOverflow(true)
                    }
                } else {
                    const allocation = 190;
                    if (rect1.right < (rect2.left - allocation)) {
                        setUseOverflow(false)
                    }
                }
            }
        }
    },[useOverflow, userDetails, width])

    const initialiseTabs = useCallback((userDetails, location) => {
        const route = routes.find(route => location.pathname.toLowerCase().indexOf(route.path.toLowerCase()) === 0);
        const isAccountRoute = accountRoutes.find(r => r.path === route.path || r.path === location.pathname.toLowerCase() || route.path === routePaths.account)
        setVnMenu(displayVNNavBar())

        if (isAccountRoute) {
            setCurrentTab(false)
        } else if (route && !vnMenu) {
            const tabLabel = findTabLabel(userDetails, route, config)
            setCurrentTab(tabLabel)
        }
    }, [config, displayVNNavBar, vnMenu])

    useEffect(() => {
        dispatch(getUser());
    }, []);

    useEffect(() => {
        if (location) {
            initialiseTabs(userDetails, location);
        }
    }, [userDetails, location, initialiseTabs]);

    useEffect(() => {
        setVnMenu(displayVNNavBar());
    }, [displayVNNavBar]);

    useEffect(() => {
        document.addEventListener('keydown', keydown);
        window.addEventListener('resize', updateUserActionsMode);
        return () => {
            document.removeEventListener('keydown', keydown);
            window.removeEventListener('resize', updateUserActionsMode);
        };
    }, [keydown, updateUserActionsMode]);

    useEffect(() => {
        if (userDetails && config) {
            if (!notificationStatus) {
                dispatch(getNotificationStatus());
            }
            setVnMenu(displayVNNavBar());
            initialiseTabs(userDetails, location);
        }
        if (!notifications && priorityNotif && priorityNotif.title !== '') {
            let update = false;
            if (priorityNotif.id !== priorityNotification.id) {
                update = true;
            }
            if (update) {
                setPriorityOpen(true);
                setPriorityNotification(priorityNotif);
            }
        }
    }, [priorityNotif, location, userDetails, config, notifications, notificationStatus, displayVNNavBar, initialiseTabs, dispatch, priorityNotification.id]);

    useEffect(() => {
        const notificationInterval = setInterval(() => {dispatch(getNotificationStatus())}, TEN_MINUTES_IN_MS);
        return () => clearInterval(notificationInterval);
    }, [dispatch]);

    const getDisplaySettings = (fullHeader) => {
        const holdingPageDisplayed = holdingPageCheck(userDetails, config?.holdingPageUserPlans)
        const displayVNNavBarResult = displayVNNavBar()
        const conditionalVNMenuClass = displayVNNavBarResult ? classes.vnMenu : undefined;
        const conditionalVNButtonsClass = displayVNNavBarResult ? classes.vnAccountButtons : undefined;
        let navButtonsDisplay = 'None'
        if (displayVNNavBarResult) {
            navButtonsDisplay = 'VernacularNames';
        }
        if (!holdingPageDisplayed && !displayVNNavBarResult && fullHeader) {
            navButtonsDisplay = 'FullHeader';
        }
        return {
            displayVNNavBarResult,
            conditionalVNMenuClass,
            conditionalVNButtonsClass,
            navButtonsDisplay,
            holdingPageDisplayed
        };
    }

    const changeTab = (event, tabLabel) => {
        const defaultContextUserDetails = getUserDetailsDefaultContext(userDetails);
        const tab = findTab(defaultContextUserDetails, tabLabel, config);
        const tabRoute = getTabRoute(tab, defaultContextUserDetails, config);
        const newLocation = getLocation(tabRoute.path, location);
        setVnMenu(displayVNNavBar())
        history.push(newLocation);
    }
    
    const hideNotifications = useCallback(() => {
        setNotifications(false);
        setPriorityOpen(false);
    }, []);

    const toggleNotifications = useCallback(() => {
        setNotifications((prevState) => !prevState);
    }, []);

    const showNotifications = () => {
        setNotifications(true)
        setPriorityOpen(false)
    }

    const hidePriorityNotification = () => {
        setPriorityOpen(false)
    }

    const route = routes.find(route => {
        const match = matchPath(location.pathname, {
            path: route.path,
            exact: true
        });
        return match;
    });
    const fullHeader = !(route?.simpleHeader);
    const noLinkHeader = route?.noLinkHeader;

    const navLabel = intl.formatMessage(messages.navLabel);
    let display = getDisplaySettings(fullHeader);

    const navActionContainerClasses = {[navActionsContainer]: true};
    const headerClass = {[classes.header]: true};
    if (vnMenu) {
        navActionContainerClasses[navNowrap] = true;
        headerClass[navHiddenTabsApp] = true;
    }
    if (!fullHeader || display.holdingPageDisplayed) {
        headerClass[navMinimalHeader] = true;
    }
    return (
        <div className={classes.headerContainer} ref={headerRef}>
            <UserSiteNotices/>
            <header className={classNames(headerClass, navHeader)}>
                {/*NB: Vernacular Names page does not have access to the full Data Hub menu*/}
                {(!vnMenu && !display.holdingPageDisplayed) && <MenuIcon/>}
                <DataHubLogo logoRef={logoRef} vnMenu={display.displayVNNavBarResult} isLink={!noLinkHeader}
                             holdingPageDisplayed={display.holdingPageDisplayed}/>

                <div className={classNames(navActionContainerClasses)}>
                    <a href="#main-content" className={classes.skipToMainContent}>Skip to Main Content</a>

                    {(display.displayVNNavBarResult || fullHeader) && (
                        <ConditionalWrapWithClass className={display.conditionalVNMenuClass}>
                            <HeaderNavButtons display={display.navButtonsDisplay}
                                              navLabel={navLabel}
                                              currentTab={currentTab}
                                              changeTab={changeTab}
                                              updateUserActionsMode={updateUserActionsMode}
                                              ref={lastTabRef}
                                              userDetails={userDetails}
                            />
                            <UserDetails useOverflow={useOverflow}
                                         hideNotifications={hideNotifications}
                                         toggleNotifications={toggleNotifications}
                                         userDetails={userDetails}
                                         notificationCount={notificationCount}
                                         loading={loading}
                                         ref={userDetailsRef}
                                         holdingPageDisplayed={display.holdingPageDisplayed}
                                         isVNHeader={vnMenu}
                            />

                            {
                                !display.holdingPageDisplayed &&
                                <ConditionalWrapWithClass className={display.conditionalVNButtonsClass}>
                                    <Notifications open={notifications}
                                                anchorEl={notificationsButtonRef}
                                                handleClickAway={hideNotifications}/>

                                    <PriorityNotification handleClick={showNotifications}
                                                        open={priorityOpen}
                                                        anchorEl={notificationsButtonRef}
                                                        handleClickAway={hidePriorityNotification}
                                                        item={priorityNotification}/>
                                </ConditionalWrapWithClass>
                            }

                        </ConditionalWrapWithClass>
                    )}
                </div>
            </header>
        </div>
    )
}

Header.propTypes = {
    headerRef: PropTypes.object,
}

export default Header
