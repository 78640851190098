import {useForm} from "@tanstack/react-form";
import {
    Checkbox, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    TextField
} from "@mui/material";
import classNames from "classnames";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import React from "react";
import {createUseStyles} from "react-jss";
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {ExternalLink} from "omse-components";
import {privacyPolicyAddress} from "../../../../util/routes";

const messages = defineMessages({
    title: {
        id: 'SampleDataForm.title',
        defaultMessage: 'Sample data download',
        description: 'Title for OS SampleData downloads form'
    },
    description: {
        id: 'SampleDataForm.description',
        defaultMessage: 'To download our sample data, please provide a few details below:',
        description: 'Description for OS SampleData downloads form'
    },
    firstNameTitle:{
        id: 'SampleDataForm.firstNameTitle',
        defaultMessage: 'First name',
        description: 'Field Title for First name'
    },
    firstNameRequired:{
        id: 'SampleDataForm.firstNameRequired',
        defaultMessage: "Please enter your first name",
        description: 'First name required message'
    },
    lastNameTitle:{
        id: 'SampleDataForm.lastNameTitle',
        defaultMessage: 'Last name',
        description: 'Field Title for Last name'
    },
    lastNameRequired:{
        id: 'SampleDataForm.lastNameRequired',
        defaultMessage: 'Please enter your last name',
        description: 'Last name required message'
    },
    jobTitleTitle:{
        id: 'SampleDataForm.JobTitleTitle',
        defaultMessage: 'Job title',
        description: 'Field Title for Job title'
    },
    jobTitleRequired:{
        id: 'SampleDataForm.JobTitleRequired',
        defaultMessage: 'Please enter your job title',
        description: 'Job title required message'
    },
    emailAddressTitle:{
        id: 'SampleDataForm.emailAddressTitle',
        defaultMessage: 'Email address',
        description: 'Field Title for Email'
    },
    emailAddressRequired:{
        id: 'SampleDataForm.emailAddressRequired',
        defaultMessage: 'Please enter your email address',
        description: 'Email required message'
    },
    emailAddressInvalid:{
        id: 'SampleDataForm.emailAddressInvalid',
        defaultMessage: 'Please enter a valid email address',
        description: 'Email invalid message'
    },
    phoneNumberTitle:{
        id: 'SampleDataForm.phoneNumberTitle',
        defaultMessage: 'Phone number',
        description: 'Field Title for Phone number'
    },
    phoneNumberRequired:{
        id: 'SampleDataForm.phoneNumberRequired',
        defaultMessage: 'Please enter your phone number',
        description: 'Phone number required message'
    },
    phoneNumberInvalid:{
        id: 'SampleDataForm.phoneNumberInvalid',
        defaultMessage: 'Please enter a valid phone number',
        description: 'Phone number invalid message'
    },
    organisationTitle:{
        id: 'SampleDataForm.organisationTitle',
        defaultMessage: 'Organisation',
        description: 'Field Title for Organisation'
    },
    organisationRequired:{
        id: 'SampleDataForm.organisationRequired',
        defaultMessage: 'Please enter your organisation',
        description: 'Organisation required message'
    },
    organisationTypeTitle:{
        id: 'SampleDataForm.organisationTypeTitle',
        defaultMessage: 'Organisation type',
        description: 'Field Title for Organisation type'
    },
    organisationTypeRequired:{
        id: 'SampleDataForm.organisationTypeRequired',
        defaultMessage: 'Please enter your organisation type',
        description: 'Organisation type required message'
    },
    publicSectorOrganisation:{
        id: 'SampleDataForm.publicSectorOrganisation',
        defaultMessage: 'Public sector organisation',
        description: 'Organisation type Public sector organisation'
    },
    corporationBusiness:{
        id: 'SampleDataForm.corporationBusiness',
        defaultMessage: 'Corporation/business',
        description: 'Organisation type Corporation/business'
    },
    soleTrader:{
        id: 'SampleDataForm.soleTrader',
        defaultMessage: 'Sole trader',
        description: 'Organisation type Sole trader'
    },
    eduction:{
        id: 'SampleDataForm.eduction',
        defaultMessage: 'Eduction',
        description: 'Organisation type Eduction'
    },
    privateNonCommercial :{
        id: 'SampleDataForm.privateNonCommercial',
        defaultMessage: 'Private individual (non-commercial use)',
        description: 'Organisation type Private individual (non-commercial use)'
    },
    other :{
        id: 'SampleDataForm.other',
        defaultMessage: 'Other',
        description: 'Organisation type Other'
    },
    acceptTermsMessage:{
        id: 'SampleDataForm.acceptedTermsMessage',
        defaultMessage: 'I have read and accepted the <a>terms and conditions</a>',
        description: 'Accept Terms message'
    },
    acceptTermsRequiredMessage:{
        id: 'SampleDataForm.acceptTermsRequiredMessage',
        defaultMessage: 'To use our sample data, please accept the terms. ',
        description: 'Accept Terms required error message'
    },
    termsMessage:{
        id: 'SampleDataForm.termsMessage',
        defaultMessage: 'By downloading the sample data, you agree to our sample data terms and conditions.',
        description: 'Terms message'
    },
    privacyPolicyText:{
        id: 'SampleDataForm.privacyPolicyText',
        defaultMessage: 'All of the information that you provide will be used in accordance with our <a>privacy policy</a>.',
        description: 'Terms link text'
    },
    privacyPolicyLinkText: {
        id: 'SampleDataForm.privacyPolicyLinkText',
        defaultMessage: 'privacy policy',
        description: 'Terms link text'
    },
    submitButtonText: {
        id: 'SampleDataForm.submitButtonText',
        defaultMessage: 'Get sample data',
        description: 'Submit button text'
    },
    cancelButtonText: {
        id: 'SampleDataForm.cancelButtonText',
        defaultMessage: 'Cancel',
        description: 'Cancel button text'
    }
});

const useStyles = createUseStyles(theme => ({
    formContentContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1.5)
    },
    checkboxContainer: {
        display: 'flex',
        gap: theme.spacing(1)
    },
    errorField: {
        backgroundColor: "#faeef1"
    },
    errorText: {
        color: "#c63d54",
        fontWeight: 400,
        fontSize: "0.875rem"
    },
    formPadding: {
        padding: theme.spacing(1)
    }
}));

const EMAIL_ADDRESS_REGEXP = /^[^@]+@[^@]+\.[^@]+$/;
const PHONE_NUMBER_REGEXP = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

export function SampleDataForm({setShowForm, setFormSubmitted}) {
    const classes = useStyles();
    const form = useForm({
        defaultValues: {
            firstName: '',
            lastName: '',
            jobTitle: '',
            emailAddress: '',
            phoneNumber: '',
            organisation: '',
            organisationType: '',
            acceptedTerms: false
        },
        onSubmit: ({value}) => {
            // todo - do something with the form values
            console.log(value);
            setFormSubmitted(true);
            setShowForm(false);
        }
    });
    const intl = useIntl();

    const termsLink = "https://www.ordnancesurvey.co.uk/customers/public-sector/public-sector-licensing/licensing-terms";

    const submitDisabled = form.useStore((state) => {
        // submit disabled if form is pristine or there are validation errors
        if (state.isPristine) return true;
        if (!state.canSubmit) return true;

        // submit disabled if any of the values are falsy (empty strings, etc.)
        // (needed as canSubmit is true if a field has not yet been changed to run the field's validator)
        const values = state.values;
        for (const value in values) {
            if (!values[value]) return true;
        }

        return false;
    });
    
    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                form.handleSubmit();
            }}>
            <DialogTitle><FormattedMessage {...messages.title} /></DialogTitle>
            <DialogContent className={classes.formContentContainer}>
                <DialogContentText>
                    <FormattedMessage {...messages.description} />
                </DialogContentText>

                <form.Field
                    name="firstName"
                    validators={{
                        onChange: ({value}) => {
                            if (value.length < 1) return intl.formatMessage(messages.firstNameRequired);
                        }
                    }}
                    children={field => (
                        <TextField
                            id={field.name}
                            label={intl.formatMessage(messages.firstNameTitle)}
                            defaultValue={field.state.value}
                            onChange={(e) => field.handleChange(e.target.value)}
                            onBlur={field.handleBlur}
                            error={field.state.meta.errors.length > 0}
                            helperText={field.state.meta.errors}/>
                    )}/>

                <form.Field
                    name="lastName"
                    validators={{
                        onChange: ({value}) => {
                            if (value.length < 1) return intl.formatMessage(messages.lastNameRequired);
                        }
                    }}
                    children={field => (
                        <TextField
                            id={field.name}
                            label={intl.formatMessage(messages.lastNameTitle)}
                            defaultValue={field.state.value}
                            onChange={(e) => field.handleChange(e.target.value)}
                            onBlur={field.handleBlur}
                            error={field.state.meta.errors.length > 0}
                            helperText={field.state.meta.errors}/>
                    )}/>

                <form.Field
                    name="jobTitle"
                    validators={{
                        onChange: ({value}) => {
                            if (value.length < 1) return intl.formatMessage(messages.jobTitleRequired);
                        }
                    }}
                    children={field => (
                        <TextField
                            id={field.name}
                            label={intl.formatMessage(messages.jobTitleTitle)}
                            defaultValue={field.state.value}
                            onChange={(e) => field.handleChange(e.target.value)}
                            onBlur={field.handleBlur}
                            error={field.state.meta.errors.length > 0}
                            helperText={field.state.meta.errors}/>
                    )}/>

                <form.Field
                    name="emailAddress"
                    validators={{
                        onChange: ({value}) => {
                            if (value.length < 1) return intl.formatMessage(messages.emailAddressRequired);
                            if (!EMAIL_ADDRESS_REGEXP.test(value)) return intl.formatMessage(messages.emailAddressInvalid);
                        }
                    }}
                    children={field => (
                        <TextField
                            id={field.name}
                            label={intl.formatMessage(messages.emailAddressTitle)}
                            defaultValue={field.state.value}
                            onChange={(e) => field.handleChange(e.target.value)}
                            onBlur={field.handleBlur}
                            error={field.state.meta.errors.length > 0}
                            helperText={field.state.meta.errors.join(', ')}/>
                    )}/>

                <form.Field
                    name="phoneNumber"
                    validators={{
                        onChange: ({value}) => {
                            if (value.length < 1) return intl.formatMessage(messages.phoneNumberRequired);
                            if (!PHONE_NUMBER_REGEXP.test(value)) return intl.formatMessage(messages.phoneNumberInvalid);
                        }
                    }}
                    children={field => (
                        <TextField
                            id={field.name}
                            label={intl.formatMessage(messages.phoneNumberTitle)}
                            defaultValue={field.state.value}
                            onChange={(e) => field.handleChange(e.target.value)}
                            onBlur={field.handleBlur}
                            error={field.state.meta.errors.length > 0}
                            helperText={field.state.meta.errors}/>
                    )}/>

                <form.Field
                    name="organisation"
                    validators={{
                        onChange: ({value}) => {
                            if (value.length < 1) return intl.formatMessage(messages.organisationRequired);
                        }
                    }}
                    children={field => (
                        <TextField
                            id={field.name}
                            label={intl.formatMessage(messages.organisationTitle)}
                            defaultValue={field.state.value}
                            onChange={(e) => field.handleChange(e.target.value)}
                            onBlur={field.handleBlur}
                            error={field.state.meta.errors.length > 0}
                            helperText={field.state.meta.errors}/>
                    )}/>

                <form.Field
                    name="organisationType"
                    validators={{
                        onBlur: ({value}) => {
                            if (value === "") return intl.formatMessage(messages.organisationTypeRequired);
                        }
                    }}
                    children={field => (
                        <TextField
                            select
                            id={field.name}
                            label={intl.formatMessage(messages.organisationTypeTitle)}
                            defaultValue={intl.formatMessage(messages.organisationTypeTitle)}
                            value={field.state.value}
                            onChange={(e) => field.handleChange(e.target.value)}
                            onBlur={field.handleBlur}
                            error={field.state.meta.errors.length > 0}
                            helperText={field.state.meta.errors}>
                            <MenuItem value="Public sector organisation"><FormattedMessage {...messages.publicSectorOrganisation}/></MenuItem>
                            <MenuItem value="Corporation/business"><FormattedMessage {...messages.corporationBusiness}/></MenuItem>
                            <MenuItem value="Sole trader"><FormattedMessage {...messages.soleTrader}/></MenuItem>
                            <MenuItem value="Education"><FormattedMessage {...messages.eduction}/></MenuItem>
                            <MenuItem value="Private individual (non-commercial use)"><FormattedMessage {...messages.privateNonCommercial}/></MenuItem>
                            <MenuItem value="Other"><FormattedMessage {...messages.other}/></MenuItem>
                        </TextField>
                    )}/>

                <form.Field
                    validators={{
                        onBlur: ({value}) => {
                            if (!value) return intl.formatMessage(messages.acceptTermsRequiredMessage);
                        }
                    }}
                    name="acceptedTerms"
                    children={field => (
                        <div className={classNames(field.state.meta.errors.length > 0 && classes.errorField, classes.formPadding)}>
                            <div className={classNames(classes.checkboxContainer, classes.formContentItem)}>
                                <Checkbox
                                    checked={field.state.value}
                                    onChange={(e) => field.handleChange(e.target.checked)}
                                    onBlur={field.handleBlur}
                                />
                                <Typography>
                                    <FormattedMessage {...messages.acceptTermsMessage} values={{
                                        a: chunks => (
                                            <ExternalLink
                                                type="generic"
                                                href={termsLink}
                                                message={chunks}
                                            />
                                        )
                                    }}/>
                                </Typography>
                            </div>
                            {field.state.meta.errors.length > 0 && <Typography className={classes.errorText}>
                                {field.state.meta.errors}
                            </Typography>}
                        </div>
                    )}/>

                <DialogContentText>
                    <FormattedMessage {...messages.termsMessage} />
                </DialogContentText>
                <DialogContentText>
                    <FormattedMessage {...messages.privacyPolicyText} values={{
                        a: msg => <a href={privacyPolicyAddress} target="_blank" rel="noopener noreferrer">{msg}</a>
                    }}/>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => setShowForm(false)}>
                    <FormattedMessage {...messages.cancelButtonText} />
                </Button>
                <Button variant="contained" disabled={submitDisabled} type="submit">
                    <FormattedMessage {...messages.submitButtonText} />
                </Button>
            </DialogActions>
        </form>
    )
}

SampleDataForm.propTypes = {
    setShowForm: PropTypes.func,
    setFormSubmitted: PropTypes.func
}