const TEAM_PERMISSION_GROUPS = {
    TeamOwner: "teamOwner",
    TeamViewer: "teamViewer"
}

const API_PROJECT_PERMISSION_GROUPS = {
    ApiProjectEditor: "apiProjectEditor",
    ApiProjectViewer: "apiProjectViewer"
}

const TEAM_SPACES_API_ERROR = "Unexpected Team Spaces API response";

exports.TEAM_PERMISSION_GROUPS = TEAM_PERMISSION_GROUPS;
exports.API_PROJECT_PERMISSION_GROUPS = API_PROJECT_PERMISSION_GROUPS;
exports.TEAM_SPACES_API_ERROR = TEAM_SPACES_API_ERROR;
