import { defineMessages } from "react-intl";

const teamSpaceAccessMessages = defineMessages({
    ownersTab: {
        id: "teamSpaceAccessMessages.ownersTab",
        defaultMessage: "Owners",
        description: "Owners tab",
    },
    membersTab: {
        id: "teamSpaceAccessMessages.membersTab",
        defaultMessage: "Members",
        description: "Members tab",
    },
    membersTabLockedAria: {
        id: "teamSpaceAccessMessages.membersTabLockedAria",
        defaultMessage: "Currently Locked",
        description: "Members tab - Locked Aria-Label",
    },
    membersTabUnlockedAria: {
        id: "teamSpaceAccessMessages.membersTabUnlockedAria",
        defaultMessage: "Currently Open / Unlocked",
        description: "Members tab - Open Aria-label",
    },
});

export default teamSpaceAccessMessages;
