import React from 'react';
import {defineMessages, FormattedMessage} from "react-intl";
import {createUseStyles} from 'react-jss';
import {border1, contentPadding, osColour} from 'omse-components';
import Typography from "@mui/material/Typography";
import { ReactComponent as Error404Icon} from "./error404/404.svg"
import {ReactComponent as RecipeSVG} from "../components/icons/recipe-large.svg";
import {useHistory, useLocation} from 'react-router';
import Button from '@mui/material/Button';
import routes, {getLocation, matchRoute} from '../util/routes';
import NotLoggedIn from "./appContent/NotLoggedIn";
import {useSelector} from 'react-redux';

const messages = defineMessages({
    notFound: {
        id: 'Error404.notFound',
        defaultMessage: 'Page not found',
        description: ' first line of 404 message'
    },
    notFoundSubtitle: {
        id: 'Error404.notFoundSubtitle',
        defaultMessage: 'We can\'t seem to find the page you are looking for.',
        description: 'second line of 404 message'
    },
    redirectButtonTextHome: {
        id: 'redirectButton.redirectButtonTextHome',
        defaultMessage: 'Go back home',
        description: 'Text for button to go back home'
    },
    redirectButtonTextProjects: {
        id: 'redirectButton.redirectButtonTextProjects',
        defaultMessage: 'View your projects',
        description: 'Text for button to view your projects'
    },
    redirectButtonTextDownloads: {
        id: 'redirectButton.redirectButtonTextDownloads',
        defaultMessage: 'View all downloads',
        description: 'Text for button to view downloads'
    },
    notAvailable: {
        id: 'Error404Recipe.notAvailable',
        defaultMessage: 'Recipe not available',
        description: 'Recipe not available title'
    },
    notAvailableSubtitle: {
        id: 'ErrorRecipeNotAvailable.subtitle',
        defaultMessage: 'This recipe is not available within your organisation.',
        description: 'Recipe not available Subtitle'
    },
    redirectButtonRecipeLibrary: {
        id: 'redirectButton.redirectButtonRecipeLibrary',
        defaultMessage: 'View your recipes',
        description: 'Text for button to go back to the Recipe Library'
    }
});

const useStyles = createUseStyles(theme => {
    return {
        root: {
            paddingTop: contentPadding.top - contentPadding.backLink,
            paddingRight: contentPadding.right,
            paddingBottom: contentPadding.bottom,
            paddingLeft: contentPadding.left,
            borderBottom: border1,
            [theme.breakpoints.down('sm')]: {
                padding: contentPadding.mobile
            },
        },
        container: {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
            backgroundColor: osColour.primary.lightestBerry,
            height: '100%'
        },
        smallThemeSpacing: {
            marginBottom: theme.spacing(1),
        },
        largerThemeSpacing: {
            marginBottom: theme.spacing(3),
        },
        error404Icon: {
            maxWidth: '231px',
            paddingBottom: theme.spacing(5),
        },
        error404Text: {
            marginTop: theme.spacing(3),
        },
    };
});

export default function Error404() {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const userDetails = useSelector(state => state.user.current.result);

    let pathName = history.location.pathname;
    const match = matchRoute(pathName);

    // If a user is looking at a URL that does not match any of our routes (or is hidden behind a feature flag)
    // then we should replace the URL with our fixed 404 location, as the invalid path is not a useful thing to
    // keep on the page.
    if ((!match || match.featureFlag) && pathName !== '/error404') {
        history.replace({
            pathname: '/error404'
        });
    }

    // If the path is a protected app path then we can get to here if the user is not logged in. Note that we don't
    // alter the URL, as we want to come back to the same page after the user logs in.
    if(match && !match.public && !userDetails) {
        return <NotLoggedIn/>;
    }

        let icon;
    if (pathName === routes.error404Recipe) {
        icon = <RecipeSVG className={classes.error404Icon} />
    } else {
        icon = <Error404Icon className={classes.error404Icon} />
    }

    let message;
    if (pathName === routes.error404Recipe) {
        message = <FormattedMessage {...messages.notAvailable} />
    } else {
        message = <FormattedMessage {...messages.notFound} />
    }

    let subtitle;
    if (pathName === routes.error404Recipe) {
        subtitle = <FormattedMessage {...messages.notAvailableSubtitle} />
    } else {
        subtitle = <FormattedMessage {...messages.notFoundSubtitle} />
    }


    function renderButton(label, path) {
        const newLocation = getLocation(path, location);

        return (
            <Button color='primary'
                    variant='contained'
                    onClick={() => {history.push(newLocation)}}
                    label={label}
            >
                <Typography>
                    <FormattedMessage {...label} />
                </Typography>
            </Button>
        )
    }

    let redirectButton;

    let goHomeButton = renderButton({...messages.redirectButtonTextHome}, routes.landing);

    if (pathName === routes.error404Projects) {
        redirectButton =  renderButton({...messages.redirectButtonTextProjects}, routes.projects);
    } else if (pathName === routes.error404Downloads) {
        redirectButton = renderButton({...messages.redirectButtonTextDownloads}, routes.openDataDownloads);
    } else if (pathName === routes.error404Sample) {
        redirectButton = renderButton({...messages.redirectButtonTextDownloads}, routes.sampleDataDownloads);
    } else if (pathName === routes.error404Premium) {
        redirectButton = renderButton({...messages.redirectButtonTextDownloads}, routes.premiumDownloads);
    } else if (pathName === routes.error404Recipe) {
        redirectButton = renderButton({...messages.redirectButtonRecipeLibrary}, routes.recipeLibrary);
    }

    return(
            <div className={classes.container}>
                {icon}

                <Typography variant='h2' className={classes.smallThemeSpacing}>
                    {message}
                </Typography>

                <Typography variant='body1' className={classes.largerThemeSpacing}>
                    {subtitle}
                </Typography>
                <div className={classes.smallThemeSpacing}>
                    {redirectButton}
                </div>
                {goHomeButton}
            </div>

    )
}
