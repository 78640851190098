import { useSelector} from 'react-redux';
import {defineMessages, useIntl} from 'react-intl';
import {fromISOString, toISODate} from "../../../util/dateUtils";

const messages = defineMessages({
    areaOptionAllOfBritainOrPredefinedArea: {
        id: 'UseDataPackageNgdOptions.areaOptionAllOfBritainOrPredefinedArea',
        defaultMessage: 'All of Britain or Predefined Area',
        description: 'areaOptionAllOfBritainOrPredefinedArea label'
    },
    areaOptionCustomAOI: {
        id: 'UseDataPackageNgdOptions.areaOptionCustomAOI',
        defaultMessage: 'Draw a polygon/upload a file/use an OS polygon',
        description: 'areaOptionCustomAOI label'
    },
    '27700': {
        id: 'useDataPackageNgdOptions.27700',
        defaultMessage: 'British National Grid (BNG: EPSG: 27700)',
        description: 'Label for CRS www.opengis.net/def/crs/EPSG/0/27700'
    },
    '3857': {
        id: 'useDataPackageNgdOptions.3857',
        defaultMessage: 'Web Mercator (EPSG: 3857)',
        description: 'Label for CRS www.opengis.net/def/crs/EPSG/0/3857'
    },
    '4326': {
        id: 'useDataPackageNgdOptions.4326',
        defaultMessage: 'World Geodetic System (WGS84: EPSG: 4326)',
        description: 'Label for CRS www.opengis.net/def/crs/EPSG/0/4326'
    },
    'CRS84': {
        id: 'useDataPackageNgdOptions.CRS84',
        defaultMessage: 'World Geodetic System (CRS84: longitude / latitude)',
        description: 'Label for CRS www.opengis.net/def/crs/OGC/1.3/CRS84'
    },
    '7405': {
        id: 'useDataPackageNgdOptions.7405',
        defaultMessage: 'British National Grid + ODN Height (EPSG: 7405)',
        description: 'Label for CRS www.opengis.net/def/crs/EPSG/0/7405'
    },
    '4258': {
        id: 'useDataPackageNgdOptions.4258',
        defaultMessage: 'European Terrestrial Reference System (ETRS89: EPSG: 4258)',
        description: 'Label for CRS www.opengis.net/def/crs/EPSG/0/4258'
    },
    defaultMixCrs: {
        id: 'useDataPackageNgdOptions.defaultMixCrs',
        defaultMessage: 'Default mix',
        description: 'Label for default CRS mix, should indicate that the supplied CRS is not altered'
    }
});

const SUPPORTED_CRS = ["27700","7405", "4326", "4258"];
/**
 * Returns the different options that should be used to populate the NGD Add Data Package page components used to
 * configure the data package.
 *
 * @returns {{areaRadioOptions: [], formatOptions: [], updatesOptions: [], initialSupplyDateOptions: []}}
 */
function useDataPackageNgdOptions() {
    const intl = useIntl();
    const format = useSelector(state => state.dataPackages.draftOrder.format);
    const area = useSelector(state => state.dataPackages.draftOrder.area);
    const updates = useSelector(state => state.dataPackages.draftOrder.updates);
    const initialSupplyDate = useSelector(state => state.dataPackages.draftOrder.initialSupplyDataDate);
    const hasFilters = useSelector(state => state.dataPackages.draftOrder.hasFilters);
    const datasetOptions = useSelector(state => state.recipes.datasetOptions.result)

    const crsOptions = useCrsOptions({intl, datasetOptions});

    const areaRadioOptions = useAreaRadioOptions({updates});
    const formatOptions = useFormatOptions({updates});

    const updatesOptions = useUpdateOptions({area, format, initialSupplyDate, hasFilters});
    const initialSupplyDateOptions = useInitialSupplyDateOptions(intl, updates);
    return {areaRadioOptions, crsOptions, formatOptions, updatesOptions, initialSupplyDateOptions};
}

function useIsAnnualAllowed() {
    const ngdStartDate = useSelector(state => state.config.current.result.ngdStartDate);
    const parsedDate = fromISOString(ngdStartDate);
    const currentDate = new Date();
    return currentDate.getFullYear() > parsedDate.getFullYear();
}

function useAreaRadioOptions({updates}) {
    const areaOptions = [
        {id: 'GB', label: messages.areaOptionAllOfBritainOrPredefinedArea},
        {id: 'Polygon', label: messages.areaOptionCustomAOI}
    ];
    if (updates === 'DAILY' || updates === 'MONTHLY_COU') {
        areaOptions[1].disabled = true;
    }

    return areaOptions;
}

function useCrsOptions({intl, datasetOptions}) {
    const defaultMix = {id: 'defaultMix', value: 'defaultMix', label: intl.formatMessage(messages.defaultMixCrs), enabled: true};
    function buildOption(option){ return {id: option, value: option, label: intl.formatMessage(messages[option.split("/").pop()]), enabled: true}; }
    function isSupportedCrs(option){ return SUPPORTED_CRS.includes(option.id.split("/").pop()); }
    if(datasetOptions?.length){
        let options = [];
        datasetOptions.forEach((option) => {
            options.push(...option.availableCrs.filter(crs => !options.includes(crs)));
        });
        return [defaultMix, ...options.map(buildOption).filter(isSupportedCrs)];
    }
    return [defaultMix];
}

function getNGDDefaultFormatOptions(){
    return [
        {id: 'CSV', label: 'CSV', enabled: true},
        {id: 'GPKG', label: 'GeoPackage', enabled: true},
    ];
}

function useFormatOptions({updates}) {
    const formatOptions = getNGDDefaultFormatOptions();
    if (updates === 'DAILY' || updates === 'MONTHLY_COU') {
        formatOptions[1].enabled = false;
    }
    return formatOptions;
}

function useUpdateOptions({area, format, initialSupplyDate, hasFilters}) {
    const isAnnualAllowed = useIsAnnualAllowed();

    let updatesOptions = [
        {id: 'ONCE', label: 'Not Required', enabled: true},                 //0
        {id: 'DAILY', label: 'Daily COU', enabled: true},                   //1
        {id: 'MONTHLY_COU', label: 'Monthly COU', enabled: true},           //2
        {id: 'MONTHLY_FULL', label: 'Monthly Full Refresh', enabled: true}, //3
    ];
    if(isAnnualAllowed) {
        updatesOptions.push(
            {id: 'ANNUALLY', label: 'Annual Full Refresh ', enabled: true}  //4
        );
    }

    const date = new Date();
    const currentDate = toISODate(date)
    const firstOfMonth = getFirstOfMonthDate();
    const firstOfYear = getFirstOfYearDate();

    //do not need to disable any options if initialSupplyDate is not selected yet
    if (initialSupplyDate) {
        const initialSupplyIsFirstOfYear = initialSupplyDate === toISODate(firstOfYear);
        const initialSupplyIsFirstOfMonth = initialSupplyDate === toISODate(firstOfMonth);

        //DAILY
        updatesOptions[1].enabled = (initialSupplyDate === currentDate);
        //MONTHLY_COU
        updatesOptions[2].enabled = initialSupplyIsFirstOfMonth;
        //MONTHLY_FULL
        updatesOptions[3].enabled = initialSupplyIsFirstOfMonth;
        //ANNUALLY
        if(isAnnualAllowed) {
            updatesOptions[4].enabled = initialSupplyIsFirstOfYear;
        }
    }

    // We can't produce COU updates if any of these things are set
    if (area === 'Polygon' || format === 'GPKG' || hasFilters) {
        updatesOptions[1].enabled = false;
        updatesOptions[2].enabled = false;
    }

    return updatesOptions;
}

function useInitialSupplyDateOptions(intl, updates) {
    const isAnnualAllowed = useIsAnnualAllowed();

    const today = toISODate(new Date());
    const firstOfMonth = toISODate(getFirstOfMonthDate());
    const firstOfYear = toISODate(getFirstOfYearDate());

    // Add in all the options... today, 1st of the month, and 1st of the year. Some of them may be the same
    // day, and we don't want duplicates in the list, so we skip the option if it already exists
    const initialSupplyDateOptions = [];
    function addIfUnique(timeString) {
        if(!initialSupplyDateOptions.find(existingOption => existingOption.id === timeString)) {
            initialSupplyDateOptions.push({
                id: timeString,
                label: intl.formatDate(timeString, {day: 'numeric', month: 'short', year: 'numeric'}),
                enabled: true
            });
        }
    }
    addIfUnique(today);
    addIfUnique(firstOfMonth);
    if(isAnnualAllowed) {
        addIfUnique(firstOfYear);
    }

    switch (updates) {
        case 'DAILY' :
            disableOtherOptions(initialSupplyDateOptions, today);
            break;
        case 'MONTHLY_COU':
        case 'MONTHLY_FULL':
            disableOtherOptions(initialSupplyDateOptions, firstOfMonth);
            break;
        case 'ANNUALLY':
            disableOtherOptions(initialSupplyDateOptions, firstOfYear);
            break;
        default:
            break;
    }

    return initialSupplyDateOptions;
}

//Disables update options depending on which date has been selected
function disableOtherOptions(initialSupplyDateOptions, optionToKeep) {
    for (let i = 0; i < initialSupplyDateOptions.length; i++) {
        if (initialSupplyDateOptions[i].id !== optionToKeep) {
            initialSupplyDateOptions[i].enabled = false;
        }
    }
}

function getFirstOfMonthDate() {
    const firstOfMonth = new Date();
    firstOfMonth.setDate(1);
    return firstOfMonth;
}

function getFirstOfYearDate() {
    const firstOfYear = new Date();
    firstOfYear.setMonth(0, 1);

    return firstOfYear;
}

export {
    useDataPackageNgdOptions,
    getNGDDefaultFormatOptions
};