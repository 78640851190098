import { ReactComponent as SearchIcon } from "../../../components/icons/search.svg";
import searchFieldMessages from "./SearchField.msg";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { useIntl } from "react-intl";
import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";
import PropTypes from "prop-types";

const StyledTextField = styled(TextField)(({ theme }) => `
    flex-grow: 1;
    & .inputRoot { 
        border-radius: 3px 0 0 3px;
        padding: 0 ${theme.spacing(1)};
    }
    & .inputRoot *::placeholder {
        font-style: italic;
    }
    & .clearSearch {
        padding: 0.2em;
    }
    & .clearSearch svg {
        width: 20px;
        height: 20px;
    }
`);

const SearchFieldContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export default function SearchField(props) {
    const intl = useIntl();
    return (
        <SearchFieldContainer>
            <StyledTextField
                id="teamSpaceOrgOwnerSearch"
                placeholder={intl.formatMessage(searchFieldMessages.ownersSearchPlaceholder)}
                variant='standard'
                InputProps={{
                    ...props.InputProps,
                    disableUnderline: true,
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon height={24} width={24} />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position='end'>
                            {props.showClearSearchTerm && (
                                <IconButton className='clearSearch' onClick={props.onClearSearchTerm} title='Clear'>
                                    <Close />
                                </IconButton>
                            )}
                            {props.InputProps?.endAdornment}
                        </InputAdornment>
                    )
                }}
                inputProps={props.inputProps}
            />
        </SearchFieldContainer>
    );
};

SearchField.propTypes = {
    showClearSearchTerm: PropTypes.bool,
    onClearSearchTerm: PropTypes.func
};
