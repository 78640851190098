import React, {useState} from 'react';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {defineMessages, FormattedMessage} from 'react-intl';
import {formatBytes} from '../../../../modules/downloads';
import {compose} from 'redux';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import {ReactComponent as SaveAlt} from "../../../../components/icons/download.svg";
import {withWidth} from 'omse-components';
import {Dialog} from "@mui/material";
import {SampleDataForm} from "./SampleDataForm";
import Button from "@mui/material/Button";

const messages = defineMessages({
    downloadLink: {
        id: 'SampleDataDownloadsItem.downloadLink',
        defaultMessage: '{name}{fileInfo, select, false {} other { ({fileInfo})}} - {format} {tile} ({size})',
        description: 'Download link text'
    },
    links: {
        id: 'SampleDataDownloadsItem.links',
        defaultMessage: 'Direct {data, plural, one {link} zero {link} other {links}}:',
        description: 'Link label'
    },
});

const styles = theme => ({
    downloadLink: {
        padding: 0,
        '& svg': {
            marginRight: theme.spacing(1),
            position: 'relative',
            top: -5,
            flex: '0 0 auto'
        },
        [theme.breakpoints.down('md')]: {
            '&:not(:last-child)': {
                marginBottom: 15
            }
        }
    },
    downloadLinks: {
        padding: '7px 0 0 0',
        [theme.breakpoints.down('md')]: {
            padding: 0
        }
    },
    linksLabel: {
        color: theme.palette.text.primary,
        margin: '20px 0 7px 0'
    }
});

export function SampleDataLinks(props) {
    let {classes, downloads, name, format, showLabel} = props;

    const [showForm, setShowForm] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    return (
        <>
            <List className={classes.downloadLinks}>
                {showLabel &&
                    <Typography variant='body1' className={classNames(classes.stageLabels, classes.linksLabel)}>
                        <FormattedMessage {...messages.links} values={{data: downloads.length}}/>
                    </Typography>
                }
                {!formSubmitted &&
                    <Button variant='outlined' onClick={() => setShowForm(true)}>
                        Fill in form and download
                    </Button>
                }
                {formSubmitted && downloads.map((dl, idx) => {
                    return (<ListItem key={idx} className={classes.downloadLink}>
                        <ListItemText>
                            <a href={dl.url} download>
                                <SaveAlt width={24}/>
                                <FormattedMessage {...messages.downloadLink} values={{
                                    name: name,
                                    fileInfo: false,
                                    format: format,
                                    tile: dl.area,
                                    size: formatBytes(dl.size)
                                }}/>
                            </a>
                        </ListItemText>
                    </ListItem>)
                })
                }
            </List>
            <Dialog open={showForm} onClose={() => setShowForm(false)}>
                <SampleDataForm setShowForm={setShowForm} setFormSubmitted={setFormSubmitted}/>
            </Dialog>
        </>
    );
}

SampleDataLinks.propTypes = {
    classes: PropTypes.object.isRequired,
    downloads: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    showLabel: PropTypes.bool
};

SampleDataLinks.defaultProps = {
    downloads: [],
    showLabel: false
};

export default compose(withWidth(), withStyles(styles))(SampleDataLinks);
