import errorHandlingMessages from "./errorHandling.msg";

export function errorFormattedMessage(teamSpaceErrorResult) {
    const httpStatus = teamSpaceErrorResult.error.httpStatus;
    const httpResponse = teamSpaceErrorResult.error.httpResponse;
    let errorKey = JSON.parse(httpResponse).error;

    if (errorKey.includes(';')) {
        const errorKeys = errorKey.split(";");
        if (errorKeys.length > 0) {
            errorKey = errorKeys[0]; // Show the first error.
        }
    }

    switch (httpStatus) {
        case 400:
            switch (errorKey) {
                case 'field:name:required':
                    return errorHandlingMessages.nameFieldRequired;
                case 'field:name:length':
                    return errorHandlingMessages.nameFieldLengthInvalid;
                case 'field:description:length':
                    return errorHandlingMessages.descriptionFieldLengthInvalid;
                case 'permission:invalid':
                    return errorHandlingMessages.invalidPermission;
                default:
                    return errorHandlingMessages.genericServerError;
            }
        case 403:
            switch (errorKey) {
                case 'role:invalid':
                    return errorHandlingMessages.invalidRole;
                default:
                    return errorHandlingMessages.genericUnauthorized;
            }
        default:
            return errorHandlingMessages.genericServerError;
    }
}