import React, {Component, Fragment} from 'react';
import {Line} from 'react-chartjs-2';
import PropTypes from 'prop-types';
import {osColour, withWidth} from 'omse-components';
import Typography from '@mui/material/Typography';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import withStyles from 'react-jss';
import classNames from 'classnames';
import {ARROW_LEFT, ARROW_RIGHT, ESCAPE} from '../constants/keys';

const arrowBasis = 10;
const boxShadow = '0 1px 3px 1px rgba(0, 0, 0, 0.4)';
const borderRadius = '2px';
const keyMargin = 4;

// When drawing the tooltip we center it on the matching x-axis point. For mobile sized devices, there might
// not be space to draw the whole tooltip for x values near the edges of the graph, so we have a position value
// that notices if we are close to the edge and makes the content flow towards the middle of the screen, and
// not off the edge.
const minTooltipPosition = 100;

const styles = (theme) => ({
    lineGraphWrapper: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        '&:focus': {
            outline: 'none'
        }
    },
    lineGraph: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row'
    },
    paper: {
        padding: theme.spacing(1.5),
        paddingBottom: theme.spacing(1),
        background: osColour.neutral.white,
        position: 'relative',
        boxShadow: 'none',
        zIndex: 2,
        borderRadius
    },
    popper: {
        top: theme.spacing(-0.5),
        zIndex: 1,
        opacity: 1,
        display: 'none',
        position: 'absolute',
        boxShadow,
        borderRadius
    },
    arrow: {
        top: theme.spacing(-0.5),
        width: 25,
        height: 25,
        marginLeft: -12.5,
        position: 'absolute',
        overflow: 'hidden',
        display: 'none',
        '&:after': {      
            display: 'block',
            content: '""',
            position: 'absolute',
            width: arrowBasis,
            height: arrowBasis,
            background: '#fff',
            transform: 'rotate(-45deg)',
            borderRadius: '0 1px',
            top: -5,
            left: 6.5,
            zIndex: 5,
            boxShadow
        }
    },
    key: {
        height: theme.spacing(2),
        width: theme.spacing(2),
        marginRight: keyMargin,
        marginTop: keyMargin,
        flex: 'none'
    },
    item: {
        display: 'flex',
        '& > div': {
            display: 'flex',
            flexWrap: 'wrap'
        }
    },
    title: {
        marginBottom: theme.spacing(0.5)
    },
    label: {
        color: osColour.neutral.stone,
        marginRight: theme.spacing(0.5)
    }
});

export class LineGraph extends Component {

    chartRef = React.createRef();
    tooltipRef = React.createRef();
    tooltipDateRef = React.createRef();
    arrowRef = React.createRef();
    tooltipIndex = 0;

    state = {}

    componentDidMount() {
        if (!this.state.data) {
            const data = typeof this.props.data === 'function'? this.props.data() : this.props.data;
            this.setState({data}, () => {
                if (this.state.data && this.state.data.datasets) {
                    this.state.data.datasets.forEach(item => item.ref = React.createRef());
                    const config = this.chartOptions();
                    this.setState({config});
                }
            });
        }

        window.addEventListener('resize', this.hideTooltip);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.hideTooltip);
    }

    chartOptions = () => {
        return {
            responsive: true,
            maintainAspectRatio: false,
            cubicInterpolationMode: 'monotone',
            fill: true,
            scales: {
                x: {},
                y: {
                    min: 0,
                    suggestedMax: 5,
                    grid: {
                        display: false
                    }
                }
            },

            plugins: {
                tooltip: {
                    enabled: false,
                    external: this.customTooltips
                },
                legend: {
                    display: false
                },
                annotation: {
                    animations: {
                        numbers: {
                            properties: [],
                            type: 'number'
                        }
                    },
                    annotations: {
                        // This draws a vertical dashed line above the selected 'x' value, whenever a tooltip is shown
                        // The graph will need a refresh() call to catch up when the underlying state changes
                        toolTipLine: {
                            display: false,
                            type: 'line',
                            borderColor: osColour.neutral.stone,
                            borderWidth: 2,
                            borderDash: [2, 2],
                            xMin: () => this.tooltipIndex,
                            xMax: () => this.tooltipIndex
                        }
                    }
                }
            }
        }
    }

    customTooltips = (context) => {
        const chart = context.chart;
        const tooltip = context.tooltip;
        const {theme} = this.props;
        const canvas = chart.canvas;

        // Hide tooltip and line annotation when tooltip is transparent
        if (tooltip.opacity === 0) {
            this.hideTooltip();
            return;
        }

        // Update values
        const date = tooltip.dataPoints[0].label;
        const tooltipDateEl = this.tooltipDateRef.current;
        tooltipDateEl.innerHTML = date;
        if (this.state.data && this.state.data.datasets) {
            this.state.data.datasets.forEach((item, index) => {
                // If some datasets are hidden the tooltip array will have fewer elements than the dataPoints array.
                // This means we must use the datasetIndex value from the tooltip to relate them.
                const currentDataPoint = tooltip.dataPoints.find(point => point.datasetIndex === index);
                if (item.ref && item.ref.current && currentDataPoint) {
                    item.ref.current.innerHTML = currentDataPoint.formattedValue;
                }
            });
        }

        // Update position. We try to center the label on the current point, but if the text doesn't fit then we need
        // to nudge the position a little. In the first half of the graph then we use the left hand edge as the datum,
        // but as we get into the second half then the right hand side controls things.
        const midpoint = (chart.chartArea.left + chart.chartArea.right) / 2;
        const tooltipEl = this.tooltipRef.current;
        tooltipEl.style.width = 'auto';
        tooltipEl.style.display = 'block';

        if(tooltip.caretX <= midpoint) {
            tooltipEl.style.left = tooltip.caretX + 'px';
            tooltipEl.style.right = 'auto'
            tooltipEl.style.transform = 'translate(-50%, -100%)';
        } else {
            tooltipEl.style.left = 'auto';
            tooltipEl.style.right = (canvas.clientWidth - tooltip.caretX) + 'px';
            tooltipEl.style.transform = 'translate(50%, -100%)';
        }

        // positioning at graph limits.
        // min, or far left
        if ((chart.chartArea.left === tooltip.caretX) || (tooltip.caretX < minTooltipPosition)) {
            tooltipEl.style.left = theme.spacing(1);
            tooltipEl.style.right = 'auto';
            tooltipEl.style.transform = 'translate(0, -100%)';
        }

        // max, or far right
        if ((chart.chartArea.right === tooltip.caretX) || (canvas.clientWidth - tooltip.caretX < minTooltipPosition)) {
            tooltipEl.style.left = 'auto';
            tooltipEl.style.right = theme.spacing(-1);
            tooltipEl.style.transform = 'translate(0, -100%)';
        }

        // update arrow position
        const arrowEl = this.arrowRef.current;
        arrowEl.style.left = tooltip.caretX + 'px';
        arrowEl.style.display = 'block';

        // Update dashed line annotation
        let updateNeeded = false;
        if(this.tooltipIndex !== tooltip.dataPoints[0].dataIndex) {
            this.tooltipIndex = tooltip.dataPoints[0].dataIndex;
            updateNeeded = true;
        }

        // We only show the dotted line when we move on from index 0. This stops us from making the
        // y-axis look like a dotted line.
        let displayLine = this.tooltipIndex > 0;
        if(chart.options.plugins.annotation.annotations.toolTipLine.display !== displayLine) {
            chart.options.plugins.annotation.annotations.toolTipLine.display = displayLine;
            updateNeeded = true;
        }

        if(updateNeeded) {
            chart.update();
        }
    }

    hideTooltip = () => {
        this.arrowRef.current.style.display = 'none';
        this.tooltipRef.current.style.display = 'none';
        const chart = this.chartRef.current;
        if(chart && chart.options.plugins.annotation.annotations.toolTipLine.display) {
            chart.options.plugins.annotation.annotations.toolTipLine.display = false;
            chart.update();
        }
    }

    keyDown = (e, graphFocussed) => {
        if (e.key === ARROW_RIGHT || e.key === ARROW_LEFT || graphFocussed) {
            const chart = this.chartRef.current;
            if (chart) {
                const canvas = chart.canvas;
                const scales = chart.scales;
                if (canvas && scales) {
                    const xAxis = scales.x;
                    if (xAxis) {
                        const xGrid = xAxis['_gridLineItems'];
                        if (xGrid) {
                            let nextIndex = this.tooltipIndex;
                            if (this.tooltipIndex < xGrid.length - 1 && e.key === ARROW_RIGHT) {
                                nextIndex++;
                            }
                            if (this.tooltipIndex > 0 && e.key === ARROW_LEFT) {
                                nextIndex--;
                            }
                            if (nextIndex >= 0 && nextIndex < xGrid.length) {
                                const newX = (xGrid[nextIndex].tx1 || 0) + canvas.offsetParent.offsetLeft;
                                const newY = xGrid[nextIndex].ty1 || 0;
                                const evt = new MouseEvent('click', {
                                    view: window,
                                    bubbles: true,
                                    cancelable: true,
                                    clientX: newX,
                                    clientY: newY
                                });
                                canvas.dispatchEvent(evt);
                            }
                        }
                    }
                }
            }
        }
        if (e.key === ESCAPE) {
            this.hideTooltip();
        }
    }

    graphFocused = () => {
        this.keyDown({key: null}, true);
    }

    graphUnfocused = () => {
        this.hideTooltip();
    }

    render() {
        const {classes, data} = this.props;
        const datasets = (this.state.data && this.state.data.datasets) || [];
        const config = this.state.config;
        
        return <div className={classes.lineGraphWrapper} tabIndex='0' onKeyDown={this.keyDown} onFocus={this.graphFocused} onBlur={this.graphUnfocused}>
            {config &&
                <Fragment>
                    <ClickAwayListener onClickAway={this.hideTooltip} mouseEvent='onMouseDown' touchEvent='onTouchStart'>
                        <Line ref={this.chartRef} className={classes.lineGraph} data={data} options={config} height={200} />
                    </ClickAwayListener>
                    <div ref={this.tooltipRef} role='tooltip' className={classes.popper} x-placement='top'>
                        <div className={classes.paper}>
                            <Typography variant='body1' className={classes.title}>
                                <span ref={this.tooltipDateRef}></span>
                            </Typography>
                            {datasets.filter(dataset => !dataset.hidden).map(item => {
                                return <Typography variant='body1' component='div' className={classes.item} key={item.label}>
                                            <span className={classNames(classes.key, item.keyClass)}></span>
                                            <div>
                                                <span className={classes.label}>{item.displayLabel + ':'}</span>
                                                {item.ref && <span ref={item.ref} />}
                                            </div>
                                        </Typography>
                                })
                            }
                        </div>
                    </div>
                    <span ref={this.arrowRef} className={classes.arrow} />
                </Fragment>
            }
        </div>
    }
}

LineGraph.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

export default withWidth()(withStyles(styles, {injectTheme: true})(LineGraph));

