import React from "react";
import { contentPadding } from "omse-components";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import WorkspaceOverview from "./workspace/WorkspaceOverview";
import WorkspaceSidebar from "./workspace/WorkspaceSidebar";
import FeatureCheck from "../components/FeatureCheck"
import WelcomeModal from "./workspace/WelcomeModal";

const workspaceXPaddingDesktop = contentPadding.left / 2; // 48px
const workspaceYPaddingDesktop = contentPadding.left / 4; // 24px

const workspaceXPaddingMobile = contentPadding.left / 6; // 16px
const workspaceYPaddingMobile = contentPadding.left / 6; // 16px

const ContentContainer = styled("div")(
	({ theme }) => `
	display: flex;
	flex: 1 1 auto;
	flex-direction: row;
	gap: 8rem;
	padding-bottom: ${contentPadding.bottom}px;
	padding-left: ${workspaceXPaddingDesktop}px;  
	padding-right: ${workspaceXPaddingDesktop}px;
	padding-top: ${workspaceYPaddingDesktop}px;
	font-family: Source Sans Pro,sans-serif;
	${theme.breakpoints.down(1315)} {
		margin-bottom: ${theme.spacing(3)};
		gap: 0;
		flex-direction: column;
		padding-left: ${workspaceXPaddingMobile}px;
		padding-right: ${workspaceXPaddingMobile}px;
		padding-top: ${workspaceYPaddingMobile}px;
	}
`
);

const Workspace = () => {
	const userLoading= useSelector(state => state.user?.current?.loading)
	const configLoading = useSelector(state => state.config?.current?.loading)
	const loading = userLoading || configLoading

	return (
		<FeatureCheck feature="workspace">
			<WelcomeModal />
			<ContentContainer>
				{loading && <CircularProgress size={32} data-testid="loading-spinner" />}
				{!loading && (
					<>
						<WorkspaceOverview />
						<WorkspaceSidebar />
					</>
				)}
			</ContentContainer>
		</FeatureCheck>
	);
};

export default Workspace