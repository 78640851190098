import React from "react";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { Paper, Typography } from "@mui/material";
import { osColour } from "omse-components";
import emptyStateMessages from "./EmptyState.msg";
import NewTeamSpace from "./NewTeamSpace";

const CustomPaper = styled(Paper)`
    padding: 2em;

    & > h2 {
        color: ${osColour.primary.berry};
    }

    & > p {
        margin-bottom: 1em;
    }
`;

function EmptyState() {
    return (
        <CustomPaper>
            <Typography variant="h2">
                <FormattedMessage {...emptyStateMessages.title} />
            </Typography>
            <Typography variant="body1">
                <FormattedMessage {...emptyStateMessages.body} />
            </Typography>
            <NewTeamSpace />
        </CustomPaper>
    );
}

export default EmptyState;
