import { useEffect, useState, useMemo} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { LockOutlined } from "@mui/icons-material";
import { osColour } from "omse-components";
import {
    Box,
    Typography,
    FormControlLabel,
    Switch,
    ClickAwayListener,
    Tooltip,
    IconButton,
    Autocomplete,
    css,
    CircularProgress,
} from "@mui/material";
import { ReactComponent as InfoIcon } from '../../../components/icons/info-notification.svg';
import Link from '../../../components/Link';
import SearchField from "./SearchField";
import MembersTable from "./MembersTable";
import OwnerMemberInfo from "./OwnerMemberInfo";
import { getOrgUsers } from "../../../modules/teamSpaces/actions";
import { TEAM_PERMISSION_GROUPS, API_PROJECT_PERMISSION_GROUPS } from "../../../../shared/teamSpaces";
import { ADMIN_ROLE } from "../../../../shared/roles";
import routePaths from '../../../util/routes';
import membersTabMessages from "./MembersTab.msg";
import { membersTab } from "./TeamSpaceAccess";

const noOptionsClass = css`
    &:empty {
        display: none;
    }
`;

const endAdornmentClass = css`
    margin-right: 8px;
`;

const Container = styled(Box)(
    ({ theme }) => `
    padding: ${theme.spacing(2, 4)};
`
);

const LockedTeamSwitcher = styled(FormControlLabel)(
    ({ theme }) => `
    margin: 0 0 ${theme.spacing(2)} 0;
    gap: 1em;
    display: flex;
    place-items: center;
    justify-content: space-between;

    & span {
        gap: 0.3em;
        display: flex;
        place-items: center;
    }
`
);

const MIN_SEARCH_LENGTH = 3;

const MembersTab = ({ newTeamSpaceParameters, setNewTeamSpaceParameters, members, setMembers, selectedUserIds }) => {
    const intl = useIntl();
    const dispatch = useDispatch()
    const user = useSelector(state => state.user.current.result);
    const orgUsers = useSelector(state => state.teamSpaces.getOrgUsers.result);
    const orgUsersLoading = useSelector(state => state.teamSpaces.getOrgUsers.loading);

    const [filteredOrgUsers, setFilteredOrgUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [autocompleteKey, setAutocompleteKey] = useState('autocomplete-members');

    const [infoTooltipVisible, setInfoTooltipVisible] = useState(false);

    useEffect(function () {
        if (user && orgUsers && orgUsers.length >= 0) {
            setFilteredOrgUsers(orgUsers.filter(orgUser => orgUser.role !== ADMIN_ROLE));
        }
    }, [user, orgUsers, setFilteredOrgUsers]);

    useEffect(function () {
        if ((!orgUsers || orgUsers.length === 0)) {
            dispatch(getOrgUsers());
        }
    }, [dispatch, orgUsers]);
    
    const toggleLocked = () => {
        setNewTeamSpaceParameters((prevState) => ({
            ...prevState,
            locked: !newTeamSpaceParameters.locked,
        }));
    };
    function resetAutocomplete() {
        setSearchTerm('');
        setAutocompleteKey(`autocomplete-members-${Date.now()}`)
    }

    // Autocomplete change: select/clear option
    function handleAutoCompleteChange(setter, selectedUserItem, reason) {
        if (reason === 'selectOption') {
            if (selectedUserItem) {
                setMembers([
                    {
                        ...selectedUserItem,
                        teamRole: TEAM_PERMISSION_GROUPS.TeamViewer,
                        apiProjectRole: API_PROJECT_PERMISSION_GROUPS.ApiProjectViewer,
                    },
                    ...members,
                ]);
                resetAutocomplete();
            }
        }
    }

    const onKeyUpHandler = (event) => {
        if (event) {
            setSearchTerm(event.target.value || '')
        }
    }

    const orgUserDetailsContainSearchTerm = useMemo(() => (orgUser) => {
        const st = searchTerm?.toLowerCase();
        const fn = orgUser.firstName?.toLowerCase();
        const ln = orgUser.lastName?.toLowerCase();
        const em = orgUser.email?.toLowerCase();
        const result = (
            (st) &&
            ((fn && fn.indexOf(st) !== -1) ||
                (ln && ln.indexOf(st) !== -1) ||
                (em && em.indexOf(st) !== -1) ||
                ((fn && ln) && (fn + " " + ln).indexOf(st) !== -1))
        )
        return result;
    }, [searchTerm]);
   
    return (
        <Container>
            <LockedTeamSwitcher
                label={
                    <>
                        <LockOutlined
                            fontSize="medium"
                            aria-hidden={true}
                            sx={{ color: osColour.neutral.stone }}
                        />
                        <Typography variant="body1">
                            {intl.formatMessage(membersTabMessages.makePrivate)}
                        </Typography>
                        <ClickAwayListener onClickAway={() => setInfoTooltipVisible(false)}>
                            <Tooltip
                                open={infoTooltipVisible}
                                onClose={() => setInfoTooltipVisible(false)}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={
                                    newTeamSpaceParameters.locked ? (
                                        <>
                                            <Typography
                                                variant="body1"
                                                marginBottom={2}
                                            >
                                                {intl.formatMessage(
                                                    membersTabMessages.unlockedTooltipP1,
                                                    {
                                                        strong: (chunks) => (
                                                            <strong>{chunks}</strong>
                                                        ),
                                                    }
                                                )}
                                            </Typography>
                                            <Typography variant="body1">
                                                {intl.formatMessage(
                                                    membersTabMessages.unlockedTooltipP2,
                                                    {
                                                        strong: (chunks) => (
                                                            <strong>{chunks}</strong>
                                                        ),
                                                    }
                                                )}
                                            </Typography>
                                        </>
                                    ) : (
                                        <Typography variant="body1">
                                            {intl.formatMessage(
                                                membersTabMessages.lockedTooltip
                                            )}
                                        </Typography>
                                    )
                                }
                            >
                                <IconButton
                                    size="small"
                                    onClick={() => setInfoTooltipVisible(true)}
                                >
                                    <InfoIcon
                                        width={24}
                                        height={24}
                                        color={osColour.status.warning}
                                    />
                                </IconButton>
                            </Tooltip>
                        </ClickAwayListener>
                    </>
                }
                labelPlacement="start"
                control={
                    <Switch
                        data-testid="toggleTeamLockedSwitch"
                        checked={newTeamSpaceParameters.locked || false}
                        onChange={() => toggleLocked()}
                    />
                }
            />
            {newTeamSpaceParameters.locked ? (
                <>
                    <Autocomplete
                        key={autocompleteKey}
                        fullWidth
                        clearOnEscape
                        handleHomeEndKeys
                        autoHighlight
                        clearOnBlur={false}
                        getOptionLabel={() => ''}
                        getOptionDisabled={option => option === "" || selectedUserIds.includes(option.datahubDeveloperId)}
                        forcePopupIcon={false}
                        options={filteredOrgUsers || []}
                        loading={orgUsersLoading}
                        onChange={handleAutoCompleteChange}
                        filterOptions={(options, state) => {
                            const results = options.filter(orgUser => (
                                (searchTerm?.length >= MIN_SEARCH_LENGTH) &&
                                (!members.find(member => (member.datahubDeveloperId === orgUser.datahubDeveloperId))) &&
                                orgUserDetailsContainSearchTerm(orgUser)
                            ));
                            return (results?.length > 0) ? results : [];
                        }}
                        renderInput={(params) => (
                            <SearchField {...params}
                                showClearSearchTerm={(searchTerm?.length > 0)}
                                onClearSearchTerm={resetAutocomplete}
                            />
                        )}
                        renderOption={(props, option) => {
                            const prevSelected = selectedUserIds.includes(option.datahubDeveloperId);
                            return (
                                <li {...props} key={option.datahubDeveloperId}>
                                    <OwnerMemberInfo 
                                        info={option} 
                                        user={user} 
                                        currentTab={membersTab}
                                        prevSelected={prevSelected} 
                                    />
                                </li>
                            );
                        }}
                        css={{
                            endAdornment: endAdornmentClass,
                            noOptions: noOptionsClass
                        }}
                        classes={{
                            inputRoot: 'inputRoot',
                        }}
                        noOptionsText={(searchTerm.length >= MIN_SEARCH_LENGTH)
                            && intl.formatMessage(membersTabMessages.noResultsAdminPrompt, { a: chunks => <Link path={routePaths.manageTeamMembers}>{chunks}</Link> })
                        }
                        onKeyUp={onKeyUpHandler}
                    />

                    {(orgUsersLoading || !user) &&
                        <CircularProgress size={32} className="loading" />
                    }

                    {(!orgUsersLoading && user) && (
                        members.length > 0 && <MembersTable members={members} setMembers={setMembers} />
                    )}
                </>
            ) : (
                <Typography variant="body1">
                    {intl.formatMessage(membersTabMessages.openMessage, {
                        br: () => <br />,
                    })}
                </Typography>
            )}
        </Container>
    );
};

export default MembersTab;

MembersTab.propTypes = {
    newTeamSpaceParameters: PropTypes.object.isRequired,
    setNewTeamSpaceParameters: PropTypes.func.isRequired,
    members: PropTypes.arrayOf(PropTypes.object).isRequired,
    setMembers: PropTypes.func.isRequired,
    selectedUserIds: PropTypes.arrayOf(PropTypes.string)
};
