import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import { createUseStyles } from 'react-jss';
import { styles } from '../download/DownloadStyles';

const useStyles = createUseStyles(styles);

const messages = defineMessages({
  title: {
    id: 'OsNetRinexData.title',
    defaultMessage: 'OS Net RINEX data',
    description: 'Label for OS Net RINEX data'
  }
});

export default function OsNetRinexData() {
  const classes = useStyles();
    return (
        <header className={classes.root}>
          <Typography variant='h1' color='primary'>
            <FormattedMessage {...messages.title}/>
          </Typography>
        </header>
    );

}