import React from 'react';
import { connect } from 'react-redux';
import Header from '../../documentation/Header';
import Content from "../../documentation/Content";
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import { compose } from 'redux';
import { createUseStyles } from 'react-jss';
import getBaseStyles from './styles/support';
import accountFaqs from './data/account';
import Faq from './components/Faq';
import { LinkToAnchor } from '../../../components/LinkToAnchor';
import { scrollHashIntoView } from './util/scroll';
import classNames from 'classnames';
import Document from '../../documentation/Document';
import { isWidthDown, withWidth, ExternalLink } from 'omse-components';
import { useImageLoadCallback } from './util/useImageLoadCallback';
import { useFaqs } from './util/useFaqs';

const useStylesBase = createUseStyles(theme => getBaseStyles(theme));

const useStyles = createUseStyles(theme => {
    return {
        support: {
            '& img ': {
                [theme.breakpoints.down('sm')]: {
                    width: 220
                }
            }
        }
    };
});

export function Index(props) {
    const { loggedIn, width } = props;
    const classesBase = useStylesBase(props);
    const classes = useStyles(props);
    const mobile = isWidthDown('xs', width);
    // Wait for all content to load before trying to scroll to fragment. Otherwise its been observed on Safari that
    // when images end up loading slowly they can they push page content down, meaning the final scroll position is then
    // not next to the correct heading.
    const { getImg } = useImageLoadCallback(scrollHashIntoView, loggedIn);
    let faqs = useFaqs(accountFaqs, getImg, { mobile });

    if (loggedIn) {
        faqs = faqs.filter(item => !item.loggedOutOnly);
    }
    return (
        <Document>
            <Header>
                <span>Support</span>
            </Header>
            <Content classes={{ content: classNames(classes.support, classesBase.support) }}>

                <Typography variant='h2'>FAQs: Account and API</Typography>
                <Typography variant='body1'>
                    The following FAQs describe how to manage your account and access API products:
                </Typography>

                <nav className={classesBase.faqs}>
                    <List>
                        {faqs.map((item) => (
                            <LinkToAnchor key={item.id} item={item} />
                        ))}
                    </List>
                </nav>

                <Typography variant='h2'>Contact us</Typography>
                <Typography variant='body1' paragraph={true}>
                    Please <ExternalLink type='support' /> for other technical or account issues.
                </Typography>

                <div className={classesBase.divider} />

                {faqs.map((item) => (
                    <Faq key={item.id} linkId={item.id} title={item.title}>{item.body}</Faq>
                ))}

                <List>
                    <LinkToAnchor />
                </List>

            </Content>
        </Document>
    );
}

function mapStateToProps(state) {
    const { result } = state.user.current;
    return {
        loggedIn: result,
        active: result && result.active
    }
}

export default compose(withWidth(), connect(mapStateToProps))(Index);