// pseudo catalogues
const OPEN_CATALOGUE = 'OPEN';
const PREMIUM_CATALOGUE = 'PREM';

// real catalogues
const PSGA_CATALOGUE = 'PSGA';
const PART_CATALOGUE = 'PART';
const COM3_CATALOGUE = 'COM3';
const SAMPLE_CATALOGUE = 'SAMP'

const CATALOGUES = [OPEN_CATALOGUE, PSGA_CATALOGUE, PART_CATALOGUE, COM3_CATALOGUE, PREMIUM_CATALOGUE, SAMPLE_CATALOGUE];

module.exports = {
    CATALOGUES,
    OPEN_CATALOGUE,
    PSGA_CATALOGUE,
    PART_CATALOGUE,
    COM3_CATALOGUE,
    PREMIUM_CATALOGUE,
    SAMPLE_CATALOGUE
};
