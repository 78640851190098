import React, { useEffect } from 'react';
import menuMessages from '../appContent/MenuMessages';
import {createUseStyles} from 'react-jss';
import {Typography, CircularProgress} from '@mui/material';
import {defineMessages, FormattedMessage} from 'react-intl';
import Header from '../documentation/Header';
import Document from '../documentation/Document';
import style from './styles/legal';
import { ExternalLink } from 'omse-components';
import { useDispatch, useSelector } from 'react-redux';
import { loadPartnerContractCatalogue } from "../../modules/partner/actions";
import { partnerContracts, titleMessages, summaryMessages } from './data/partnerContracts';
import { checkAndAppendPDF, checkAndAppendContract } from '../../util/text';
import {hasPartnerCatalogue} from "../../util/permissions";

const messages = defineMessages({
    intro: {
        id: 'PartnerContracts.intro',
        defaultMessage: 'Click on a Partner Contract link below to read the terms and conditions. Each link will open a PDF in a new tab.',
        description: 'Intro'
    }
});

const useStyles = createUseStyles(style);

export default function PartnerContracts() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const catalogue = useSelector(state => state.partners.catalogue.result);
    const user = useSelector(state => state.user.current.result);
    const loadingCatalogue = useSelector(state => state.partners.catalogue.loading);
    const loadingUser = useSelector(state => state.user.current.loading);
    const loading = loadingCatalogue || loadingUser;
    const partnerCatalogueLoaded = !loading && user && (catalogue && catalogue.contracts) && hasPartnerCatalogue(user);

    useEffect(() => {
        if (user) {
            dispatch(loadPartnerContractCatalogue());
        }
    }, [dispatch, user]);

    return (
        <Document>
            <Header back={true}>
                <FormattedMessage {...menuMessages.partnerContracts} />
            </Header>
            <div className={classes.content}>
                <div aria-live='polite'>
                    {partnerCatalogueLoaded &&
                        <Typography variant='h5' component='p'>
                            <FormattedMessage {...messages.intro} />
                        </Typography>
                    }
                </div>
                <Typography variant='h5' component='ul'>
                    {Object.entries(partnerContracts).map(c => {
                        const messageId = c[0];
                        const group = c[1];
                        return (
                            <li key={messageId}>
                                <Typography variant='h4' component='h2'>
                                    <FormattedMessage {...titleMessages[messageId]} />
                                </Typography>
                                <Typography variant='h5' component='p'>
                                    <FormattedMessage {...summaryMessages[messageId]} />
                                </Typography>
                                <div aria-live='polite'>
                                    {loading && hasPartnerCatalogue(user) &&
                                        <CircularProgress size={32} className={classes.loading} />
                                    }
                                    {partnerCatalogueLoaded &&
                                        <>
                                            {(group.contractIds && group.contractIds.length > 0) &&
                                                <ul>
                                                    {group.contractIds.map(contractId => {
                                                        const catalogueItem = catalogue.contracts.find(c => c.id === contractId);
                                                        if (catalogueItem) {
                                                            const hrefMessage = checkAndAppendContract(catalogueItem.label) + checkAndAppendPDF(catalogueItem.termsUrl)
                                                            return (
                                                                <li key={contractId}>
                                                                    <ExternalLink
                                                                        type='generic'
                                                                        href={catalogueItem.termsUrl}
                                                                        message={hrefMessage}
                                                                    />
                                                                </li>
                                                            )
                                                        }
                                                        return null;
                                                    })}
                                                </ul>
                                            }
                                            {(group.host && (group.contracts && group.contracts.length > 0)) &&
                                                <ul>
                                                    {group.contracts.map((contract) => {
                                                        const hrefMessage = contract.label + checkAndAppendPDF(contract.path)
                                                        return (
                                                            <li key={contract.label}>
                                                                <ExternalLink
                                                                    type='generic'
                                                                    href={group.host + contract.path}
                                                                    message={hrefMessage}
                                                                />
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            }
                                        </>
                                    }
                                </div>
                            </li>
                        )
                    })}
                </Typography>
            </div>
        </Document>
    )
}