import { createLoadingReducer, createLoadingMiddleware, createActionMiddleware, createActionReducer } from 'omse-components';
import {
    GET_TEAMSPACES_LOAD_ACTION, GET_TEAMSPACES_LOADED_ACTION, GET_TEAMSPACE_LOAD_ACTION, GET_TEAMSPACE_LOADED_ACTION,
    CREATE_TEAMSPACE_ACTION,
    GET_ORG_USERS_LOAD_ACTION, GET_ORG_USERS_LOADED_ACTION, GET_ORG_USERS_CLEAR_ACTION
} from "./teamSpaces/actions";
import { combineReducers } from 'redux';

export const teamSpacesReducer = combineReducers({
    getTeamSpaces: createLoadingReducer(GET_TEAMSPACES_LOAD_ACTION, GET_TEAMSPACES_LOADED_ACTION, true),
    getTeamSpace: createLoadingReducer(GET_TEAMSPACE_LOAD_ACTION, GET_TEAMSPACE_LOADED_ACTION, true),
    createTeamSpace: createActionReducer(CREATE_TEAMSPACE_ACTION),
    getOrgUsers: createLoadingReducer(GET_ORG_USERS_LOAD_ACTION, GET_ORG_USERS_LOADED_ACTION, true),
});

const TEAM_SPACES_API_URL = '/api/teamSpaces';

function getTeamSpacesPrepareCall() {
    return TEAM_SPACES_API_URL + '/teamSpaces';
}

function getTeamSpacePrepareCall(action) {
    return TEAM_SPACES_API_URL + `/teamSpaces/${action.teamSpaceId}`;
}

function createTeamSpacePrepareCall(action) {
    return {
        method: 'POST',
        url: TEAM_SPACES_API_URL + '/teamSpaces',
        body: {
            name: action.name,
            description: action.description,
            ownerDatahubDeveloperIds: action.ownerDatahubDeveloperIds,
            members: action.members,
            locked: action.locked
        }
    };
}

function getOrgUsersPrepareCall() {
    return TEAM_SPACES_API_URL + '/orgUsers'
}

export const teamSpacesMiddleware = [
    createLoadingMiddleware(GET_TEAMSPACES_LOAD_ACTION, GET_TEAMSPACES_LOADED_ACTION, getTeamSpacesPrepareCall),
    createLoadingMiddleware(GET_TEAMSPACE_LOAD_ACTION, GET_TEAMSPACE_LOADED_ACTION, getTeamSpacePrepareCall),
    createActionMiddleware(CREATE_TEAMSPACE_ACTION, createTeamSpacePrepareCall),
    createLoadingMiddleware(GET_ORG_USERS_LOAD_ACTION, GET_ORG_USERS_LOADED_ACTION, getOrgUsersPrepareCall),
    store => next => action => {
        if (action.type === GET_ORG_USERS_CLEAR_ACTION) {
            store.dispatch({ type: GET_ORG_USERS_LOADED_ACTION, result: [] });
        }
        next(action);
    }
];