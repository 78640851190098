import React, {Fragment, useState} from 'react';
import {FormControl, Typography} from '@mui/material';
import {createUseStyles, useTheme} from 'react-jss';
import {osColour, border1, contentPadding, InputLabel, linkButton, DropDownMenu} from 'omse-components';
import BackLink from '../../../../components/BackLink';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import StepList from '../../../../components/stepList/StepList';
import routes, {getLocation} from "../../../../util/routes";
import classNames from 'classnames';
import SampleDataDesc from './SampleDataDesc';
import SampleDataSideInfo from './SampleDataSideInfo';
import SampleDataLinks from './SampleDataLinks';
import SampleDataHeading from './SampleDataHeading';
import StepListItem from '../../../../components/stepList/StepListItem';
import {formatBytes} from '../../../../modules/downloads';
import ContentHeader from '../../../../components/ContentHeader';
import useSampleDataItem from "../../../../hooks/useSampleDataItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import SampleDataTagAndProvider from "./SampleDataTagAndProvider";
import {useHistory, useLocation} from "react-router-dom";
import {SAMPLE_DATA} from "../../../../../shared/features";
import check from "../../../../util/featureCheck";
import FeatureCheck from "../../../../components/FeatureCheck";
import {useSelector} from "react-redux";

const messages = defineMessages({
    sampleDataDownloads: {
        id: 'SampleDataItem.actions',
        defaultMessage: 'SampleData downloads',
        description: 'Label for back link'
    },
    specifyAnArea: {
        id: 'SampleDataItem.specifyAnArea',
        defaultMessage: 'Specify an area',
        description: 'Label for Specify an area'
    },
    dataFormat: {
        id: 'SampleDataItem.dataFormat',
        defaultMessage: 'Data format',
        description: 'Label for Data format'
    },
    download: {
        id: 'SampleDataItem.download',
        defaultMessage: 'Download',
        description: 'Label for Download'
    },
    allOfGb: {
        id: 'SampleDataItem.allOfGb',
        defaultMessage: 'All of Great Britain',
        description: 'Label for all of GB'
    },
    allOfUk: {
        id: 'SampleDataItem.allOfUk',
        defaultMessage: 'All of the United Kingdom',
        description: 'Label for all of UK'
    },
    allOfGbAndNi: {
        id: 'SampleDataItem.allOfGbAndNi',
        defaultMessage: 'All of Great Britain and Northern Ireland',
        description: 'Label for all of GB and NI'
    },
    formatLabel: {
        id: 'SampleDataItem.formatLabel',
        defaultMessage: 'Select format',
        description: 'Label Select format control'
    },
    loading: {
        id: 'SampleDataItem.loading',
        defaultMessage: 'Loading...',
        description: 'Label for the download page while it is loading the details of the download'
    },
    downloadData: {
        id: 'SampleDataItem.downloadData',
        defaultMessage: 'Download {data, plural, =0{} one {} other {all}}',
        description: 'Label for download data button'
    },
    size: {
        id: 'SampleDataItem.size',
        defaultMessage: 'Size',
        description: 'Size label'
    },
    selectFormat: {
        id: 'SampleDataItem.selectFormat',
        defaultMessage: 'Select format',
        description: 'Select format option label'
    },
    setCustomArea: {
        id: 'SampleDataItem.setCustomArea',
        defaultMessage: 'Set a custom area',
        description: 'Custom area label'
    },
    or: {
        id: 'SampleDataItem.or',
        defaultMessage: 'or ',
        description: 'Text for or label'
    },
    please: {
        id: 'SampleDataItem.please',
        defaultMessage: 'Please ',
        description: 'Text for Please label'
    },
    setYourCustomArea: {
        id: 'SampleDataItem.setYourCustomArea',
        defaultMessage: 'set your custom area',
        description: 'Set custom area text'
    },
    selectedTiles: {
        id: 'SampleDataItem.selectedTiles',
        defaultMessage: 'Selected tiles:',
        description: 'Selected tiles label'
    },
    changeCustomArea: {
        id: 'SampleDataItem.changeCustomArea',
        defaultMessage: 'Change custom area',
        description: 'Change custom area label'
    },
    sizeRange: {
        id: 'SampleDataItem.sizeRange',
        defaultMessage: 'Each download ranges from {min} to {max}',
        description: 'Size label for custom area'
    },
    singleSize: {
        id: 'SampleDataItem.singleSize',
        defaultMessage: '{size}',
        description: 'Size label for custom area for one item'
    },
    loadingFormats: {
        id: 'SampleDataItem.loadingFormats',
        defaultMessage: 'Loading formats...',
        description: 'Label for the Loading formats... message'
    },
    back: {
        id: 'SampleDataItem.back',
        defaultMessage: 'Back to {name}',
        description: 'Label back link'
    }
});

// Used to keep the controls consistent
const controlWidth = 258;
const mobileControlWidth = 244;

const myStyles = createUseStyles(theme =>  ({
        root: {
            paddingTop: contentPadding.top - contentPadding.backLink - 10,
            paddingRight: contentPadding.right,
            paddingBottom: contentPadding.bottom,
            paddingLeft: contentPadding.left,
            borderBottom: border1,
            [theme.breakpoints.down('sm')]: {
                padding: contentPadding.mobile,
                paddingTop: theme.spacing(3)
            }
        },
        container: {
            display: 'flex',
            marginTop: theme.spacing(2),
            [theme.breakpoints.down('lg')]: {
                flexDirection: 'column'
            }
        },
        content: {
            paddingTop: theme.spacing(0.5),
            paddingLeft: contentPadding.left,
            maxWidth: contentPadding.maxWidth,
            [theme.breakpoints.down('sm')]: {
                padding: 0,
                marginTop: 0
            }
        },
        downloadDetailContent: {
            flex: '1 1 fit-content',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2)
            }
        },
        downloadStage: {
            '&:not(:last-child)': {
                minHeight: 108,
            },
            '&:last-of-type $stageContent': {
                paddingBottom: 0,
                marginBottom: theme.spacing(3)
            }
        },
        contentLabel: {
            color: theme.palette.text.primary,
            marginTop: 4,
            whiteSpace: 'normal'
        },
        stageLabels: {
            color: theme.palette.text.primary,
            marginTop: 4,
            whiteSpace: 'nowrap',
            [theme.breakpoints.down('sm')]: {
                width: 130,
                marginBottom: 15,
                flex: '1 0 auto'
            },
            '&.disabled p span,&.disabled': {
                color: osColour.neutral.stone
            }
        },
        stageHeading: {
            marginBottom: theme.spacing(1),
            [theme.breakpoints.down('sm')]: {
                marginTop: 5,
                '& span': {
                    fontSize: '1.125rem'
                }
            }
        },
        formatSelectButton: {
            '&.MuiButton-outlined': {
                fontWeight: 600,
            },
            minWidth: controlWidth,
            [theme.breakpoints.down('sm')]:{
                minWidth: mobileControlWidth
            }
        },
        downloadMenu: {
            padding: '8px 51px 7px 19px',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(2.5),
            }
        },
        downloadMenuIcon: {
            color: osColour.primary.berry,
            transform: 'scale(1.25)'
        },
        expandIcon: {
            position: 'absolute',
            top: 8,
            right: 11,
            zIndex: -1,
            '&.formatOpen': {
                transform: 'rotate(180deg)'
            }
        },
        label: {
            color: osColour.neutral.stone,
            marginRight: 5
        },
        controlInfo: {
            marginTop: theme.spacing(1),
            whiteSpace: 'normal',
            '&.disabled': {
                color: osColour.neutral.stone,
                '& span': {
                    color: osColour.neutral.stone,
                }
            },
            display: 'block',
            width: controlWidth,
            [theme.breakpoints.down('sm')]:{
                width: mobileControlWidth
            }
        },
        allAreaButton: {
            borderRadius: '3px 0 0 3px',
            '&.selectedAll': {
                background: osColour.primary.berry,
                color: osColour.neutral.white
            },
            borderRight: 'none',
            '&:hover':{
                borderRight: 'none'
            }
        },
        customAreaButton: {
            borderRadius: '0 3px 3px 0',
            '&.hasCustomArea': {
                background: osColour.primary.berry,
                color: osColour.neutral.white
            },
            borderLeft: 'none',
            '&:hover':{
                borderLeft: 'none'
            }
        },
        singleActionButton: {
            background: osColour.primary.berry,
            color: osColour.neutral.white,
            '&:hover': {
                background: osColour.primary.berry
            }
        },
        mobileCompatable: {
            [theme.breakpoints.down('sm')]: {
                padding: '7px 15px 7px !important'
            }
        },
        controls: {
            whiteSpace: 'normal',
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        },
        downloadForm: {
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(2)
            }
        },
        changeCustomArea: {
            ...linkButton,
            display: 'block',
            marginBottom: theme.spacing(4.5),
            [theme.breakpoints.down('sm')]: {
                marginBottom: 0
            }
        },
        downloadMetadata: {
            marginLeft: 5,
        },
        metaLabel: {
            marginLeft: 5
        },
        linkIcon: {
            width: '16px',
            height: '16px',
            paddingTop: '2px',
            paddingRight: theme.spacing(1)
        },
        sizeInfo: {
            marginBottom: theme.spacing(4),
            color: theme.palette.text.primary,
            whiteSpace: 'break-spaces',
            [theme.breakpoints.down('sm')]: {
                marginBottom: 0
            }
        },
        titleContainer: {
            display: 'flex',
            flexWrap: 'wrap-reverse',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            marginTop: theme.spacing(3),
            marginRight: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(2),
            }
        },
        productTitle: {
            marginRight: theme.spacing(3),
            marginBottom: theme.spacing(3)
        },
        stageContent: {
            marginLeft: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 auto',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                left: -13,
                position: 'relative',
                alignItems: 'start',
                paddingBottom: theme.spacing(4)
            }
        }
    }));

export default function SampleDataItem() {
    const history = useHistory();
    const location = useLocation();
    const classes = myStyles();
    const intl = useIntl();
    const theme = useTheme();
    const isXlScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const [selectedFormatName, setSelectedFormatName] = useState(null);
    const { product, productLoading, downloads, downloadsLoading, error } = useSampleDataItem();
    const config = useSelector(state => state.config.current.result);

    if(!check(SAMPLE_DATA, config)){
        history.replace({
            pathname: '/error404'
        });
    }

    const loadingJsx = <FeatureCheck feature={SAMPLE_DATA}>
        <ContentHeader
            backPath={routes.sampleDataDownloads}
            backLabel={messages.sampleDataDownloads}
            title={messages.loading}/>
    </FeatureCheck>;

    if (error) {
        history.push(getLocation(routes.error404Sample, location));
        return loadingJsx;
    }

    if (productLoading || !product) {
        return loadingJsx;
    }

    let filteredDownloads = [];
    const formatLabels = getFormatLabels(downloads);
    filteredDownloads = selectedFormatName ? downloads.filter(d => selectedFormatName === getFormatLabel(d)) : downloads;


    function getFormatLabel(download) {
        let label = download.format;
        if(download.subformat) {
            label += ' ' + download.subformat;
        }
        return label;
    }

    function getFormatLabels(downloads) {
        const uniqueFormats = [];
        downloads.forEach(d => {
            const label = getFormatLabel(d);
            if (uniqueFormats.indexOf(label) === -1) {
                uniqueFormats.push(label);
            }
        });
        return uniqueFormats;
    }

    const formatDisabled = filteredDownloads.length === 0;
    const downloadDisabled = !selectedFormatName && formatLabels.length !== 1 && filteredDownloads.length !== 1;
    const minSize = formatBytes(Math.min(...filteredDownloads.map(d => d.size)));
    const maxSize = formatBytes(Math.max(...filteredDownloads.map(d => d.size)));

    const logoJsx = <SampleDataTagAndProvider product={product} />;
    const sideInfoJsx = <SampleDataSideInfo item={product} />
    return <FeatureCheck feature={SAMPLE_DATA}>
        <BackLink path={routes.sampleDataDownloads} label={messages.sampleDataDownloads}/>
        <div className={classes.content}>
            <div className={classes.container}>
                <div className={classes.downloadDetailContent} data-testid="downloadDetail">
                    {!isXlScreen && logoJsx}
                    <Typography variant='h1' color='primary' className={classes.productTitle}>
                        {product.name}
                    </Typography>
                    <SampleDataDesc item={product} summary={false}/>
                    <SampleDataHeading title={product.name}/>

                    <StepList className={classes.downloadForm}>
                        <StepListItem disabled={formatDisabled} lineDisabled={downloadDisabled}
                                      className={classes.downloadStage}>
                            <div className={classes.stageContent}>
                                <div className={classNames({
                                    [classes.stageHeading]: true,
                                    [classes.stageLabels]: true,
                                    disabled: formatDisabled
                                })}>
                                    <Typography variant='body1'>
                                        <FormattedMessage {...messages.dataFormat} />
                                    </Typography>
                                </div>

                                <div className={classes.controls}>
                                    <FormControl className={classes.formControl}>
                                        {downloadsLoading &&
                                            <Typography variant='body1'
                                                        className={classNames({
                                                            [classes.contentLabel]: true,
                                                            disabled: formatDisabled
                                                        })}>
                                                <FormattedMessage {...messages.loadingFormats} />
                                            </Typography>
                                        }
                                        {formatLabels.length === 1 &&
                                            <Typography variant='body1'
                                                        className={classNames({
                                                            [classes.contentLabel]: true,
                                                            disabled: formatDisabled
                                                        })}>
                                                {formatLabels[0]}
                                            </Typography>
                                        }
                                        {!downloadsLoading && formatLabels.length !== 1 &&
                                            <Fragment>
                                                <InputLabel hidden={true}>
                                                    {intl.formatMessage(messages.formatLabel)}
                                                </InputLabel>
                                                <DropDownMenu
                                                    id='format'
                                                    buttonClasses={{outlined: classes.formatSelectButton}}
                                                    buttonLabel={formatLabels.find(label => label === selectedFormatName) || intl.formatMessage(messages.selectFormat)}
                                                    buttonVariant='outlined'
                                                    buttonProps={{
                                                        'aria-controls': 'links size',
                                                    }}
                                                    disabled={formatDisabled}
                                                    onChange={item => setSelectedFormatName(item.value)}
                                                    items={formatLabels.map(label => ({label, value: label}))}
                                                />
                                            </Fragment>
                                        }
                                        {filteredDownloads.length === 1 &&
                                            <Typography id='size' type='region' variant='body1'
                                                        className={classNames(classes.sizeInfo, {
                                                            [classes.controlInfo]: true,
                                                            disabled: formatDisabled
                                                        })}>
                                                <span
                                                    className={classes.label}>{intl.formatMessage(messages.size)}:</span>{filteredDownloads[0].readableSize}
                                            </Typography>
                                        }

                                        {!downloadDisabled && filteredDownloads.length > 1 && minSize && maxSize &&
                                            <Typography id='size' type='region' variant='body1'
                                                        className={classNames(classes.sizeInfo, {
                                                            [classes.controlInfo]: true,
                                                            disabled: formatDisabled
                                                        })}>
                                                <span
                                                    className={classes.label}>{intl.formatMessage(messages.size)}:</span>
                                                {minSize === maxSize ?
                                                    <FormattedMessage {...messages.singleSize}
                                                                      values={{size: minSize}}/>
                                                    :
                                                    <FormattedMessage {...messages.sizeRange}
                                                                      values={{min: minSize, max: maxSize}}/>
                                                }
                                            </Typography>
                                        }
                                    </FormControl>
                                </div>
                            </div>
                        </StepListItem>

                        <StepListItem disabled={downloadDisabled} className={classes.downloadStage}>
                            <div className={classes.stageContent}>
                                <div className={classNames({
                                    [classes.stageHeading]: true,
                                    [classes.stageLabels]: true,
                                    disabled: downloadDisabled
                                })}>
                                    <Typography variant='body1'>
                                        <FormattedMessage {...messages.download} />
                                    </Typography>
                                </div>

                                <div className={classes.controls} id='links' type='region' aria-live='polite'>
                                    {!downloadDisabled &&
                                        <SampleDataLinks downloads={filteredDownloads} name={product.name}
                                                       format={selectedFormatName}/>
                                    }
                                </div>
                            </div>
                        </StepListItem>
                    </StepList>
                </div>
                <div>
                    {isXlScreen && logoJsx}
                    {sideInfoJsx}
                </div>
            </div>
        </div>
    </FeatureCheck>;
}
