import { useEffect } from "react";
import { ExternalLink, Notification } from "omse-components";
import { FormattedMessage } from "react-intl";
import { CircularProgress, Typography } from "@mui/material";
import FeatureCheck from "../components/FeatureCheck";
import { TEAM_SPACES } from "../../shared/features";
import { useDispatch, useSelector } from "react-redux";
import { getTeamSpace } from "../modules/teamSpaces/actions";
import teamSpaceMessages from "./TeamSpace.msg";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import Header from "./teamSpaces/Header";
import { ContentContainer } from "./teamSpaces/ContentContainer";
import routePaths from "../util/routes";
import CodeSnippet from "./documentation/CodeSnippet";

export default function TeamSpace() {
    const params = useParams();
    const dispatch = useDispatch();

    const loading = useSelector((state) => state.teamSpaces.getTeamSpace.loading);
    const teamSpaceResult = useSelector((state) => state.teamSpaces.getTeamSpace.result);
    const teamSpaceError = useSelector((state) => state.teamSpaces.getTeamSpace.error);

    useEffect(() => {
        if (params.teamSpaceId) {
            dispatch(getTeamSpace(params.teamSpaceId));
        }
    }, [dispatch, params.teamSpaceId]);

    return (
        <FeatureCheck feature={TEAM_SPACES}>
            <Header
                title={teamSpaceMessages.title}
                backLabel={teamSpaceMessages.backToTeamSpacesPage}
                backPath={routePaths.teamSpaces}
            />
            <ContentContainer>
                {loading && <CircularProgress size={32} data-testid="loading-spinner" />}
                {!loading && (
                    <>
                        {teamSpaceError && (
                            <Notification variant="error" appearance="inline">
                                <Typography variant="body1" paragraph={true}>
                                    <FormattedMessage {...teamSpaceMessages.errorGeneric} />
                                </Typography>
                                <Typography variant="body1">
                                    <FormattedMessage
                                        {...teamSpaceMessages.errorContent}
                                        values={{ link: <ExternalLink type="support" /> }}
                                    />
                                </Typography>
                            </Notification>
                        )}
                        {!teamSpaceError && (
                            <>
                                {teamSpaceResult ? (
                                    <Typography variant="body1" component="pre">
                                        <CodeSnippet>
                                            {JSON.stringify(teamSpaceResult, null, "\t")}
                                        </CodeSnippet>
                                    </Typography>
                                ) : (
                                    <Typography variant="body1" paragraph={true}>
                                        <FormattedMessage {...teamSpaceMessages.notFound} />
                                    </Typography>
                                )}
                            </>
                        )}
                    </>
                )}
            </ContentContainer>
        </FeatureCheck>
    );
}
