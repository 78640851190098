import React from "react";
import withStyles from "react-jss";
import { FormattedMessage } from "react-intl";
import Typography from "@mui/material/Typography";
import { ExternalLink, InternalLink, osColour } from "omse-components";
import { defineMessages } from "react-intl";
import routes, { privacyPolicyAddress, cookiesAddress, generalTermsAddress } from "../util/routes";

const messages = defineMessages({
    copyright: {
        id: "Footer.copyright",
        defaultMessage: "© Ordnance Survey {year}",
        description: "Copyright text",
    },
    privacyPolicyLink: {
        id: "Footer.privacyPolicyLink",
        defaultMessage: "Privacy Policy",
        description: "Privacy Policy link text",
    },
    cookiesLink: {
        id: "Footer.cookiesLink",
        defaultMessage: "Cookies",
        description: "Cookies link text",
    },
    generalTermsLink: {
        id: "Footer.generalTermsLink",
        defaultMessage: "OS Website Terms",
        description: "Text for the link to the OS EULA",
    },
    legalTermsLink: {
        id: "Footer.legalTermsLink",
        defaultMessage: "Legal Terms",
        description: "Legal Terms link",
    },
});

const styles = (theme) => ({
    footer: {
        background: osColour.neutral.clouds,
        borderTop: `solid ${osColour.neutral.mist} 1px`,
        color: osColour.neutral.stone,
        textAlign: "right",
        paddingRight: theme.spacing(2.5),
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
        flex: "0 0 auto",
        zIndex: 1,

        // Avoid clashing with the cookie banner on small screens
        [theme.breakpoints.down("md")]: {
            paddingLeft: theme.spacing(5),
        },

        "& a": {
            marginLeft: theme.spacing(1.5),
            height: 20,
            textDecoration: "none",
            color: osColour.neutral.stone,

            "&:hover": {
                textDecoration: "underline",
            },
        },

        "& a > svg": {
            color: osColour.neutral.stone,
        },

        "& a:visited > svg": {
            color: osColour.neutral.stone,
        },

        "& span": {
            height: 20,
        },

        "& p": {
            lineHeight: 1.7,
        },
    },
    spacer: {
        paddingLeft: theme.spacing(1.5),
    },
});

export const Footer = (props) => {
    const { classes } = props;
    const currentYear = new Date().getFullYear();

    const LinkItemSpacer = () => <span className={classes.spacer}>&nbsp;|&nbsp;</span>;

    return (
        <footer className={classes.footer}>
            <Typography variant={"body1"}>
                <span>
                    <FormattedMessage {...messages.copyright} values={{ year: currentYear }} />
                </span>
                <LinkItemSpacer />
                <ExternalLink
                    type="generic"
                    href={privacyPolicyAddress}
                    message={messages.privacyPolicyLink}
                />
                <LinkItemSpacer />
                <ExternalLink
                    type="generic"
                    href={cookiesAddress}
                    message={messages.cookiesLink}
                />
                <LinkItemSpacer />
                <ExternalLink
                    type="generic"
                    href={generalTermsAddress}
                    message={messages.generalTermsLink}
                />
                <LinkItemSpacer />
                <InternalLink path={routes.legalOverview} message={messages.legalTermsLink} />
            </Typography>
        </footer>
    );
}

export default withStyles(styles)(Footer);
