import {matchPath, useHistory, useLocation} from "react-router";
import queryString from 'query-string';
import withTracker from './withTracker';
import withPermissionBarrier from "../pages/permission/withPermissionBarrier";
import {
    hasEditPremiumDownloadsPermission,
    hasShowPremiumDownloadsPermission,
    hasEditNgdDownloadsPermission,
    hasShowNgdDownloadsPermission,
    hasCreateRecipePermission,
    hasViewDataPackagesPermission,
    hasShowVernacularNamesPermission,
    hasManageEaiApiPlan,
    hasManageLicencesPermission,
    hasViewEaiApiPlansPermission,
    hasEditPremiumDownloadOrDownloadPermission,
    hasOsNetRinexPermission
} from './permissions'
import withTermsBarrier from "../pages/legal/acceptTermsBarrier/withTermsBarrier";
import withFullHeight from './withFullHeight';
import withFeatureCheck from './withFeatureCheck';
import {apiTermsName, downloadTermsName, generalTermsName} from '../../shared/constants';
import {PREMIUM_PLAN, PSGA_PLAN, ENERGY_AND_INFRASTRUCTURE_PLAN, USER_ENERGY_AND_INFRASTRUCTURE_DATA_PLAN} from "../../shared/plans";
import {useMemo} from "react";
import { isWorkspaceUser } from "./plans";
import {OS_NET_RINEX_DATA} from "../../shared/features";

//
// The AppContent and Menu use the list of routes to present the correct content to the user.
// The order of the routes matter, as the first <Switch> that matches will win, so we need the most
// specific routes first. Make sure to add any new PUBLIC facing routes to /share/publicRoutes.js
// so it can be added to the sitemap and be indexed.
//
export const routes = [
    {name: 'account',                   path: '/account',                                       requiresTerms: true},
    {name: 'teamSpaces',                path: '/teamSpaces',                                    requiresTerms: true},
    {name: 'teamSpace',                 path: '/teamSpaces/:teamSpaceId',                       requiresTerms: true},
    {name: 'billing',                   path: '/billing',                                       requiresTerms: true},
    {name: 'payments',                  path: '/payments',                                      requiresTerms: true},
    {name: 'accountInvitation',         path: '/invite',                                        requiresTerms: true},
    {name: 'manageTeamMembers',         path: '/manageTeamMembers',                             requiresTerms: true},
    {name: 'companyInformation',        path: '/companyInformation',                            requiresTerms: true},
    {name: 'contactPreferences',        path: '/contactPreferences',                            requiresTerms: true},
    {name: 'history',                   path: '/history',                                       requiresTerms: true, preserveSearch: ['mode','interval','projectId']},
    {name: 'project',                   path: '/projects/:projectId',                           requiresApiTerms: true, requiresTerms: true},
    {name: 'projects',                  path: '/projects',                                      requiresTerms: true, requiresActionScopedApiTerms: true},
    {name: 'apis',                      path: '/products',                                      requiresTerms: true, requiresActionScopedApiTerms: true},
    {name: 'myApiPlans',                path: '/myApiPlans',                                    check:hasViewEaiApiPlansPermission, requiresApiTerms: true, requiresTerms:true},
    {name: 'pricingApiPlans',           path: '/pricingApiPlans',                               check:hasViewEaiApiPlansPermission, requiresApiTerms: true, requiresTerms:true},
    {name: 'addApiPlan',                path: '/addApiPlan',                                    check:hasManageEaiApiPlan, requiresActionScopedApiTerms: true, requiresTerms:true},
    {name: 'addedApiPlan',              path: '/addedApiPlan',                                  check:hasManageEaiApiPlan},
    {name: 'downloadsOverview',         path: '/docs/downloads/overview',                       public: true},
    {name: 'downloadsIntro',            path: '/docs/downloads/gettingStarted',                 public: true},
    {name: 'downloadsDetail',           path: '/docs/downloads/technicalSpecification',         public: true},
    {name: 'oauthOverview',             path: '/docs/oauth2/overview',                          public: true},
    {name: 'oauthIntro',                path: '/docs/oauth2/gettingStarted',                    public: true},
    {name: 'oauthDetail',               path: '/docs/oauth2/technicalSpecification',            public: true},
    {name: 'wfsOverview',               path: '/docs/wfs/overview',                             public: true},
    {name: 'wfsIntro',                  path: '/docs/wfs/gettingStarted',                       public: true},
    {name: 'wfsDetail',                 path: '/docs/wfs/technicalSpecification',               public: true},
    {name: 'ofaOverview',               path: '/docs/ofa/overview',                             public: true},
    {name: 'ofaIntro',                  path: '/docs/ofa/gettingStarted',                       public: true},
    {name: 'ofaDetail',                 path: '/docs/ofa/technicalSpecification',               public: true},
    {name: 'otaOverview',               path: '/docs/ota/overview',                             public: true},
    {name: 'otaIntro',                  path: '/docs/ota/gettingStarted',                       public: true},
    {name: 'otaDetail',                 path: '/docs/ota/technicalSpecification',               public: true},
    {name: 'wmtsOverview',              path: '/docs/wmts/overview',                            public: true},
    {name: 'wmtsIntro',                 path: '/docs/wmts/gettingStarted',                      public: true},
    {name: 'wmtsDetail',                path: '/docs/wmts/technicalSpecification',              public: true},
    {name: 'vtsOverview',               path: '/docs/vts/overview',                             public: true},
    {name: 'vtsIntro',                  path: '/docs/vts/gettingStarted',                       public: true},
    {name: 'vtsDetail',                 path: '/docs/vts/technicalSpecification',               public: true},
    {name: 'namesOverview',             path: '/docs/names/overview',                           public: true},
    {name: 'namesIntro',                path: '/docs/names/gettingStarted',                     public: true},
    {name: 'namesDetail',               path: '/docs/names/technicalSpecification',             public: true},
    {name: 'linkedIdentifiersOverview', path: '/docs/linkedIdentifiers/overview',               public: true},
    {name: 'linkedIdentifiersIntro',    path: '/docs/linkedIdentifiers/gettingStarted',         public: true},
    {name: 'linkedIdentifiersDetail',   path: '/docs/linkedIdentifiers/technicalSpecification', public: true},
    {name: 'matchOverview',             path: '/docs/match/overview',                           public: true},
    {name: 'matchIntro',                path: '/docs/match/gettingStarted',                     public: true},
    {name: 'matchDetail',               path: '/docs/match/technicalSpecification',             public: true},
    {name: 'placesOverview',            path: '/docs/places/overview',                          public: true},
    {name: 'placesIntro',               path: '/docs/places/gettingStarted',                    public: true},
    {name: 'placesDetail',              path: '/docs/places/technicalSpecification',            public: true},
    {name: 'documentation',             path: '/docs',                                          public: true},
    {name: 'supportDownload',           path: '/support/download',                              public: true},
    {name: 'supportOsSelectAndBuild',   path: '/support/osSelectAndBuild',                      public: true},
    {name: 'supportPlans',              path: '/support/plans',                                 public: true},
    {name: 'support',                   path: '/support',                                       public: true},
    {name: 'openDataDownloads',         path: '/downloads/open',                                public: true},
    {name: 'openDataItem',              path: '/downloads/open/:downloadId',                    public: true},
    {name: 'sampleDataDownloads',       path: '/downloads/sample',                              public: true},
    {name: 'sampleDataItem',            path: '/downloads/sample/:downloadId',                  public: true},
    {name: 'premiumDownloads',          path: '/downloads/premium',                             check: hasShowPremiumDownloadsPermission, requiresTerms: true, requiresDownloadTerms: true},
    {name: 'premiumItem',               path: '/downloads/premium/:productId',                  check: hasShowPremiumDownloadsPermission, requiresTerms: true, requiresDownloadTerms: true},
    {name: 'dataPackageNew',            path: '/downloads/premium/:productId/dataPackage',      check: hasEditPremiumDownloadsPermission, requiresTerms: true, requiresDownloadTerms: true, hideMenu: true, fullHeight: true},
    {name: 'dataPackageNewNgd',         path: '/downloads/recipes/:recipeId/dataPackage',       check: hasEditNgdDownloadsPermission,     requiresTerms: true, requiresDownloadTerms: true, hideMenu: true, fullHeight: true},
    {name: 'dataPackages',              path: '/downloads/packages',                            check: hasViewDataPackagesPermission,     requiresTerms: true, requiresDownloadTerms: true},
    {name: 'dataPackage',               path: '/downloads/packages/:packageId',                 check: hasViewDataPackagesPermission,     requiresTerms: true, requiresDownloadTerms: true},
    {name: 'dataPackageExpand',         path: '/downloads/packages/:packageId/expand',          check: hasEditPremiumDownloadOrDownloadPermission, requiresTerms: true, requiresDownloadTerms: true, hideMenu: true, fullHeight: true},
    {name: 'manageLicences',            path: '/downloads/licences',                            check: hasManageLicencesPermission,       requiresTerms: true, requiresDownloadTerms: true},
    {name: 'recipeLibrary',             path: '/downloads/recipes',                             check: hasShowNgdDownloadsPermission,     requiresTerms: true, requiresDownloadTerms: true},
    {name: 'recipeEditor',              path: '/downloads/recipes/new',                         check: hasCreateRecipePermission,         requiresTerms: true, requiresDownloadTerms: true, hideMenu: true, fullHeight: true},
    {name: 'recipeDetails',             path: '/downloads/recipes/:recipeId',                   check: hasShowNgdDownloadsPermission,     requiresTerms: true, requiresDownloadTerms: true},
    {name: 'osNetRinexData',            path: '/downloads/osnet',                               check: hasOsNetRinexPermission,           requiresTerms: true, requiresDownloadTerms: true, featureFlag: OS_NET_RINEX_DATA },
    {name: 'plans',                     path: '/plans',                                         public: true},
    {name: 'legalOverview',             path: '/legal/overview',                                public: true},
    {name: 'apiTermsConditions',        path: '/legal/apiTermsConditions',                      public: true},
    {name: 'termsConditions',           path: '/legal/termsConditions',                         public: true},
    {name: 'downloadTerms',             path: '/legal/downloadTerms',                           public: true},
    {name: 'errorReportingTerms',       path: '/legal/errorReportingTerms',                     public: true},
    {name: 'vernacularNamesTerms',      path: '/legal/vernacularNamesTerms',                    public: true},
    {name: 'trialModeTerms',            path: '/legal/trialModeTerms',                          public: true},
    {name: 'frameworkContractTerms',    path: '/legal/frameworkContractTerms',                  public: true},
    {name: 'partnerContracts',          path: '/legal/partnerContracts',                        public: true},
    {name: 'sla',                       path: '/legal/sla',                                     public: true},
    {name: 'serviceStatus',             path: '/serviceStatus',                                 public: true},
    {name: 'serviceStatusApi',          path: '/serviceStatus/:apiId',                          public: true},
    {name: 'dashboard',                 path: '/dashboard',                                     public: true,  requiresTerms: true, requiresActionScopedApiTerms: true, preserveSearch: ['mode']},
    {name: 'workspace',                 path: '/workspace',                                     check: isWorkspaceUser, requiresTerms: true, requiresActionScopedApiTerms: true},
    {name: 'error404Projects',          path: '/projectNotFound',                               public: true,  hideMenu: true},
    {name: 'error404Downloads',         path: '/downloadNotFound',                              public: true,  hideMenu: true},
    {name: 'error404Sample',            path: '/sampleNotFound',                                public: true,  hideMenu: true},
    {name: 'error404Premium',           path: '/premiumNotFound',                               public: true,  hideMenu: true},
    {name: 'error404Recipe',            path: '/recipeNotFound',                                public: true,  hideMenu: true},
    {name: 'errorsAndOmissions',        path: '/errorsAndOmissions',                            requiresTerms: true, fullHeight: true},
    {name: 'holdingPage',               path: '/holdingPage',                                   public:true, hideMenu: true,},
    {name: 'errorReporting',            path: '/errorsAndOmissions/reporting',                  requiresTerms: true, fullHeight: true},
    {name: 'vernacularNames',           path: '/tools/vn',                                      check: hasShowVernacularNamesPermission, requiresTerms: true, fullHeight: true},
    {name: 'vernacularNamesReporting',  path: '/tools/vn/reporting',                            check: hasShowVernacularNamesPermission, requiresTerms: true, fullHeight: true, hideMenu: true},
    {name: 'logInError',                path: '/logInError',                                    public: true,  hideMenu: true},
    {name: 'brandImportance',           path: '/brandLogo/brandImportance',                     public: true},
    {name: 'logoOverview',              path: '/brandLogo/logoOverview',                        public: true},
    {name: 'logoFullColour',            path: '/brandLogo/logoFullColour',                      public: true},
    {name: 'logoWhite',                 path: '/brandLogo/logoWhite',                           public: true},
    {name: 'copyrightOverview',         path: '/brandLogo/copyrightOverview',                   public: true},
    {name: 'logoCopyrightPositioning',  path: '/brandLogo/logoCopyrightPositioning',            public: true},
    {name: 'exclusionZone',             path: '/brandLogo/exclusionZone',                       public: true},
    {name: 'minMax',                    path: '/brandLogo/minMax',                              public: true},
    {name: 'prohibitedUse',             path: '/brandLogo/prohibitedUse',                       public: true},
    {name: 'premiumSetup',              path: '/premiumSetup',                                  public: false, requiresTerms: true, hideMenu: true, simpleHeader: true, noLinkHeader: true},
    {name: 'psgaSetup',                 path: '/psgaSetup',                                     public: false, requiresTerms: true, hideMenu: true, simpleHeader: true, noLinkHeader: true},
    {name: 'energyAndInfraSetup',       path: '/energyAndInfrastructureSetup',                  public: false, requiresTerms: true, hideMenu: true, simpleHeader: true, noLinkHeader: true},
    {name: 'paymentSetup',              path: '/paymentSetup',                                  public: false, requiresTerms: true, hideMenu: true, simpleHeader: true},
    {name: 'invitation',                path: '/invitation',                                    public: false, requiresTerms: true, hideMenu: true, simpleHeader: true},
    {name: 'landing',                   path: '/',                                              public: true,  hideMenu: true},
];
/*See Note above, the order matters, especially when concerning if it will select/display on the side menu*/

var routePaths = {};
routes.forEach(route => {
    routePaths[route.name] = route.path;
});

export default routePaths;

export const accountRoutes = routes.filter(route => ['billing', 'payments', 'accountInvitation', 'manageTeamMembers', 'companyInformation', 'contactPreferences'].indexOf(route.name) !== -1);
export const orgSelectRoutes = accountRoutes.concat(routes.filter(route => ['dashboard', 'apis', 'projects', 'project', 'history', 'account'].indexOf(route.name) !== -1));

export function registerComponent(name, component) {
    const route = routes.find(item => item.name === name);

    if(route.featureFlag) {
        component = withFeatureCheck(component, route.featureFlag);
    }

    if(route.check) {
        component = withPermissionBarrier(component, route.check);
    }

    if(route.requiresApiTerms || route.requiresActionScopedApiTerms) {
        component = withTermsBarrier(component, apiTermsName, route.requiresActionScopedApiTerms);
    }

    if(route.requiresDownloadTerms) {
        component = withTermsBarrier(component, downloadTermsName);
    }

    if(route.requiresTerms) {
        component = withTermsBarrier(component, generalTermsName);
    }

    if(route.fullHeight) {
        component = withFullHeight(component);
    }

    component = withTracker(component);
    route.component = component;
}

export function findRoute(name) {
    return routes.find(route => route.name === name);
}

export function matchRoute(path) {
    return routes.find(route => {
        return matchPath(path, {
            path: route.path,
            exact: true,
            strict: false
        });
    })
}

// These routes are different from most of the navigation in the app, as the normal routing is taken care of by
// React-Router, and we don't leave the single page application. For this navigation we need to access the api
// endpoints that will redirect us to the single-sign-on provider, so we force the browser to really go there.

export const logInLink = "/api/auth/signIn";
export const logOutLink = '/api/auth/signOut';

export function logIn() {
    window.location = logInLink;
}

export function logOut() {
    window.location = logOutLink;
}

export const signUpLink = (plan) => {
    let result = "/api/auth/signUp";

    if(plan === PREMIUM_PLAN) {
        result += "?plan=PremiumData";
    } else if (plan === PSGA_PLAN) {
        result += "?plan=PSGA"
    }
    else if (plan === ENERGY_AND_INFRASTRUCTURE_PLAN) {
        result += "?plan=" + USER_ENERGY_AND_INFRASTRUCTURE_DATA_PLAN
    }

    return result;
}

export const gitHubAddress = 'https://github.com/OrdnanceSurvey/OS-Data-Hub-API-Demos';

export const privacyPolicyAddress = 'https://www.ordnancesurvey.co.uk/governance/policies/privacy';

export const dataHubExamplesUrl = 'https://labs.os.uk/public/os-data-hub-examples';

export const osDataHubExplorerUrl = 'https://labs.os.uk/prototyping/data-hub-explorer/';
export const PyWrapperUrl = 'https://pypi.org/project/osdatahub/';
export const JsWrapperUrl = 'https://www.npmjs.com/package/osdatahub';

export const osMedium = 'https://osdeveloper.medium.com/';

export const cookiesAddress = 'https://www.ordnancesurvey.co.uk/cookies';

export const generalTermsAddress = 'https://www.ordnancesurvey.co.uk/legal/general-terms';

export const PSGA_DESCRIPTION_URL="https://www.ordnancesurvey.co.uk/business-government/public-sector-geospatial-agreement";
export const FCP_URL="https://www.ordnancesurvey.co.uk/business-government/licensing-agreements/partner-licence";
export const PSGA_REGISTRATION_URL = "https://www.ordnancesurvey.co.uk/business-government/licensing-agreements/psga-registration";
export const PARTNER_MEMBER_URL = "https://www.ordnancesurvey.co.uk/business-government/partner-member";
export const PSGA_MEMBER_URL = "https://www.ordnancesurvey.co.uk/customers/public-sector/psga-member-finder";
export const PSGA_COMMERCIAL_ENQUIRIES = "https://www.ordnancesurvey.co.uk/business-government/commercial-enquiries";
export const ROYAL_MAIL_SOLUTION_PROVIDER_LICENSE = "https://www.poweredbypaf.com/licence-our-products/licence-agreements/solutions-providers/";
export const DATAHUB_ROADMAP_URL = "https://roadmap.prodpad.com/54a82b62-8265-11eb-a902-0288f735e5b9";

export const psgaAbout = 'https://www.ordnancesurvey.co.uk/business-government/public-sector-geospatial-agreement';

export const PSGA_DOWNLOADS_VIDEO = 'https://www.youtube.com/watch?v=ultyQbIEuR0';

export const osToolsSupportUrl = 'https://www.ordnancesurvey.co.uk/business-government/tools-support';
export const osNgdGitBook = 'https://docs.os.uk/osngd';
export const osNgdGitBookMoreThanMaps = "https://docs.os.uk/more-than-maps";
export const osSelectAndBuildFaq = 'https://docs.os.uk/osngd/extra-links/faqs'
export const osSelectAndBuildAccess = 'https://docs.os.uk/osngd/accessing-os-ngd/downloading-with-os-select+build'
export const commercialContactUs = 'https://www.ordnancesurvey.co.uk/business-government/commercial-enquiries'

export const OS_HOMEPAGE = 'https://www.ordnancesurvey.co.uk'

/**
 * Generates a location object that can be used for redirecting, has behaviour around preserving desired query strings.
 *
 * The following query params are preserved:
 * - The 'lang' param.
 * - Any params on route.preserveSearch.
 *
 * Use this method like:
 *   const newLocation = getLocation(routes.<someRoute>, useLocation());
 *   history.replace(newLocation);
 *
 * @param {string} targetRoutePath Path of the route to generate a location for.
 * @param location Existing location object. Will be used to find the current query string to preserve.
 * @param {object} extraQueryStringParams Object of extra query string params to add.
 * @param {object} extraLocationParts Object of extra location params to add.
 * @returns {{search: string, pathname: string}}
 */
export function getLocation(targetRoutePath, location, extraQueryStringParams = {}, extraLocationParts = {}) {
    const route = matchRoute(targetRoutePath);

    let partsToKeep = ['lang'];
    if(route && route.preserveSearch) {
        partsToKeep = partsToKeep.concat(route.preserveSearch);
    }
    const parsed = queryString.parse(location.search);
    partsToKeep.forEach(part => extraQueryStringParams[part] = parsed[part]);

    return {
        pathname: targetRoutePath,
        search: queryString.stringify(extraQueryStringParams),
        ...extraLocationParts
    };
}

/**
 * Util method for performing a standard redirect involving preserving appropriate query params.
 *
 * @param {object} extraQueryStringParams Extra query params to add.
 */
export function useRedirect(extraQueryStringParams = {}) {
    const location = useLocation();
    const history = useHistory();

    // By wrapping our result in useMemo we make the return value more stable, so less re-rendering in the app.
    const result = useMemo(function() {
        const getNewLocation = (targetRoutePath, extraLocationParts) => getLocation(targetRoutePath, location, extraQueryStringParams, extraLocationParts);

        return {
            replace: (targetRoutePath, extraLocationParts) => history.replace(getNewLocation(targetRoutePath, extraLocationParts)),
            push: (targetRoutePath, extraLocationParts) => history.push(getNewLocation(targetRoutePath, extraLocationParts)),
        };
    }, [location, history, extraQueryStringParams]);

    return result;
}

export function goToLandingPage(history) {
    const newLocation = getLocation(routePaths.landing, history.location);
    history.replace(newLocation);
}
