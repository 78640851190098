import { FormattedMessage, useIntl } from "react-intl";
import ClickAwayTooltip from "../../../components/ClickAwayTooltip";
import { Typography } from "@mui/material";
import { ADMIN_ROLE } from "../../../../shared/roles";
import { osColour, DropDownMenu } from "omse-components";
import { ReactComponent as InfoIcon } from "../../../components/icons/info-notification.svg";
import { ReactComponent as OverflowIcon } from "../../../components/icons/overflow-menu.svg";
import styled from "@emotion/styled";
import { css } from '@emotion/css';
import { useSelector } from "react-redux";
import ownersTableMessages from "./OwnersTable.msg";
import PropTypes from "prop-types";
import OwnerMemberInfo from "./OwnerMemberInfo";

const StyledTable = styled('table')(({ theme }) => `
    margin-top: ${theme.spacing(2)};
    width: 100%;
    border: none;
    border-collapse: collapse;
    & thead {
        color: ${osColour.neutral.charcoal};
        & tr {
            border-bottom: 2px solid ${osColour.neutral.rock};
        }
        & th {
            display: flex;
            text-align: left;
            font-weight: bold;
        }
        & th:last-of-type {
            text-align: right;
            padding-right: 0;
        }
    }
    & tr td:first-of-type {
        padding: ${theme.spacing(1, 0)};
    }
    & tr td:first-of-type p:last-of-type {
        font-size: 0.94em;
        color: ${osColour.neutral.stone} 
    }
    & tbody tr {
        display: flex;
        justify-content: space-between;
    }
    & tbody tr td:last-of-type {
        display: flex;
        align-items: center;
    }
    ${theme.breakpoints.down('sm')} {
        & tbody tr {
            flex-direction: column;
        }
        & tbody tr td:first-of-type {
            padding: ${theme.spacing(1, 0, 0.5, 0)}
        }
    }
`);

const OwnerActions = styled.div(({ theme }) => `
    & svg {
        color: ${osColour.neutral.charcoal}
    }
    & li:first-of-type:not(.Mui-disabled) {
        color: ${osColour.status.error};
    }
    & button {
        padding: ${theme.spacing(1)};
    }
    ${theme.breakpoints.down('sm')} {
        & button {
            padding: ${theme.spacing(0.5)};
        }
    }
`);

// Tooltip
const clickAwayTooltipClass = css`
    float: none !important;
    margin-left: 6px;
    display: inline-flex;
`;

const clickAwayTooltipLinkClass = css`
    & > span:nth-child(1) { 
        display: flex;
    }
`;

export default function OwnersTable({ setOwners, owners }) {
    const intl = useIntl();
    const user = useSelector(state => state.user.current.result);
    const createTeamSpaceWorking = useSelector(state => state.teamSpaces.createTeamSpace.working);

    const removeOwner = (owner) => {
        if (owner?.datahubDeveloperId) {
            setOwners(owners.filter(existing => existing.datahubDeveloperId !== owner.datahubDeveloperId));
        }
    }

    const sortedOwners = [...owners].sort((a) => (a.datahubDeveloperId === user.datahubDeveloperId) ? -1 : 1);

    return (
        <StyledTable>
            <thead>
                <tr>
                    <Typography variant='body1' component='th' colSpan={2}>
                        <FormattedMessage {...ownersTableMessages.ownersTableHeading} />
                        <ClickAwayTooltip
                            id='ownerHelp'
                            classes={{ clickAwayTooltip: clickAwayTooltipClass, tooltipLink: clickAwayTooltipLinkClass }}
                            icon={
                                <InfoIcon
                                    width={24}
                                    height={24}
                                    color={osColour.status.warning}
                                />
                            }
                            body={<FormattedMessage {...ownersTableMessages.ownerHelp} values={{ b: chunks => <b>{chunks}</b> }} />}
                            ariaLabel={intl.formatMessage(ownersTableMessages.ownerHelp, { b: chunks => chunks })}
                        />
                    </Typography>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {sortedOwners
                    .map((owner) => (owner && (
                        <tr key={owner.datahubDeveloperId}>
                            <td>
                                <Typography component='div'>
                                    <OwnerMemberInfo info={owner} user={user} />
                                </Typography>
                            </td>
                            <td>
                                <OwnerActions>
                                    <DropDownMenu
                                        disabled={createTeamSpaceWorking}
                                        icon={<OverflowIcon height={24} width={24} />}
                                        items={[
                                            ((owner.role === ADMIN_ROLE) ?
                                                ((owner.datahubDeveloperId === user.datahubDeveloperId) ?
                                                    {
                                                        label: intl.formatMessage(ownersTableMessages.cannotRemoveAdminLoggedIn),
                                                        value: ownersTableMessages.cannotRemoveAdminLoggedIn.id,
                                                        disabled: true
                                                    }
                                                    :
                                                    {
                                                        label: intl.formatMessage(ownersTableMessages.cannotRemoveAdmin),
                                                        value: ownersTableMessages.cannotRemoveAdmin.id,
                                                        disabled: true
                                                    }
                                                ) :
                                                {
                                                    label: intl.formatMessage(ownersTableMessages.remove),
                                                    value: ownersTableMessages.remove.id,
                                                    action: () => removeOwner(owner)
                                                }
                                            )
                                        ]}
                                        placement='bottom-end'
                                    />
                                </OwnerActions>
                            </td>
                        </tr>
                    )))}
            </tbody>
        </StyledTable>
    )
};

OwnersTable.propTypes = {
    setOwners: PropTypes.func.isRequired,
    owners: PropTypes.array.isRequired
};