import React from "react";
import {defineMessages} from "react-intl";
import DataPackageDropdownField from "./DataPackageDropdownField";
import {useSelector} from "react-redux";
import DataPackageDatePicker from "./DataPackageDatePicker";
import dateFormat from 'dateformat';
import {fromISOString} from '../../../../util/dateUtils';

export const messages = defineMessages({
    initialSupplyDate: {
        id: 'InitialSupplyDateDropdown.initialSupplyDate',
        defaultMessage: 'Initial supply date',
        description: 'Initial supply date label'
    },
    initialSupplyDateSnapBack: {
        id: 'InitialSupplyDateDropdown.initialSupplyDateSnapBack',
        defaultMessage: 'Supply date (dd/mm/yyyy)',
        description: 'Supply date label'
    },
    initialSupplyDateSelect: {
        id: 'InitialSupplyDateDropdown.initialSupplyDateSelect',
        defaultMessage: 'Select initial supply date',
        description: 'Select initial supply date label'
    },
    initialValidSupplyDateNeeded: {
        id: 'InitialSupplyDateDropdown.initialValidSupplyDateNeeded',
        defaultMessage: 'Please select a date between {startDate} and {endDate}',
        description: 'valid initialSupplyDate needed'
    },
    initialSupplyDateNeeded: {
        id: 'InitialSupplyDateDropdown.initialSupplyDateNeeded',
        defaultMessage: 'Please select an initial supply date',
        description: 'initialSupplyDate needed'
    },
    initialSupplyDateTooltip: {
        id: 'InitialSupplyDateDropdown.initialSupplyDateTooltip',
        defaultMessage: 'Your data package will be fulfilled as soon as possible, using data from this supply date. Available supply dates depend on the update frequency that you choose.',
        description: 'initialSupplyDate needed'
    },
    initialSupplyDateSnapBackTooltip: {
        id: 'InitialSupplyDateDropdown.initialSupplyDateSnapBackTooltip',
        defaultMessage: 'You will receive a snapshot of our data as it looked on the date you select. The actual data creation date may be earlier. The calendar will allow you to pick any date between today and the date the data was first made available.',
        description: 'initialSupplyDate needed'
    },
    initialSupplyDateTooltipAccessibleLabel: {
        id: 'InitialSupplyDateDropdown.initialSupplyDateTooltipAccessibleLabel',
        defaultMessage: 'Initial supply date help',
        description: 'Initial supply date tooltip accessible label'
    }
})

export default function InitialSupplyDateSelection(props) {
    const isSnapBack = useSelector((state) => state.dataPackages.draftOrder.isSnapBack);
    const startDate = useSelector(state => state.config.current.result.ngdStartDate);
    const dateRange = {
        minDate: fromISOString(startDate),
        maxDate : new Date()
    };
    return (isSnapBack ?
            <DataPackageDatePicker
                fieldName={'initialSupplyDataDate'}
                labelMessage={messages.initialSupplyDateSnapBack}
                fieldErrorMessage={messages.initialValidSupplyDateNeeded}
                fieldErrorMessageValues={{
                    startDate: dateFormat(dateRange.minDate, 'dd/mm/yyyy'),
                    endDate: dateFormat(dateRange.maxDate, 'dd/mm/yyyy')
                }}
                tooltipMessage={messages.initialSupplyDateSnapBackTooltip }
                tooltipAriaLabelMessage={messages.initialSupplyDateTooltipAccessibleLabel}
                dateRange={dateRange}/> :
            <DataPackageDropdownField
                options={props.initialSupplyDateOptions}
                fieldName={'initialSupplyDataDate'}
                labelMessage={messages.initialSupplyDate}
                selectMessage={messages.initialSupplyDateSelect}
                fieldErrorMessage={messages.initialSupplyDateNeeded}
                tooltipMessage={messages.initialSupplyDateTooltip}
                tooltipAriaLabelMessage={messages.initialSupplyDateTooltipAccessibleLabel}
            />
    );
}