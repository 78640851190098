import { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from "react-intl";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { osColour, DropDownMenu } from "omse-components";
import styled from "@emotion/styled";
import { css } from "@emotion/css";
import { ReactComponent as InfoIcon } from '../../../components/icons/info-notification.svg';
import ClickAwayTooltip from "../../../components/ClickAwayTooltip";
import OwnerMemberInfo from "./OwnerMemberInfo";
import { API_PROJECT_PERMISSION_GROUPS } from "../../../../shared/teamSpaces";
import { ReactComponent as OverflowIcon } from "../../../components/icons/overflow-menu.svg";
import membersTableMessages from "./MembersTable.msg";
import { ADMIN_ROLE } from "../../../../shared/roles";

const StyledTable = styled("table")(
    ({ theme }) => `
    margin-top: ${theme.spacing(2)};
    width: 100%;
    border: none;
    border-collapse: collapse;
    & thead {
        display: contents;
        color: ${osColour.neutral.charcoal};
        & tr {
            border-bottom: 2px solid ${osColour.neutral.rock};
        }
        & th {
            text-align: left;
            font-weight: bold;
        }
        & th:last-of-type {
            text-align: right;
            padding-right: 0;
        }
    }
    & tr td:first-of-type p:last-of-type {
        font-size: 0.94em;
        color: ${osColour.neutral.stone} 
    }
    & tbody td {
        padding: ${theme.spacing(1,0,1,0)};
        vertical-align: middle;
    }
    & tbody td:first-of-type {
        width: 350px;
        padding-right: ${theme.spacing(1)};
    }
    & tbody tr td:last-of-type {
        display: flex;
        justify-content: flex-end;
    }
    ${theme.breakpoints.down('sm')} {
        & tbody tr {
            padding: ${theme.spacing(1,1)};
            flex-direction: column;
        }
        & tbody tr td:first-of-type {
            padding: ${theme.spacing(1, 0, 0.5, 0)}
        }
    }
`
);

const MemberActions = styled.div(({theme}) => `
    & svg {
        color: ${osColour.neutral.charcoal}
    }
    & li:first-of-type:not(.Mui-disabled) {
        color: ${osColour.status.error};
    }
    & button {
        padding: ${theme.spacing(1)};
    }
    ${theme.breakpoints.down('sm')} {
        & button {
            padding: ${theme.spacing(0.5)};
        }
    }
`);

const clickAwayTooltipClass = css`
    float: none !important;
    margin-left: 6px;
    display: inline-flex;
    vertical-align: middle;
`;

const clickAwayTooltipLinkClass = css`
    & > span:nth-child(1) {
        display: flex;
    }
`;

const StyledSelect = styled(Select)(({ theme }) => `
    &.MuiInputBase-root {
        border: none;
        &:before {
            border-bottom: none;
        },
        &:after {
            border-bottom: none;
        },
    },
    & .MuiSelect-select {
        display: flex;
        align-items: center;
        & span {
            width: 36px;
            padding-right: ${theme.spacing(1)};
        }
    },
    & svg {
        color: ${osColour.primary.berry}
    }
`);

const darkerMoss = '#028043';

const EDITOR = 'Editor'
const VIEWER = 'Viewer'

export default function MembersTable({ setMembers, members }) {
    const intl = useIntl();
    const user = useSelector((state) => state.user.current.result);
    const createTeamSpaceWorking = useSelector((state) => state.teamSpaces.createTeamSpace.working);
    const removeMember = (member) => {
        if (member?.datahubDeveloperId) {
            setMembers(members.filter((existing) => existing.datahubDeveloperId !== member.datahubDeveloperId));
        }
    };
    return (
        <StyledTable>
            <thead>
                <tr>
                    <Typography variant='body1' component='th' >
                        <FormattedMessage {...membersTableMessages.membersTableHeading} />
                        <ClickAwayTooltip
                            id="membersHelp"
                            classes={{
                                clickAwayTooltip: clickAwayTooltipClass,
                                tooltipLink: clickAwayTooltipLinkClass,
                            }}
                            icon={
                                <InfoIcon
                                    width={24}
                                    height={24}
                                    color={osColour.status.warning}
                                />
                            }
                            body={
                                <>
                                    <FormattedMessage
                                        {...membersTableMessages.membersHelpViewer}
                                        values={{ b: (chunks) => <b>{chunks}</b> }}
                                    />
                                    <br/>
                                    <br/>
                                    <FormattedMessage
                                        {...membersTableMessages.membersHelpEditor}
                                        values={{ b: (chunks) => <b>{chunks}</b> }}
                                    />
                                </>
                            }
                            ariaLabel={intl.formatMessage(membersTableMessages.membersHelpViewer, {
                                b: (chunks) => chunks,
                            })}
                        />
                    </Typography>
                    <Typography variant="body1" component='th'>
                        <FormattedMessage {...membersTableMessages.membersTableAPIsHeading} />
                    </Typography>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {members.length > 0 && members
                    .filter(m => m.role !== ADMIN_ROLE)
                    .sort((a, b) => (a.datahubDeveloperId === user.datahubDeveloperId ? -1 : 1))
                    .map((member) => (
                        <tr key={member.datahubDeveloperId}>
                            <td>
                                <Typography component='div'>
                                    <OwnerMemberInfo info={member} user={user} />
                                </Typography>
                            </td>
                            <td>
                                <RoleSelect 
                                    initialRole={VIEWER} 
                                    members={members} 
                                    member={member} 
                                    setMembers={setMembers} 
                                />
                            </td>
                            <td>
                                <MemberActions>
                                    <DropDownMenu
                                        disabled={createTeamSpaceWorking}
                                        icon={<OverflowIcon height={24} width={24} />}
                                        items={[
                                            {
                                                label: intl.formatMessage(
                                                    membersTableMessages.remove
                                                ),
                                                value: intl.formatMessage(
                                                    membersTableMessages.remove
                                                ),
                                                action: () => removeMember(member),
                                            },
                                        ]}
                                        placement="bottom-end"
                                    />
                                </MemberActions>
                            </td>
                        </tr>
                    ))}
            </tbody>
        </StyledTable>
    );
}

function RoleSelect({ initialRole, member, members, setMembers}) {
    const [role, setRole] = useState(initialRole || "");

    const handleChange = (event) => {
        const newRole = event.target.value;
        setRole(newRole);
    
        const memberIndex = members.findIndex(m => m.datahubDeveloperId === member.datahubDeveloperId);
        if (memberIndex !== -1) {
            const updatedMembers = [...members];
            updatedMembers[memberIndex] = {
                ...updatedMembers[memberIndex],
                apiProjectRole: newRole === VIEWER 
                    ? API_PROJECT_PERMISSION_GROUPS.ApiProjectViewer 
                    : API_PROJECT_PERMISSION_GROUPS.ApiProjectEditor,
            };
            setMembers(updatedMembers);
        }
    };

    return (
        <FormControl variant="outlined">
            <StyledSelect
                value={role}
                onChange={handleChange}
                displayEmpty
                IconComponent={KeyboardArrowDown}
                renderValue={(selected) => (
                    <span style={{ color: selected === VIEWER ? osColour.primary.berry : darkerMoss }}>
                        {selected === VIEWER ? VIEWER : EDITOR}
                    </span>
                )}
            >
                <MenuItem value={VIEWER}>Viewer</MenuItem>
                <MenuItem value={EDITOR}>Editor</MenuItem>
            </StyledSelect>
        </FormControl>
    );
}

RoleSelect.propTypes = {
    initialRole: PropTypes.string,
    member: PropTypes.shape({
        datahubDeveloperId: PropTypes.string.isRequired,
        apiProjectRole: PropTypes.string.isRequired,
    }).isRequired,
    members: PropTypes.arrayOf(PropTypes.object).isRequired,
    setMembers: PropTypes.func.isRequired,
};
